import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PropTypes, object, func } from 'prop-types';
import DocumentStatus from '../DocumentStatus';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const AdditionalPhotosWrap = styled.div`
  padding: 40px 0;
  margin-bottom: 4pc;

  h2 {
    color: white;
    padding: 20px;
  }

  h3 {
    color: white;
  }

  p {
    color: white;
    padding: 12px 0 20px 0;
  }

  ${media.tablet} {
    width: 90%;
    margin: 0 auto;
  }

  ${media.desktop} {
    h2 {
      padding: 0;
    }
    h3 {
      padding: 30px 10px;
    }
  }
`;

const Row = styled.div`
  padding: 20px;
  margin: 20px 0;
  background: #1f1e29;

  ${media.desktop} {
    display: flex;
    flex-direction: column;
    background: unset;
    padding: 0;

    p {
      width: 22pc;
      color: white;
      padding: 12px 0 20px 12px;
    }
  }
`;

const HeaderWrap = styled.div`
  display: none;

  ${media.desktop} {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid gray;

    p {
      width: 10pc;
      padding: 3px;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  border-bottom: 1px solid gray;

  ${media.desktop} {
    justify-self: flex-end;
    align-self: flex-end;
    width: 20pc;
    margin-top: -38px;
    border-bottom: unset;
  }
`;

const Header = () => {
  return (
    <HeaderWrap>
      <p>Vencimiento</p>
      <p>Status</p>
    </HeaderWrap>
  );
};

const AdditionalPhotos = ({ premiumPhotos, setAdditionalPhotosList }) => {
  const [truckerLicence, setTruckerLicence] = useState('');
  const [tractorVtv, setTractorVtv] = useState('');
  const [RUTATractor, setRUTATractor] = useState('');
  const [tractorInsurance, setTractorInsurance] = useState('');
  const [trailerVTV, setTrailerVTV] = useState('');
  const [trailerInsurance, setTrailerInsurance] = useState('');

  const [truckerLicencePhoto, setTruckerLicencePhoto] = useState('');
  const [tractorVTVImg, setTractorVTVImg] = useState('');
  const [RUTATractorPhoto, setRUTATractorPhoto] = useState('');
  const [tractorInsurancePhoto, setTractorInsurancePhoto] = useState('');
  const [trailerVtvPhoto, setTrailerVtvPhoto] = useState('');
  const [trailerInsurancePhoto, setTrailerInsurancePhoto] = useState('');

  const buildImgStates = () => {
    setTruckerLicence({
      imgURI: premiumPhotos.truckerLicensePhoto,
      imgStatus:
        premiumPhotos.confirmedFrontLicensePhoto !== null
          ? premiumPhotos.confirmedFrontLicensePhoto
          : null,
      expiration:
        premiumPhotos.truckerLicenseExpiration !== ''
          ? moment(premiumPhotos.truckerLicenseExpiration).format('DD/MM/YYYY')
          : ' - ',
    });

    setTractorVtv({
      imgURI: premiumPhotos.tractorVtvPhoto,
      imgStatus:
        premiumPhotos.confirmedFrontVtvPhoto !== null ? premiumPhotos.confirmedFrontVtvPhoto : null,
      expiration:
        premiumPhotos.tractorVtvExpiration !== ''
          ? moment(premiumPhotos.tractorVtvExpiration).format('DD/MM/YYYY')
          : ' - ',
    });

    setRUTATractor({
      imgURI: premiumPhotos.routePhoto,
      imgStatus:
        premiumPhotos.confirmedRoutePhoto !== null ? premiumPhotos.confirmedRoutePhoto : null,
      expiration:
        premiumPhotos.routeExpiration !== ''
          ? moment(premiumPhotos.routeExpiration).format('DD/MM/YYYY')
          : '',
    });

    setTractorInsurance({
      imgURI: premiumPhotos.tractorInsurancePhoto,
      imgStatus:
        premiumPhotos.confirmedTractorInsurancePhoto !== null
          ? premiumPhotos.confirmedTractorInsurancePhoto
          : null,
      expiration:
        premiumPhotos.tractorInsuranceExpiration !== ''
          ? moment(premiumPhotos.tractorInsuranceExpiration).format('DD/MM/YYYY')
          : ' - ',
    });
    setTrailerVTV({
      imgURI: premiumPhotos.trailerVtvPhoto,
      imgStatus:
        premiumPhotos.confirmedTractorInsurancePhoto !== null
          ? premiumPhotos.confirmedTractorInsurancePhoto
          : null,
      expiration: premiumPhotos.trailerVtvExpiration
        ? moment(premiumPhotos.trailerVtvExpiration).format('DD/MM/YYYY')
        : ' - ',
    });
    setTrailerInsurance({
      imgURI: premiumPhotos.trailerInsurancePhoto,
      imgStatus:
        premiumPhotos.confirmedTrailerInsurancePhoto !== null
          ? premiumPhotos.confirmedTrailerInsurancePhoto
          : null,
      expiration:
        premiumPhotos.trailerInsuranceExpiration !== ''
          ? moment(premiumPhotos.trailerInsuranceExpiration).format('DD/MM/YYYY')
          : ' - ',
    });
  };

  const additionalImgList = () => {
    const photoUpdateList = [];

    if (truckerLicencePhoto !== '') {
      photoUpdateList.push({
        photoTag: 'Foto licencia de conducir',
        photoName: 'truckerLicensePhoto',
        photoFile: truckerLicencePhoto,
      });
    }

    if (tractorVTVImg !== '') {
      photoUpdateList.push({
        photoTag: 'Foto VTV tractor',
        photoName: 'tractorVTVPhoto',
        photoFile: tractorVTVImg,
      });
    }

    if (RUTATractorPhoto !== '') {
      photoUpdateList.push({
        photoTag: 'Foto RUTA tractor',
        photoName: 'routePhoto',
        photoFile: RUTATractorPhoto,
      });
    }

    if (tractorInsurancePhoto !== '') {
      photoUpdateList.push({
        photoTag: 'Foto seguro tractor',
        photoName: 'tractorInsurancePhoto',
        photoFile: tractorInsurancePhoto,
      });
    }

    if (trailerVtvPhoto !== '') {
      photoUpdateList.push({
        photoTag: 'Foto VTV Remolque',
        photoName: 'trailerVtvPhoto',
        photoFile: trailerVtvPhoto,
      });
    }

    if (trailerInsurancePhoto !== '') {
      photoUpdateList.push({
        photoTag: 'Foto seguro Remolque',
        photoName: 'trailerInsurancePhoto',
        photoFile: trailerInsurancePhoto,
      });
    }

    return photoUpdateList;
  };

  useEffect(() => {
    setAdditionalPhotosList(additionalImgList());
  }, [
    truckerLicencePhoto,
    tractorVTVImg,
    RUTATractorPhoto,
    tractorInsurancePhoto,
    trailerVtvPhoto,
    trailerInsurancePhoto,
  ]);

  useEffect(() => {
    buildImgStates();
  }, []);

  return (
    <AdditionalPhotosWrap>
      <h2>Fotos Adicionales</h2>
      <Row>
        <h3>Chofer</h3>
        <Header />
        <p>Licencia de conducir</p>
        <Footer>
          {truckerLicence ? (
            <DocumentStatus documentData={truckerLicence} setImg={setTruckerLicencePhoto} />
          ) : (
            ''
          )}
        </Footer>
      </Row>
      <Row>
        <h3>Tractor</h3>
        <Header />
        <p>V.T.V Tractor (R.T.O)</p>
        <Footer>
          {tractorVtv ? <DocumentStatus documentData={tractorVtv} setImg={setTractorVTVImg} /> : ''}
        </Footer>
        <p>R.U.T.A</p>
        <Footer>
          {RUTATractor ? (
            <DocumentStatus documentData={RUTATractor} setImg={setRUTATractorPhoto} />
          ) : (
            ''
          )}
        </Footer>
        <p>Seguro obligatorio tractor</p>
        <Footer>
          {tractorInsurance ? (
            <DocumentStatus documentData={tractorInsurance} setImg={setTractorInsurancePhoto} />
          ) : (
            ''
          )}
        </Footer>
      </Row>
      <Row>
        <h3>Remolque</h3>
        <Header />
        <p>V.T.V Remolque (R.T.O)</p>
        <Footer>
          {trailerVTV ? (
            <DocumentStatus documentData={trailerVTV} setImg={setTrailerVtvPhoto} />
          ) : (
            ''
          )}
        </Footer>
        <p>Seguro obligatorio remolque</p>
        <Footer>
          {trailerInsurance ? (
            <DocumentStatus documentData={trailerInsurance} setImg={setTrailerInsurancePhoto} />
          ) : (
            ''
          )}
        </Footer>
      </Row>
    </AdditionalPhotosWrap>
  );
};

AdditionalPhotos.propTypes = {
  premiumPhotos: PropTypes.objectOf(object),
  setAdditionalPhotosList: func.isRequired,
};

AdditionalPhotos.defaultProps = {
  premiumPhotos: {},
};

export default AdditionalPhotos;
