import { localStorageKeyAuth } from './constants';

function saveSessionUserData(data) {
  window.localStorage.setItem(localStorageKeyAuth, JSON.stringify(data));
}

function getSessionUserData() {
  return JSON.parse(window.localStorage.getItem(localStorageKeyAuth));
}

function removeSessionUserData() {
  window.localStorage.removeItem(localStorageKeyAuth);
}

export { getSessionUserData, saveSessionUserData, removeSessionUserData };
