import styled from 'styled-components';

const GasOrderWrap = styled.div`
  background: slategray;
  padding: 20px;
  border: 1px solid gray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: white;
    padding-bottom: 40px;
  }

  p {
    color: white;
    text-align: left;
  }

  p.error {
    color: white;
    margin-top: 10px;
    background: #ff4f4f;
    padding: 10px;
    border-radius: 5px;
  }

  .create-btn-wrap {
    height: 7pc;
  }

  .create-btn {
    outline: none;
    background: #ffbb0f;
    border: none;
    padding: 8px 18px;
    width: 300px;
    margin-top: 40px;
    border-radius: 20px;
  }
`;

export default { GasOrderWrap };
