import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes, { number } from 'prop-types';
import CSS from './pagination-styles';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  return (
    <CSS.PaginationWrap>
      <CSS.GoToWrap>
        <p>Ir a página</p>
        <input
          type="number"
          defaultValue={pageIndex + 1}
          min={0}
          onChange={e => {
            const p = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(p);
          }}
        />
        <span className="page-counter">
          {pageIndex + 1} / {pageOptions.length}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(size => (
            <>
              <option key={size} value={size}>
                Filas {size}
              </option>
            </>
          ))}
        </select>
      </CSS.GoToWrap>
      <CSS.ControlsWrapper>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </button>
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </button>
      </CSS.ControlsWrapper>
    </CSS.PaginationWrap>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.shape(number)),
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  pageOptions: [],
};

export default Pagination;
