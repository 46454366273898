/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import {
  AutoComplete,
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Select,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import client from '../../../../api/api';
import { getSessionUserData } from '../../../../libs/user';
import { useModal } from '../../../Global/useModalHook';
import './CartaPorteCreate.css';
import useTable from './CartaPorteTransportTable';
import CommonDestinationsModal from './CommonDestinationsModal';

const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 20 },
};

const {
  endpoints: { shipper, generic },
} = client;

const openNotification = (title, description, duration = 4.5) => {
  const args = {
    message: title,
    description,
    duration,
  };
  notification.open(args);
};

const CartaPorteCreate = ({ load, shipments, onCancel }) => {
  const [form] = Form.useForm();
  const [formLayout] = useState('horizontal');
  const [originOptions, setOriginOptions] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);
  const [destinationPlants, setDestinationPlants] = useState([]);
  const [itemModalOpen, setModalOpen, toggleModal] = useModal(false);
  const [loading, setLoading] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState();
  const [createCPEResponse, setCreateCPEResponse] = useState();
  const [WaybillTable, tableData, setTableData] = useTable({
    data: shipments
      .filter(s => s.status !== 'cancelada')
      .map(s => ({
        ...s,
        ...{ grossWeight: 45000, tareWeight: 15000, shiftCode: '' },
      })),
  });

  const onFinish = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const data = {
        branch: 1,
        applicantCuit: getSessionUserData().data.cuit,
        origin: {
          producer: {
            provinceCode: values.originProvinceCode,
            cityCode: values.originCityCode,
          },
        },
        destination: {
          provinceCode: selectedPlant.provinceCode,
          cityCode: selectedPlant.cityCode,
          cuit: values.destinationCuit,
          isField: false,
          plant: selectedPlant.id,
        },
        addresseeCuit: values.addresseeCuit,
        isApplicantField: true,
        loadData: {
          grainCode: load.afipGrainCode,
          harvestYear: values.harvestYear,
        },
        observations: values.observations,
      };
      data.transport = tableData
        .filter(shipment => !shipment.tripDocument)
        .map(shipment => ({
          shipmentId: shipment._id,
          freightPayerCuit: 30715441205,
          carrierCuit: shipment.trucker.carrier
            ? shipment.trucker.carrier.cuit
            : shipment.trucker.truckerCuit,
          truckerCuit: shipment.trucker.truckerCuit,
          trucksLicense: [shipment.truck.truckPatent, shipment.truck.trailerPatent],
          // trucksLicense: ['ET999OE', 'ET999OZ'],
          ...(shipment.shiftCode && { shiftCode: shipment.shiftCode }),
          departureDateTime: values.loadDate,
          kilometers: values.distance,
          fare: values.fare,
          fumigatedGoods: true,
          grossWeight: shipment.grossWeight,
          tareWeight: shipment.tareWeight,
        }));
      const {
        data: { errors, result },
      } = await shipper.afipCreateWaybill(data);
      if (errors.length) {
        errors.forEach(e => message.error(e));
        return;
      }
      message.success(`Cartas de porte creadas: ${result.map(r => r.ctgNumber).join(' - ')}`);
      setCreateCPEResponse(result);
    } catch (error) {
      console.error(error);
      if (error.errorFields) {
        message.error(
          `${form.getFieldInstance(error.errorFields[0].name[0]).labels[0].innerHTML}: ${
            error.errorFields[0].errors[0]
          }`
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchAfipCityOrigin = async ({ cuit, name }) => {
    try {
      setLoading(true);
      const { data } = await shipper.afipCitiesByProducer(cuit, name);
      if (data.length >= 1) {
        setOriginOptions(
          data.map(d => {
            return {
              id: d.code,
              value: d.name,
              provinceName: d.province.name,
              provinceCode: d.province.code,
              text: `${d.name} - ${d.province.name}`,
            };
          })
        );
      }
      return data;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  const onSelectOrigin = async (value, option) => {
    const selectedOrigin = originOptions.find(o => o.id === option.id);
    form.setFieldsValue({
      origin: `${selectedOrigin.value}, ${selectedOrigin.provinceName}`,
      originProvinceCode: selectedOrigin.provinceCode,
      originCityCode: selectedOrigin.id,
    });
  };

  const setDefaultOrigin = async () => {
    setLoading(true);
    try {
      if (!load.fromAfipCityCode) {
        openNotification(
          'Ciudad Origin',
          'No pudimos establecer automáticamente la ciudad de origen, por favor completala manualmente',
          0
        );
        await fetchAfipCityOrigin({ cuit: getSessionUserData().data.cuit, name: '' });
        return;
      }
      const { data: defaultOrigin } = await shipper.afipCities(null, load.fromAfipCityCode);
      form.setFieldsValue({
        origin: `${defaultOrigin[0].name}, ${defaultOrigin[0].province.name}`,
        originProvinceCode: defaultOrigin[0].province.code,
        originCityCode: defaultOrigin[0].code,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAfipCityDestination = async (city, code) => {
    if (city && city.length > 2) {
      try {
        setLoading(true);
        const { data } = await shipper.afipCities(city, code);
        setLoading(false);
        if (data.length > 1) {
          setDestinationOptions(
            data.map(d => {
              return {
                id: d.code,
                value: d.name,
                label: <span>{`${d.name} - ${d.province.name}`}</span>,
              };
            })
          );
        }
        return data;
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    return null;
  };

  const onSelectDestination = async (value, option) => {
    const selectedDestination = destinationOptions.find(o => o.id === option.id);
    form.setFieldsValue({
      destination: `${selectedDestination.value}, ${selectedDestination.provinceName}`,
      destinationProvinceCode: selectedDestination.provinceCode,
      destinationCityCode: selectedDestination.id,
    });
  };

  const setDefaultDestination = async () => {
    setLoading(true);
    try {
      if (!load.destinationPlantCode) {
        openNotification(
          'Ciudad Destino',
          'No pudimos establecer automáticamente los datos de destino, por favor completalos manualmente',
          0
        );
        return;
      }
      const {
        data: [destination],
      } = await generic.waybillCommonDestinations({
        plantCode: load.destinationPlantCode,
      });

      form.setFieldsValue({
        destination: `${destination.cityName}, ${destination.provinceName}`,
        destinationProvinceCode: destination.provinceCode,
        destinationCityCode: destination.cityCode,
        addresseeLegalName: destination.addressee,
        addresseeCuit: destination.addresseeCuit,
        destinationLegalName: destination.destination,
        destinationCuit: destination.destinationCuit,
      });

      const { data: plants } = await shipper.afipActivePlants(destination.destinationCuit);

      setDestinationPlants(
        plants.map(r => ({
          id: r.plantNumber,
          value: r.plantNumber,
          provinceCode: r.provinceCode,
          cityCode: r.localityCode,
          text: `${r.localityName} - ${r.provinceName}`,
        }))
      );

      setSelectedPlant({
        id: destination.plantCode,
        value: destination.plantCode,
        provinceCode: destination.provinceCode,
        cityCode: destination.cityCode,
        text: `${destination.cityName} - ${destination.provinceName}`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectPlant = (value, option) => {
    setSelectedPlant(destinationPlants.find(dp => dp.id === option.value));
  };

  const fetchDestinationActivePlants = async cuit => {
    setLoading(true);
    try {
      const { data } = await shipper.afipActivePlants(cuit);
      setDestinationPlants(
        data.map(d => ({
          id: d.plantNumber,
          value: d.plantNumber,
          provinceCode: d.provinceCode,
          cityCode: d.localityCode,
          text: `${d.localityName} - ${d.provinceName}`,
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSelectCommonDestination = async returnValue => {
    try {
      const { data: destination } = await shipper.afipCities(null, returnValue.cityCode);
      await fetchDestinationActivePlants(returnValue.destinationCuit);
      form.setFieldsValue({
        destination: `${destination[0].name}, ${destination[0].province.name}`,
        destinationProvinceCode: destination[0].province.code,
        destinationCityCode: destination[0].code,
        addresseeCuit: returnValue.addresseeCuit,
        destinationCuit: returnValue.destinationCuit,
        destinationPlant: `${returnValue.address}, ${returnValue.cityName}, ${returnValue.cityName}`,
      });
      setModalOpen(false);
    } catch (error) {
      console.error(error);
      message.error(error.response.data);
    }
  };

  useEffect(() => {
    form.resetFields();
    setDefaultOrigin();
    setDefaultDestination();
    form.setFieldsValue({
      distance: load.distance.split(' ')[0],
      fare: load.fare,
      loadDate: moment(load.loadDate),
    });
  }, []);

  useEffect(() => {
    if (createCPEResponse) {
      setTableData(
        tableData.map(row => ({
          ...row,
          ...{
            tripDocument: createCPEResponse.find(r => r.shipmentId === row._id).ctgNumber,
          },
        }))
      );
    }
  }, [createCPEResponse]);

  return (
    <Form {...layout} form={form} onFinish={onFinish} layout={formLayout} size="small">
      <Form.Item
        label="Ciudad origen"
        name="origin"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá el origen de la carga',
          },
        ]}
      >
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{ width: '100%' }}
          options={originOptions}
          onSelect={onSelectOrigin}
          onSearch={value =>
            fetchAfipCityOrigin({
              cuit: getSessionUserData().data.cuit,
              name: value,
            })
          }
        >
          <Input.Search size="small" placeholder="Origen" enterButton />
        </AutoComplete>
      </Form.Item>
      <Form.Item name="originCityCode" hidden></Form.Item>
      <Form.Item name="originProvinceCode" hidden></Form.Item>
      <Form.Item label="Ciudad Destino">
        <Form.Item
          name="destination"
          rules={[
            {
              required: true,
              message: 'Por favor ingresá el destino de la carga',
            },
          ]}
        >
          <AutoComplete
            dropdownMatchSelectWidth={252}
            style={{ width: '100%' }}
            options={destinationOptions}
            onSelect={onSelectDestination}
            onSearch={value => fetchAfipCityDestination(value)}
          >
            <Input.Search size="small" placeholder="Destino" enterButton />
          </AutoComplete>
        </Form.Item>
        <Tooltip>
          <Button style={{ fontSize: 12 }} type="link" onClick={toggleModal}>
            Frecuentes
          </Button>
        </Tooltip>
      </Form.Item>
      <CommonDestinationsModal
        visible={itemModalOpen}
        handleClose={() => setModalOpen(false)}
        callback={onSelectCommonDestination}
      />
      <Form.Item name="destinationCityCode" hidden></Form.Item>
      <Form.Item name="destinationProvinceCode" hidden></Form.Item>
      <Form.Item
        label="Distancia"
        name="distance"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá la distancia del viaje',
          },
        ]}
      >
        <InputNumber
          formatter={value => `${value} kms`}
          placeholder="Distancia"
          onChange={value => {
            form.setFieldsValue({
              distance: value,
            });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Tarifa"
        name="fare"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá la tarifa de la carga',
          },
        ]}
      >
        <InputNumber
          formatter={value => `$ ${value}/${load.fareType}`}
          placeholder="Tarifa"
          onChange={value => {
            form.setFieldsValue({
              fare: value,
            });
          }}
        />
      </Form.Item>

      <Form.Item label="Fecha de Partida" name="loadDate">
        <DatePicker showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
      </Form.Item>
      <Collapse ghost>
        <Panel
          showArrow={false}
          header={
            <Button style={{ fontSize: 12 }} type="link">
              Agregar intervinientes
            </Button>
          }
        >
          <Form.Item label="Rte. Cial. Venta Primaria" name="businessSenderPrimarySaleCuit">
            <Input placeholder="Cuit Rte. Comercial Venta Primaria" />
          </Form.Item>
          <Form.Item label="Rte. Cial. Venta Secundaria" name="businessSenderSecondarySaleCuits">
            <Input placeholder="Cuit Rte. Comercial Venta Secundaria (ingresar separados por coma)" />
          </Form.Item>
          <Form.Item label="Corredor Venta Primaria" name="brokerPrimarySaleCuit">
            <Input placeholder="Cuit Corredor Venta Primaria" />
          </Form.Item>
          <Form.Item label="Corredor Venta Secundaria" name="brokerSecondarySaleCuit">
            <Input placeholder="Cuit Corredor Venta Secundaria" />
          </Form.Item>
          <Form.Item label="Rte. Comercial Productor" name="producerBusinessSenderCuit">
            <Input placeholder="Rte. Comercial Productor" />
          </Form.Item>
          <Form.Item label="Representante Entregador" name="deliverRepresentativeCuit">
            <Input placeholder="Representante Entregador" />
          </Form.Item>
          <Form.Item label="Representante Recibidor" name="receiverRepresentativeCuit">
            <Input placeholder="Representante Recibidor" />
          </Form.Item>
        </Panel>
      </Collapse>

      <Form.Item
        label="Cuit destinatario"
        name="addresseeCuit"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá la CUIT del destinatario',
          },
        ]}
      >
        <Input placeholder="Cuit destinatario" />
      </Form.Item>

      <Form.Item
        label="Cuit destino"
        name="destinationCuit"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá la CUIT del destino',
          },
        ]}
      >
        <Input
          placeholder="Cuit destino"
          onChange={e => {
            if (e.target.value.length === 11) {
              fetchDestinationActivePlants(e.target.value);
            }
          }}
        />
      </Form.Item>
      <Form.Item label="Planta Destino">
        <Select
          value={selectedPlant}
          showSearch
          style={{ width: 500 }}
          placeholder="Seleccioná destino"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          filterSort={(optionA, optionB) => {
            return optionA.children
              .toString()
              .toLowerCase()
              .localeCompare(optionB.children.toString().toLowerCase());
          }}
          disabled={!destinationPlants.length}
          onSelect={onSelectPlant}
        >
          {destinationPlants &&
            destinationPlants.map(({ value, text }) => (
              <Option value={value} key={value}>
                {text}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Cosecha"
        name="harvestYear"
        rules={[
          {
            required: true,
            message: 'Por favor ingresá el año de la cosecha',
          },
        ]}
        validateTrigger="onChange"
      >
        <InputNumber style={{ width: 200 }} placeholder="Cosecha" min={2000} max={9999} />
      </Form.Item>
      <Form.Item label="Observaciones" name="observations">
        <Input placeholder="Observaciones" />
      </Form.Item>
      {WaybillTable}
      <Form.Item style={{ marginTop: 5 }}>
        <Space>
          <Button type="primary" htmlType="submit">
            Solicitar carta de porte
          </Button>
          <Button type="default" onClick={onCancel}>
            Cancelar
          </Button>
          <Spin spinning={loading} />
        </Space>
      </Form.Item>
    </Form>
  );
};

export default CartaPorteCreate;
