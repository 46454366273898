import styled from 'styled-components';

const PaginationWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40pc;
  margin: 0 auto;
`;

const GoToWrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    color: #ffbb0f;
  }

  input {
    background: gray;
    border: none;
    width: 60px;
    margin: 10px;
    padding: 6px;
    border-radius: 5px;
  }

  select {
    background: gray;
    border: none;
    margin: 10px;
    padding: 8px;
    border-radius: 5px;
  }

  .page-counter {
    text-align: center;
    color: #ffbb0f;
  }
`;

const ControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  button {
    background: transparent;
    border: none;
    color: #ffbb0f;
    font-size: 20px;
  }

  button:disabled {
    background: transparent;
    color: gray;
  }

  button:focus {
    outline: none;
  }
`;

export default { PaginationWrap, GoToWrap, ControlsWrapper };
