/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import ProTable from '@ant-design/pro-table';
import React, { useState, useMemo } from 'react';
import openCPEPdf from '../../../../libs/utils';

const useTable = ({ data }) => {
  const [editableKeys, setEditableRowKeys] = useState(() => data.map(item => item._id));
  const [tableData, setTableData] = useState(data);

  const columns = useMemo(() => [
    {
      title: 'Chofer',
      dataIndex: ['trucker', 'truckerName'],
      width: 250,
      editable: false,
    },
    {
      title: 'Cuit chofer',
      dataIndex: ['trucker', 'truckerCuit'],
      width: 100,
      editable: false,
    },
    {
      title: 'Celular chofer',
      dataIndex: ['trucker', 'truckerCellPhone'],
      width: 110,
      editable: false,
    },
    {
      title: 'Patente Camion',
      dataIndex: ['truck', 'truckPatent'],
      width: 90,
      editable: false,
    },
    {
      title: 'Patente Trailer',
      dataIndex: ['truck', 'trailerPatent'],
      width: 90,
      editable: false,
    },
    {
      title: 'Tipo camión',
      dataIndex: ['truck', 'truckBodyType'],
      width: 100,
      editable: false,
    },
    {
      title: 'Razón social',
      dataIndex: ['trucker', 'billingData', 'businessName'],
      width: 150,
      editable: false,
    },
    {
      title: 'Cuit transportista',
      dataIndex: ['trucker', 'billingData', 'cuit'],
      width: 110,
      editable: false,
    },
    {
      title: 'Peso Bruto',
      dataIndex: ['grossWeight'],
      width: 80,
      valueType: 'digit',
    },
    {
      title: 'Peso Tara',
      dataIndex: ['tareWeight'],
      width: 80,
      valueType: 'digit',
    },
    {
      title: 'Turno',
      dataIndex: ['shiftCode'],
      width: 100,
      editable: () => true,
      fieldProps: {
        placeholder: 'Turno',
      },
    },
    {
      title: 'CTG',
      dataIndex: ['tripDocument'],
      width: 110,
      editable: false,
      render: ctg => {
        if (ctg && ctg !== '-') {
          return (
            <a href="#" onClick={() => openCPEPdf({ ctg })}>
              {ctg}
            </a>
          );
        }
        return 'Aún no creada';
      },
    },
  ]);

  const WaybillTable = useMemo(() => {
    return (
      <ProTable
        columns={columns}
        rowKey={record => record._id}
        dataSource={tableData}
        onChange={setTableData}
        size="small"
        search={false}
        pagination={{
          hideOnSinglePage: true,
        }}
        toolBarRender={false}
        editable={{
          type: 'multiple',
          editableKeys,
          actionRender: (row, config, defaultDoms) => {
            return [defaultDoms.delete];
          },
          onValuesChange: (record, recordList) => {
            setTableData(recordList);
          },
          onChange: setEditableRowKeys,
        }}
      />
    );
  });

  return [WaybillTable, tableData, setTableData];
};

export default useTable;
