import React, { useRef, useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';

const UploadBtn = styled.div`
  .cloud {
    width: 100%;
    height: unset;
  }

  label {
    color: white;
    font-weight: 400;
    font-size: 18px;
    text-align: right;
  }

  button {
    background: transparent;
    border: none;
    width: 45px;
  }

  button:focus {
    outline: none;
  }
`;

const UploadImgBtn = ({ setImg, label }) => {
  const [uploadedOk, setUploadedOk] = useState(false);
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  return (
    <UploadBtn>
      <UploadBtn>
        <label htmlFor="linti">
          {label}
          {uploadedOk ? (
            <button type="button" id="linti" onClick={handleClick}>
              <img src="../../../images/icons/cloud-ok.png" className="cloud" alt="linti" />
            </button>
          ) : (
            <button type="button" id="linti" onClick={handleClick}>
              <img src="../../../images/icons/upload-img-btn.png" className="cloud" alt="linti" />
            </button>
          )}
          <input
            id="linti"
            type="file"
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            onChange={e => {
              setImg(e.target.files[0]);
              setUploadedOk(true);
            }}
          />
        </label>
      </UploadBtn>
    </UploadBtn>
  );
};

UploadImgBtn.propTypes = {
  setImg: func.isRequired,
  label: string.isRequired,
};

export default UploadImgBtn;
