/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import toCurrency from '../../../../libs/currencyUtils';

const List = ({ list, loadSelected, setShowLoadList, setShowLoadDetail }) => {
  const media = {
    tablet: '@media(min-width: 768px)',
    desktop: '@media(min-width: 1366px)',
  };

  const LoadList = styled.ul`
    overflow-x: auto;
    list-style: none;
    white-space: nowrap;
  `;

  const MobileItem = styled.li`
    display: inline-block;
    background: #333750;
    padding: 16px 26px 32px;
    margin-right: 16px;
    border-radius: 22px;
    width: 265px;

    .humber-yellow {
      color: #ffbb0f;
    }

    .humber-green {
      color: #19c482;
    }

    p {
      color: white;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      vertical-align: center;
    }

    button {
      display: none;
    }

    .premium-wrap {
      width: 100%;
      text-align: right;
      padding-bottom: 10px;
    }

    .premium-wrap .premium {
      color: #ffbb0f;
      font-size: 20px;
    }

    .premium-wrap .no-premium {
      color: gray;
      font-size: 20px;
    }

    .sm {
      font-size: 14px;
    }

    .md {
      font-size: 20px;
    }

    .lg {
      font-size: 30px;
      line-height: 34px;
    }

    p.mobile-pending-trucks {
      display: block;
    }

    p.desktop-pending-trucks {
      display: none;
    }

    ${media.desktop} {
      display: none;
      padding: 16px 26px 32px;
      margin-right: 16px;

      p.mobile-pending-trucks {
        display: none;
      }

      p.desktop-pending-trucks {
        display: inline-block;
        padding: 5px 12px;
      }
    }
  `;

  const DesktopItem = styled.li`
    display: none;

    ${media.desktop} {
      display: block;
      border-bottom: 1px solid gray;
      border-left: 3px solid #2b333e;
      cursor: pointer;

      &:hover {
        background: #30354e;
        border-left: 3px solid #ffbb0f;
      }

      p,
      .sm,
      .md,
      .lg {
        font-size: 12px;
        display: inline-block;
        color: white;
      }

      p.date {
        width: 151px;
      }

      p.load-type,
      p.product-type,
      p.from-city,
      p.to-city {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: sub;
      }

      p.load-type {
        width: 80px;
      }

      p.product-type {
        width: 60px;
      }

      p.from-city {
        width: 145px;
      }

      p.to-city {
        width: 90px;
        padding-right: 2px;
      }

      p.fare {
        width: 122px;
      }

      p.distance {
        width: 66px;
      }

      p.pending-trucks {
        width: 38px;
      }

      p.pending-trucks .icon {
        margin: 0 6px;
      }

      .humber-green {
        color: #19c482;
      }

      .humber-yellow {
        color: #ffbb0f;
      }

      button {
        vertical-align: top;
        display: inline-block;
        border-radius: 20px;
        background: #ffbb0f;
        border: none;
        font-size: 10px;
        outline: none;
        padding: 3px 12px;
        margin: 7px;
      }

      .premium-wrap {
        display: inline-block;
        width: 20px;
        text-align: center;
        margin: 4px 3px;
      }

      .premium-wrap .premium {
        font-size: 12px;
      }

      .premium-wrap .no-premium {
        color: gray;
        font-size: 12px;
      }

      p.mobile-pending-trucks {
        display: none;
      }

      p.desktop-pending-trucks {
        display: inline-block;
      }
      p.mobile-pending-trucks {
        display: none;
      }

      p.desktop-pending-trucks {
        display: inline-block;
        padding: 5px 12px;
      }
    }
  `;

  return (
    <LoadList>
      {list.map(load => (
        <span key={load.id}>
          <MobileItem
            onClick={() => {
              loadSelected(load);
              setShowLoadList(false);
            }}
          >
            <div className="premium-wrap">
              <p>
                <FontAwesomeIcon
                  className={load.premium ? 'premium humber-yellow' : 'no-premium'}
                  icon={faCrown}
                />
              </p>
            </div>
            <p className="humber-yellow">
              {moment(load.date)
                .locale('es')
                .format('DD-MMM-YYYY - HH:mm')}{' '}
              hs
            </p>
            <p className="sm">{load.loadType}</p>
            <p className="md">{load.productType}</p>
            <p className="lg">de {load.from}</p>
            <p className="lg">a {load.to}</p>
            <p className="md humber-green">
              ${toCurrency(load.transportFare)} x {load.fareType} + IVA
            </p>
            <p className="sm">{load.distance}</p>
            <p className="sm mobile-pending-trucks">{load.pendingTrucks} camiones pendientes</p>
            <button type="button">Tomar carga</button>
          </MobileItem>
          <DesktopItem
            onClick={() => {
              loadSelected(load);
              setShowLoadDetail(true);
            }}
          >
            <div className="premium-wrap">
              <p>
                {load.premium ? (
                  <FontAwesomeIcon className="premium humber-yellow" icon={faCrown} />
                ) : (
                  <FontAwesomeIcon className="no-premium" icon={faCrown} />
                )}
              </p>
            </div>
            <p className="date">
              {moment(load.date)
                .locale('es')
                .format('DD-MMM-YYYY - HH:mm')}{' '}
              hs
            </p>
            <p className="load-type">{load.loadType}</p>
            <p className="product-type">{load.productType}</p>
            <p className="from-city">de {load.from}</p>
            <p className="to-city">a {load.to}</p>
            <p className="distance">{load.distance}</p>
            <p className="fare humber-green">
              ${toCurrency(load.transportFare)} x {load.fareType} + IVA
            </p>
            <p className="pending-trucks humber-yellow">
              <FontAwesomeIcon icon={faTruckMoving} className="icon" />
              {load.pendingTrucks}
            </p>
            <button
              type="button"
              onClick={() => {
                loadSelected(load);
                setShowLoadList(false);
              }}
            >
              Tomar carga
            </button>
          </DesktopItem>
        </span>
      ))}
    </LoadList>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  setShowLoadList: PropTypes.func.isRequired,
  setShowLoadDetail: PropTypes.func.isRequired,
  loadSelected: PropTypes.func.isRequired,
};

export default List;
