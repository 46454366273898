import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';

const PdfViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(0);
  function onDocumentLoadSuccess() {
    setNumPages(1);
  }
  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      noData={<h4>No se seleccionó ningún archivo</h4>}
    >
      <Page pageNumber={numPages} />
    </Document>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.string.isRequired,
};

export default PdfViewer;
