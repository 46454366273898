/* eslint-disable react/prop-types */
import { Input, message, Modal, Radio, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import client from '../../../../api/api';

const {
  endpoints: { generic },
} = client;

const CommonDestinationsModal = ({ visible, handleClose, callback }) => {
  const [commonDestinations, setCommonDestinations] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState();

  const onSelect = useCallback(
    (e, destinations) => {
      setSelectedDestination(destinations[e.target.value]);
    },
    [selectedDestination, setSelectedDestination]
  );

  const fetchCommonDestinations = async filter => {
    try {
      setLoading(true);
      const { data } = await generic.waybillCommonDestinations();
      if (filter && filter.length > 2) {
        setCommonDestinations(
          data.filter(
            d =>
              d.addressee.toLowerCase().includes(filter) ||
              d.reference.toLowerCase().includes(filter) ||
              d.address.toLowerCase().includes(filter) ||
              d.cityName.toLowerCase().includes(filter) ||
              d.provinceName.toLowerCase().includes(filter)
          )
        );
      } else {
        setCommonDestinations(data);
      }
    } catch (error) {
      console.error(error);
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) fetchCommonDestinations();
  }, [visible]);

  if (visible) {
    return (
      <div>
        <Modal
          destroyOnClose
          title="Destinos Frecuentes"
          visible={visible}
          closable
          onCancel={() => {
            handleClose();
          }}
          onOk={async () => {
            setLoading(true);
            await callback(selectedDestination);
            setLoading(false);
          }}
          style={{ top: 50 }}
          width="70%"
          okText="Aceptar"
          cancelText="Cancelar"
        >
          <div style={{ overflow: 'auto', height: '500px' }}>
            <Input
              style={{ marginBottom: 10 }}
              placeholder="filtrar"
              onChange={({ target: { value } }) => {
                fetchCommonDestinations(value);
              }}
            />
            <Space />
            {commonDestinations.length && (
              <Radio.Group onChange={e => onSelect(e, commonDestinations)}>
                <Space direction="vertical">
                  {commonDestinations.map((c, index) => {
                    return (
                      <Radio value={index} key={c.plantCode}>
                        {`${c.addressee} - ${c.reference} - ${c.address} - ${c.cityName} - ${c.provinceName}`}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            )}
            <Spin spinning={isLoading} />
          </div>
        </Modal>
      </div>
    );
  }
  return null;
};

export default CommonDestinationsModal;
