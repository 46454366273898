import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';

const iconMarkup = renderToStaticMarkup(
  <FontAwesomeIcon icon={faFlagCheckered} className="icon" />
);

const endIcon = divIcon({
  className: 'active-load-end-pin ',
  html: iconMarkup,
  shadowUrl: null,
  shadowSize: [0, 0],
});

export default endIcon;
