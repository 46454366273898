import Login from './Login';
import SignUp from './SignUp';
import LandingPage from './LandingPage';
import Point from './Point';
import Carrier from './Carrier';
import VerifyAccount from './VerifyAccount';
import PasswordReset from './PasswordReset';
import Terms from './Terms';
import SettlementAdvance from './SettlementAdvance';
import SettlementAdvanceTerms from './SettlementAdvanceTerms';

export default {
  Login,
  SignUp,
  LandingPage,
  Point,
  Carrier,
  VerifyAccount,
  PasswordReset,
  Terms,
  SettlementAdvance,
  SettlementAdvanceTerms,
};
