import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as authClient from '../../libs/auth/authClient';

export const AuthContext = React.createContext({});

export default function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const login = async form => {
    try {
      setIsLoading(true);
      await authClient.login(form);
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  const register = async form => await authClient.register(form);
  const passwordResetRequest = async email => await authClient.passwordResetRequest(email);
  const resetPassword = async ({ id, token, newPassFormData }) =>
    await authClient.resetPassowrd({ id, token, newPassFormData });
  const logout = async () => {
    try {
      setIsLoading(true);
      await authClient.logout();
      window.history.push('/');
      setIsAuthenticated(false);
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  const checkAuth = async () => {
    const isAuth = await authClient.isAuthenticated();
    setIsAuthenticated(isAuth);
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        register,
        isAuthenticated,
        isLoading,
        passwordResetRequest,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

AuthProvider.defaultProps = {
  children: {},
};
