import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Image, Box, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import img from './google-play-badge.png';

const InfoBarWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  .wapp-icon {
    font-size: 22px;
  }
`;

const InfoBar = ({ status, text, setIsWappModalOpen }) => {

  const handleButtonClick = () => {
    setIsWappModalOpen(true);
  };

  return (
    <InfoBarWrap>
      <Alert p="0" h="60px" status={status}>
        <Text p="0 20px" fontSize="md">
          {text}
        </Text>
        <Button
          rightIcon={<FontAwesomeIcon className="wapp-icon" icon={faWhatsapp} />}
          colorScheme="whatsapp"
          size="sm"
          onClick={handleButtonClick}
        >
          Enviar
        </Button>
        <Box w="110px" minW="110px" m="0 10px">
          <Image src={img} />
        </Box>
      </Alert>
    </InfoBarWrap>
  );
};

InfoBar.propTypes = {
  status: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
  text: PropTypes.string.isRequired,
};

export default InfoBar;
