/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  Input,
  FormLabel,
  FormControl,
  Textarea,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  useDisclosure,
  Select,
  NumberDecrementStepper,
  useToast,
} from '@chakra-ui/react';

import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import Async from 'react-select/async';
import styled from 'styled-components';
import { getSessionUserData } from '../../../libs/user';
import client from '../../../api/api';
import startIcon from '../../../img/StartIcon';
import endIcon from '../../../img/EndIcon';
import { Cascader } from 'antd';

import jsonData from './data.json';
import axios from 'axios';

const MapWrap = styled.div`
  width: 100%;
  height: 100vh;

  .leaflet-container .leaflet-marker-icon {
    margin-left: -12px !important;
    margin-top: -40px !important;
  }

  .active-load-start-pin {
    font-size: 30px;
    color: #b64dea;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }

  .active-load-end-pin {
    font-size: 30px;
    color: #000;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }
`;
const TODAY_STRING = '(Hoy)';

const VirreyesAddLoadForm = ({ user }) => {
  const toast = useToast();

  const { zone } = user;

  const {
    endpoints: { shipper, generic },
  } = client;

  const costCenters = {
    HB4_Comercial: '1051401111',
    HB4_Desarrollo: '-',
    HB4_Producción: '1051501211',
    Bioceres_Comercial: '1051401100',
    Bioceres_Desarrollo: '-',
    Bioceres_Producción: '1051501201',
  };

  const customProductTypeNames = ['Trigo', 'Soja', 'Vicia'];
  const customUnits = [];

  const mapState = { lat: -36.0, lng: -64.0 };
  const initialMarker = L.latLng(-38.0, -57.55);
  const destinationMarker = L.latLng(-31.53, -68.53);
  const initialbounds = L.latLngBounds(initialMarker, destinationMarker);
  const initialPosition = [mapState.lat, mapState.lng];

  const [position, setPosition] = useState(initialPosition);
  const [productTypes, setProductTypes] = useState([]);
  const [products, setProducts] = useState([]);

  const [units, setUnits] = useState([]);

  const [originMarkerPosition, setOriginMarkerPosition] = useState('');
  const [destinationMarkerPosition, setDestinationMarkerPosition] = useState('');

  const [origin, setOrigin] = useState('');
  const [originId, setOriginId] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [destination, setDestination] = useState('');
  const [distance, setDistance] = useState(0);
  const [flag, setFlag] = useState('');
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const { onClose } = useDisclosure();
  const [stopoverDestination, setStopoverDestination] = useState('');
  const [stopoverDestinationName, setStopoverDestinationName] = useState('');
  const [stopoverClient, setStopoverClient] = useState('');
  const [stopovers, setStopovers] = useState([]);

  const asyncStyles = {
    menu: styles => ({
      ...styles,
      background: '#1c2023',
      border: '1px solid gray',
    }),
    control: baseStyles => ({
      ...baseStyles,
      borderColor: 'grey',
      background: '#1c2023',
      color: 'white !important',
    }),
    option: styles => ({
      ...styles,
      background: '#1c2023',
      color: 'white',
    }),
    valueContainer: styles => ({
      ...styles,
      color: 'white',
    }),
  };

  const labelStyles = {
    margin: 0,
    padding: '7px 10px',
    fontSize: '14px',
    color: 'gray',
  };

  const dividerColor = '#2d343a';

  const initialValues = {
    process: '',
    organization: '',
    requestingArea: '',
    costCenter: '',
    productType: '',
    customLoadType: '',
    grain: false,
    transportUnit: '',
    origin: '',
    destination: '',
    product: '',
    loadDate: '',
    client: '',
    quantity: '',
    unit: '',
    publicComment: '',
    privateComment: '',
  };

  const sideBoxForm = {
    width: '50pc',
    zIndex: 100,
    p: 30,
    bg: '#2d343a',
  };

  // Tamaño máximo de archivo de subir foto de plano: 5MB
  const MAX_FILE_SIZE = 5;

  const uploadFlatImage = useCallback(
    async flatImage => {
      // eslint-disable-next-line no-underscore-dangle
      const fileName = `imagenPlano_${getSessionUserData().data._id}_${new Date().getTime()}.jpg`;

      const newFile = new File([flatImage], fileName, {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', newFile);

      return generic.uploadFlatImage(formData);
    },
    [getSessionUserData]
  );

  const validateRequired = useCallback(value => {
    let error;

    if (!value) {
      error = 'El campo es obligatorio';
    }

    return error;
  }, []);

  // Carga inicial de Productos custom (Trigo, Soja y Vicia).-
  const fetchProducts = useCallback(async () => {
    try {
      const { data } = await shipper.getProductTypes();
      const productsFiltered = data
        .flatMap(({ _id: loadType, tipoProducto }) => tipoProducto.map(tp => ({ ...tp, loadType })))
        .filter(products => customProductTypeNames.includes(products.label))
        .map(({ _id: value, label, loadType }) => ({ value, label, loadType }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setProducts(
        data.map(({ tipoCarga: label, _id: value, tipoProducto: products }) => {
          return {
            label,
            value,
            children: products.map(({ label, _id }) => ({ label, value: _id })),
          };
        })
      );

      setProductTypes(productsFiltered);
    } catch (error) {
      console.error(error);
    }
  }, [shipper]);

  // Carga inicial de Unidades de medida custom (kilogramos, toneladas y unidades).-
  const fetchUnits = useCallback(async () => {
    try {
      const { data } = await shipper.getUnits();
      // Filtrar los objetos con las etiquetas deseadas

      setUnits(data);
    } catch (error) {
      console.error(error);
    }
  }, [shipper]);

  useEffect(() => {
    if (units.length === 0) {
      fetchUnits();
    }
  }, [units, fetchUnits]);

  const placesOptions = useCallback(
    async (inputValue, fieldName) => {
      if (inputValue) {
        try {
          const lowerCaseQuery = inputValue.toLowerCase();

          const filteredData = fieldName === 'origin' ? jsonData.filter(item =>
            item?.zona?.toLowerCase() === zone?.name?.toLowerCase() && item?.establecimiento?.toLowerCase().includes(lowerCaseQuery)
          ) : jsonData.filter(item => item?.establecimiento?.toLowerCase().includes(lowerCaseQuery));

          const localResults = filteredData.map(item => ({
            label: `${item.establecimiento}, ${item.zona}`,
            value: item.maps,
          }));

          return [...localResults];
        } catch (e) {
          console.error(e);
          return [];
        }
      }

      return null;
    },
    [shipper, generic]
  );

  const getGeocode = async (city, flag) => {
    const filteredData = jsonData.filter(item =>
      item.establecimiento.toLowerCase().includes(city.toLowerCase())
    );

    let googleMapsResponse = Promise.resolve([]);
    if (filteredData.length > 0) {
      const firstResult = filteredData[0];
      const params = {
        method: 'GET',
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        params: {
          key: 'AIzaSyBnq1QcKZwrZYdEj2HuPsOKxQvrbm3_j5U',
          latlng: firstResult.maps,
          country: 'AR',
          fields: 'geocode',
        },
      };

      googleMapsResponse = axios
        .request(params)
        .then(response => {
          const results = response.data.results.find(el =>
            el.types.includes('administrative_area_level_2')
          );
          if (results) {
            if (flag === 'origin') {
              setOriginId(results.place_id);
              setOrigin(results.formatted_address);
              setOriginMarkerPosition([
                results.geometry.location.lat,
                results.geometry.location.lng,
              ]);

            } else if (flag === 'destination') {
              setDestinationId(results.place_id);
              setDestination(results.formatted_address);
              setDestinationMarkerPosition([
                results.geometry.location.lat,
                results.geometry.location.lng,
              ]);
            }
          }

          /*if (city) {
          try {
            const res = shipper.getMarkerGeocode(results.formatted_address);
            if (flag === 'origin') {
              setOriginMarkerPosition([
                res.data.results[0].geometry.location.lat,
                res.data.results[0].geometry.location.lng,
              ]);
            }
            if (flag === 'destination') {
              setDestinationMarkerPosition([
                res.data.results[0].geometry.location.lat,
                res.data.results[0].geometry.location.lng,
              ]);
            }
          } catch (error) {
            console.error('Error al buscar la ciudad, intentá de nuevo mas tarde.');
          }
        }*/
        })
        .catch(error => {
          console.error('Error al solicitar datos a Google Maps', error);
          return [];
        });
    }
  };

  const showError = msgError => toast({ title: msgError, status: 'error', isClosable: true });

  const calculateDistance = useCallback(
    async (from, to) => {
      try {

        const { data } = await shipper.getCityDistances(from, to);
        return Number.parseInt(data, 10);
      } catch (error) {
        console.error(error);
        showError('Error al calcular distancia');
        throw error;
      }
    },
    [shipper]
  );

  const recalculateAllDistances = useCallback(
    async newStopovers => {
      // Si tiene paradas, recalcula todas las distancias intermedias.-

      if (originId !== '' && destinationId !== '') {
        setDistance(await calculateDistance(originId, destinationId));
      }

      setStopovers([...newStopovers]);
    },
    [originId, destinationId]
  );

  useEffect(() => {
    // Si cambia origen o destino, y tienen contenido.-
    if (originId !== '' && destinationId !== '') {
      recalculateAllDistances(originId, destinationId);
    }
  }, [originId, destinationId, recalculateAllDistances]);


  useEffect(() => {
    if (products.length === 0) {
      fetchProducts();
    }
  }, [products, fetchProducts]);

  const clearForm = actions => {
    actions.resetForm();

    originRef.current.select.select.clearValue();
    destinationRef.current.select.select.clearValue();
    setOriginMarkerPosition('');
    setDestinationMarkerPosition('');
    setOriginId('');
    setDestinationId('');
    setDistance(0);

    setStopoverDestination('');
    setStopoverDestinationName('');
    setStopoverClient('');
    setStopovers([]);
  };

  const buildLoadData = useCallback(
    async values => {
      const {
        flatImage,
        process,
        organization,
        requestingArea,
        costCenter,
        customLoadType,
        grain,
        transportUnit,
        productType,
        unit,
        ...rest
      } = values;

      let planImageName;

      if (flatImage) {
        const resUpload = await uploadFlatImage(flatImage);
        planImageName = resUpload.data.result.files.file[0].name;
      }

      const { loadType } = productTypes.find(pt => pt.value === productType);

      const fareType = units.find(u => u.value === unit)?.label === 'toneladas' ? 'TN' : 'viaje';

      return {
        distance,
        duration: 8,
        requestedTruckers: 1,
        status: 'close',
        unit,
        loadType,
        productType,
        fareType,
        fare: 0,
        ...(planImageName && { planImageName }),
        ...rest,
        custom: {
          process,
          organization,
          requestingArea,
          costCenter,
          customLoadType,
          grain,
          transportUnit,
        },
        ...(stopovers.length && {
          // eslint-disable-next-line no-shadow
          stopovers: stopovers.map(({ destination, client, distance }) => ({
            destination,
            client,
            distance,
          })),
        }),
      };
    },
    [distance, productTypes, units, stopovers, uploadFlatImage]
  );

  const commonValidationRules = [{ required: true, message: 'El campo es obligatorio' }];

  const onSubmit = useCallback(
    async (values, actions) => {
      try {

        const loadDateAndTime = `${values.loadDate}:00.000Z`;

        const formData = new FormData();
        formData.append('productQuantity', Number.parseInt(values.quantity, 10));
        formData.append('trucksRequested', 1);
        formData.append('loadDate', loadDateAndTime);
        formData.append('fromCity', origin);
        formData.append('toCity', destination);
        formData.append('fromGeoCodeLat', originMarkerPosition[0]);
        formData.append('fromGeoCodeLong', originMarkerPosition[1]);
        formData.append('toGeoCodeLat', destinationMarkerPosition[0]);
        formData.append('toGeoCodeLong', destinationMarkerPosition[1]);
        formData.append('fare', 0);
        formData.append('fareType', 'viaje');
        formData.append('distance', `${distance.toString()} Km`);
        formData.append('shipmentInsurance', false);
        formData.append('insuranceFare', '130000');
        formData.append('publicComment', values.publicComment);
        formData.append('privateComment', values.privateComment);
        formData.append('publicationTime', 0);
        formData.append('productId', values.product[1]);
        formData.append('unitId', values.unit);
        formData.append('hour', loadDateAndTime);
        formData.append('adjustmentPercentage', 0);
        formData.append('insuranceCategory', 4);
        formData.append('loadOrigin', 'Otros');
        formData.append('flatImage', '');
        formData.append('notify', true);
        formData.append('status', 'open');

        const load = { formData };

        const { data } = await shipper.addLoad(load);

        toast({
          title: 'Carga creada con éxito',
          status: 'success',
          isClosable: true,
        });

        clearForm(actions);
      } catch (error) {
        console.error(error);
        showError('Error al crear carga');
      } finally {
        // actions.setSubmitting(false);
      }
    },
    [buildLoadData, clearForm, shipper]
  );

  const FormObserver = ({ props }) => {
    const { values } = useFormikContext();

    useEffect(() => {
      const { organization, requestingArea } = values;

      if (organization && requestingArea) {
        const costCenterValue = costCenters[`${organization.split(' ')[0]}_${requestingArea}`];
        props.setFieldValue('costCenter', costCenterValue, false);
      }
    }, [values]);

    return null;
  };

  const onDeleteStopover = index => {
    stopovers.splice(index, 1);
    recalculateAllDistances(stopovers);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {props => (
        <Flex>
          <Flex>
            <Form>
              <Box {...sideBoxForm} position="relative">
                <VStack>
                  <Field name="origin" validate={validateRequired}>
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.touched.origin && Boolean(form.errors.origin)}
                      >

                        <div className="humber-dropdown">
                          <FormLabel style={labelStyles}>Origen (Zona {zone?.name})</FormLabel>

                          <Async
                            ref={originRef}
                            styles={asyncStyles}
                            placeholder="Ingresá ciudad"
                            className="dropdown"
                            defaultOptions
                            loadOptions={(inputValue) => placesOptions(inputValue, 'origin')}
                            onBlur={() => {
                              setFlag('origin');
                              form.setFieldTouched('origin', true);
                            }}
                            onChange={opt => {
                              setFlag('origin');
                              form.setFieldValue('origin', opt?.value || '');
                              if (opt) {
                                getGeocode(opt.label.split(',')[0], 'origin');
                              }
                            }}
                          />
                        </div>
                        <FormErrorMessage>{form.errors.origin}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="destination" validate={validateRequired}>
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.destination && form.touched.destination}
                      >
                        <div className="humber-dropdown">
                          <FormLabel style={labelStyles}>Destino</FormLabel>
                          <Async
                            ref={destinationRef}
                            styles={asyncStyles}
                            placeholder="Ingresá ciudad"
                            colorScheme="blackAlpha"
                            className="dropdown"
                            defaultOptions
                            loadOptions={(inputValue) => placesOptions(inputValue, 'destination')}
                            onBlur={() => {
                              form.setFieldTouched('destination', true);
                            }}
                            onChange={opt => {
                              form.setFieldValue('destination', opt?.value || '');
                              if (opt) {
                                getGeocode(opt.label.split(',')[0], 'destination');
                              }
                            }}
                          />
                        </div>
                        <FormErrorMessage>{form.errors.destination}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="product" validate={validateRequired}>
                    {({ field, form }) => (
                      <Flex width="100%" justifyContent="center">
                        <FormControl
                          isRequired
                          isInvalid={form.errors.product && form.touched.product}
                        >
                          <FormLabel style={labelStyles}>Selecione el Producto</FormLabel>

                          <Cascader
                            style={{ width: '100%' }}
                            placeholder="Seleccioná producto"
                            options={products}
                            expandTrigger="hover"
                            // displayRender={(labels) => labels[labels.length - 1]}
                            showSearch={(inputValue, path) =>
                              path.some(
                                option =>
                                  option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                              )
                            }
                            onChange={val => form.setFieldValue(field.name, val)}
                          />

                          <FormErrorMessage>{form.errors.product}</FormErrorMessage>
                        </FormControl>
                      </Flex>
                    )}
                  </Field>
                  <Flex justifyContent="space-between" flexDirection="row" w="100%">
                    <Box>
                      <Field name="quantity" w="14pc" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={form.errors.quantity && form.touched.quantity}
                          >
                            <FormLabel style={labelStyles}>Cantidad de producto</FormLabel>
                            <NumberInput
                              {...field}
                              w="195px"
                              min={1}
                              max={1000000}
                              onChange={val => form.setFieldValue(field.name, val)}
                              no-validate
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="loadDate" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            m="0 10px 0 0"
                            isRequired
                            isInvalid={form.errors.loadDate && form.touched.loadDate}
                          >
                            <FormLabel style={labelStyles}>Fecha y hora de carga</FormLabel>
                            <Input {...field} type="datetime-local" w="195px" />
                            <FormErrorMessage>{form.errors.loadDate}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box>
                      <Field name="unit" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl isRequired isInvalid={form.errors.unit && form.touched.unit}>
                            <FormLabel style={labelStyles}>Unidades</FormLabel>
                            <Select {...field} w="195px" placeholder="Seleccioná...">
                              {units.map(unitName => (
                                <option key={unitName.value} value={unitName.value}>
                                  {unitName.label}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>{form.errors.unit}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="client">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel style={labelStyles}>Cliente destino</FormLabel>
                            <Input w="195px" {...field} />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" w="100%">
                    <Box p="10px" w="14pc">
                      <Field name="publicComment">
                        {({ field }) => (
                          <FormControl>
                            <Text mb="8px">Comentarios públicos</Text>
                            <Textarea
                              {...field}
                              id="publicComment"
                              placeholder="Este comentario lo van a ver todos los interesados en la carga..."
                              size="sm"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box p="10px" w="14pc">
                      <Field name="privateComment">
                        {({ field }) => (
                          <FormControl>
                            <Text mb="8px">Comentarios privados</Text>
                            <Textarea
                              {...field}
                              placeholder="Este comentario lo van a ver todos los interesados en la carga..."
                              size="sm"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>
                  <Button
                    mt={4}
                    bg="#ffbb0f"
                    alignSelf="end"
                    colorScheme="blue"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Subir carga
                  </Button>
                </VStack>
              </Box>
            </Form>
          </Flex>

          <Flex w="60%">
            <MapWrap>
              <Map
                center={position}
                zoom={13}
                bounds={initialbounds}
                scrollWheelZoom={false}
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: 10,
                }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {originMarkerPosition && (
                  <Marker position={originMarkerPosition} icon={startIcon} />
                )}
                {destinationMarkerPosition && (
                  <Marker position={destinationMarkerPosition} icon={endIcon} />
                )}
              </Map>
            </MapWrap>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};
export default VirreyesAddLoadForm;
