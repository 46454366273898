import { localStorageKeyWebVersion } from './constants';

function saveLastWebVersion(data) {
  window.localStorage.setItem(localStorageKeyWebVersion, JSON.stringify(data));
}

function getLastWebVersion() {
  return JSON.parse(window.localStorage.getItem(localStorageKeyWebVersion));
}

function removeLastWebVersion() {
  window.localStorage.removeItem(localStorageKeyWebVersion);
}

export { getLastWebVersion, saveLastWebVersion, removeLastWebVersion };
