/* eslint-disable no-underscore-dangle */
import client from '../../api/api';
import { getSessionUserData, saveSessionUserData, removeSessionUserData } from '../user';

function handleUserResponse({ data }) {
  saveSessionUserData(data);
  return data;
}

async function isAuthenticated() {
  try {
    const response = await client.endpoints.generic.isAuthenticated({
      userId: getSessionUserData().data._id,
      token: getSessionUserData().access_token,
      scope: getSessionUserData().scope[0],
    });
    return response.data;
  } catch (error) {
    localStorage.clear();
    return false;
  }
}

async function login({ username, password, logAs }) {
  const loginResponse = await client.endpoints.generic.login(username, password, logAs);
  handleUserResponse(loginResponse);
}

async function register(form) {
  const resp = await client.endpoints.generic.signUp(form);
  return resp;
}

async function logout() {
  await client.endpoints.generic.logout();
  removeSessionUserData();
}

async function passwordResetRequest(email) {
  const resp = await client.endpoints.generic.passwordResetRequest(email);
  return resp;
}

async function resetPassowrd({ id, token, newPassFormData }) {
  const resp = await client.endpoints.generic.resetPassword({
    id,
    token,
    newPassFormData,
  });
  return resp;
}

export { login, register, logout, isAuthenticated, passwordResetRequest, resetPassowrd };
