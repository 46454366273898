import React from 'react';
import styled from 'styled-components';

const SuccessWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 768px;

  h1 {
    color: #ffbb0f;
  }
`;

const Success = () => {
  return (
    <SuccessWrap>
      <h1>Camionero agregado con éxito</h1>
    </SuccessWrap>
  );
};

export default Success;
