import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import HumberInput from '../Global/humber-input';
import './sign-up.css';
import client from '../../api/api';
import '../../App.css';

const SignUp = () => {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [legalName, setLegalName] = useState('');
  const [cuit, setCuit] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userType, setUserType] = useState('');
  const [carrierSelected, setCarrierSelected] = useState(false);
  const [shipperSelected, setShipperSelected] = useState(false);
  const [userTypeUrl, setUserTypeUrl] = useState('');
  const [showSignUpSuccess, setShowSignUpSucess] = useState(false);
  const [ERRORMsg, setERRORMsg] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    if (userType === 'carrier') {
      setCarrierSelected(true);
      setShipperSelected(false);
      setUserTypeUrl('/carriers');
    } else if (userType === 'shipper') {
      setCarrierSelected(false);
      setShipperSelected(true);
      setUserTypeUrl('/shippers');
    }
  }, [userType]);

  const validateUserCuit = async () => {
    try {
      if (cuit.length === 11) {
        const billingCUITRes = await client.endpoints.generic.validateBillingCUIT(
          cuit,
          false,
          true
        );
        if (billingCUITRes.status === 200) {
          setLegalName(billingCUITRes.data.name);
        }
      } else {
        throw new Error('cuitLength');
      }
    } catch (error) {
      if (error.message === 'cuitLength') {
        setERRORMsg('El cuit debe tener 11 caracteres');
      }

      if (error.response.data.message) {
        setERRORMsg(error.response.data.message);
      } else {
        setERRORMsg(process.env.REACT_APP_DEFAULT_API_ERROR_MESSAGE);
      }
    }
  };

  async function submit() {
    if (password !== confirmPassword) {
      setERRORMsg('Las contraseñas deben ser iguales');
      return;
    }
    try {
      const signData = new FormData();
      signData.append('username', username);
      signData.append('password', password);
      signData.append('name', `${name}`);
      signData.append('legalName', legalName);
      signData.append('email', email);
      signData.append('phoneNumber', phoneNumber);
      signData.append('cuit', cuit);
      signData.append('acceptTC', acceptTerms);
      console.log("ASKDJASKDJAKSD: ", userTypeUrl);
      const res = await client.endpoints.generic.signIn(signData, userTypeUrl);
      if (res.status === 200) {
        setShowSignUpSucess(true);
      }
    } catch (error) {
      if (error.response.data.message) {
        setERRORMsg(error.response.data.message);
      } else {
        setERRORMsg(process.env.REACT_APP_DEFAULT_API_ERROR_MESSAGE);
      }
    }
  }

  const fieldValidation = () => {
    return true;
  };

  const renderFunct = () => {
    return (
      <>
        <div className="sign-up-landing-wrapper">
          {showSignUpSuccess ? (
            <div id="wrapper" className="signup-wrapper">
              <div className="success-title">
                <h1> Tu cuenta {userType === 'carrier' ? 'Transportista' : 'Dador de carga'} fué creada con éxito!</h1>
              </div>
              <div className="success-account-icon">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
              <div className="success-list">
                <p>Guardá tu clave en un lugar seguro.</p>
                <p>Te enviamos un mail a {email}</p>
                <p>Revisalo para finalizar el proceso</p>
              </div>
            </div>
          ) : (
            // <div className="signup-wrapper signup-wrapper-100">
            <div className={carrierSelected || shipperSelected ? "signup-wrapper" : "signup-wrapper signup-wrapper-100"}>
              <div className="fields">
                <div className="user-type-btn-wrapper">
                  <div class="signup-title">
                    <h1>Creá tu cuenta</h1>
                  </div>

                  <div
                    className={carrierSelected ? 'user-selected' : 'user-type-btn'}
                    role="button"
                    tabIndex={0}
                    onClick={() => setUserType('carrier')}
                  >
                    Transportista
                  </div>
                  <div
                    className={shipperSelected ? 'user-selected' : 'user-type-btn'}
                    role="button"
                    tabIndex={0}
                    onClick={() => setUserType('shipper')}
                  >
                    Dador de carga
                  </div>
                </div>

                {carrierSelected || shipperSelected ? (
                  <>
                    <div className="section-one-wrapper">
                      <div className="lg-custom-wrapper">
                        <HumberInput
                          label="Usuario"
                          value={username}
                          min={0}
                          type="text"
                          onChange={setUsername}
                          onBlur={fieldValidation}
                          id="username"
                          htmlFor="username"
                          errorMsg=""
                          required
                        />
                      </div>
                      <div className="md-custom-wrapper">
                        <HumberInput
                          label="Contraseña"
                          value={password}
                          min={0}
                          type="password"
                          onChange={setPassword}
                          onBlur={fieldValidation}
                          id="password"
                          htmlFor="password"
                          errorMsg=""
                          required
                        />
                      </div>
                      <div className="md-custom-wrapper">
                        <HumberInput
                          label="Repetí la contraseña"
                          value={confirmPassword}
                          min={0}
                          type="password"
                          onChange={setConfirmPassword}
                          onBlur={fieldValidation}
                          id="confirmPassword"
                          htmlFor="confirmPassword"
                          errorMsg=""
                          required
                        />
                      </div>
                    </div>

                    <div className="section-two-wrapper">
                      <div className="lg-custom-wrapper">
                        <HumberInput
                          label="Nombre y Apellido"
                          value={name}
                          min={0}
                          type="text"
                          onChange={setName}
                          onBlur={fieldValidation}
                          id="name"
                          htmlFor="name"
                          errorMsg=""
                          required
                        />
                      </div>

                      <div className="md-custom-wrapper cuit">
                        <HumberInput
                          label="CUIT (Sin guiones)"
                          value={cuit}
                          minLength={11}
                          maxLength={11}
                          placeholder="30715441205"
                          type="text"
                          onChange={setCuit}
                          onBlur={validateUserCuit}
                          id="cuit"
                          htmlFor="cuit"
                          errorMsg=""
                          required
                        />
                      </div>

                      <div className="md-custom-wrapper company-name">
                        <p> {`Razon Social: ${legalName}`} </p>
                      </div>

                      <div className="md-custom-wrapper cel-number">
                        <HumberInput
                          value={phoneNumber}
                          type="text"
                          onChange={setPhoneNumber}
                          onBlur={fieldValidation}
                          label="Teléfono (Sin el 0 y sin el 15)"
                          errorMsg=""
                          minLength={10}
                          maxLength={10}
                          placeholder="1126425830"
                          id="phone"
                          htmlFor="phone"
                          required
                        />
                      </div>

                      <div className="md-custom-wrapper email">
                        <HumberInput
                          label="Email"
                          value={email}
                          placeholder="humber@humber.com.ar"
                          min={0}
                          type="text"
                          onChange={setEmail}
                          onBlur={fieldValidation}
                          id="email"
                          htmlFor="email"
                          errorMsg=""
                          required
                        />
                      </div>
                    </div>
                    <p className="simple-error-msg">{ERRORMsg}</p>
                    <div>
                      <input
                        name="isGoing"
                        type="checkbox"
                        checked={acceptTerms}
                        onChange={() => setAcceptTerms(!acceptTerms)}
                      />
                      <span className="terms">
                        Entiendo y estoy de acuerdo con los
                        <Link to="/terms" target="_blank" className="link">
                          terminos y condiciones
                        </Link>
                      </span>
                    </div>

                    {acceptTerms && (carrierSelected || shipperSelected) ? (
                      <div
                        className="sign-up-humber-btn"
                        tabIndex={0}
                        role="button"
                        onClick={() => submit()}
                      >
                        Crear
                      </div>
                    ) : (
                      <div className="sign-up-humber-btn-disabled" tabIndex={0} role="button">
                        Crear
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return renderFunct();
};

export default SignUp;
