import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving } from '@fortawesome/free-solid-svg-icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';

const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon icon={faTruckMoving} className="icon" />);

const truckIcon = divIcon({
  className: 'active-load-icon ',
  html: iconMarkup,
  shadowUrl: null,
  shadowSize: [0, 0],
});

export default truckIcon;
