import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const Headers = styled.div`
  display: none;
  ${media.desktop} {
    display: block;
    font-size: 12px;
    border-bottom: 1px solid #ffbb0f;
    margin-top: 30px;

    .humber-yellow {
      color: #ffbb0f;
    }

    p {
      display: inline-block;
      color: gray;
    }

    p.trucker-cuit {
      width: 80px;
      margin-left: 11px;
    }

    p.trucker-name {
      width: 160px;
    }

    p.trucker-cel {
      width: 90px;
    }

    p.truck-license {
      width: 60px;
    }

    p.trailer-license {
      width: 66px;
    }

    p.truck-type {
      width: 100px;
    }
  }
`;

const AddLoadWrap = styled.div`
  max-width: 1366px;

  .step-one-wrap {
    height: 90vh;
  }

  .side-info {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .side-info .leaflet-container {
    height: 23pc;
  }

  .leaflet-container .leaflet-marker-icon {
    margin-left: -12px !important;
    margin-top: -40px !important;
  }

  .active-load-start-pin {
    font-size: 30px;
    color: #b64dea;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }
  .active-load-end-pin {
    font-size: 30px;
    color: #000;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }

  .form {
    background: #1c2023;
    padding: 0 20px;
    max-width: 500px;
    margin: 0 auto;
  }

  h2 {
    color: #ffbb0f;
    font-weight: 300;
    font-size: 18px;
    border-bottom: 1px solid;
    padding: 0 0 7px 0;
  }

  h4 {
    color: #ffbb0f;
    font-weight: 300;
    font-size: 18px;
    border-bottom: 1px solid;
    padding: 70px 0 7px 0;
  }

  h4.first {
    padding-top: 20px;
  }

  h3 {
    color: #ffbb0f;
    font-size: 15px;
    font-weight: 400;
    padding: 0 0 0 6px;
  }

  .upload-image-wrapper {
    margin: 0;
  }

  .upload-image-wrapper .btn-upload-wrapper {
    text-align: center;
  }

  .upload-image-wrapper .btn-upload {
    background: #ffbb0f;
    font-size: 16px;
    padding: 4px 40px;
    border-radius: 20px;
    cursor: pointer;
  }

  .btn-wrapper {
    width: 100%;
    padding: 20px 0;
    text-align: right;
  }

  .btn-wrapper .btn-enabled {
    display: none;
  }

  .btn-wrapper .btn-disabled {
    display: none;
  }

  .btn-submit-wrap {
    margin: 40px;
  }

  .btn-submit {
    background: #ffbb0f;
    padding: 10px;
    cursor: pointer;
    border-radius: 40px;
    width: 80%;
    margin: 0 auto;
  }

  .humber-dropdown {
    width: 100%;
    margin: 0 auto;
  }

  .md-input-wrap,
  .sm-input-wrap,
  .xsm-input-wrap,
  .l-input-wrap {
    width: 100%;
  }

  .sm-input-wrap.fare-input {
    width: 10pc;
  }

  .sm-input-wrap.fare-input label input {
    background: #1d2327;
    border-radius: 20px;
    padding: 21px;
    text-align: center;
  }

  .humber-link {
    color: #6495ed;
    font-size: 12px;
    cursor: pointer;
    outline: none;
    padding-left: 20px;
  }

  .closed-load-buttons button {
    color: #6495ed;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    float: right;
    border: none;
    background: transparent;
  }

  .unit label {
    font-size: 12px;
  }

  .closed-load-wrap {
    width: 98%;
    min-height: 18pc;
    margin: 20px 0;
  }

  .closed-load-wrap .filter-wrapper {
    padding: 0 1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin: 0;
    text-align: left;
    display: inline-block;
  }

  .closed-load-buttons button {
    color: #6495ed;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    float: right;
    border: none;
    background: transparent;
  }

  .closed-load-wrap .filter-wrapper label {
    padding: 0 22px 0 0;
    width: 16pc;
  }

  .closed-load-wrap .filter-wrapper input {
    border-radius: 20px;
    margin: 4px -8px;
  }

  .closed-load-wrap .trucker {
    background: transparent;
    width: 45%;
    display: inline-block;
  }

  .closed-load-wrap .trucker button {
    background: transparent;
    padding: 0 20px;
    color: #6495ed;
    font-size: 12px;
    border: none;
    outline: none;
  }

  .closed-load-wrap .trucker-selected p {
    color: white;
  }

  .closed-load-wrap .trucker-selected button {
    background: transparent;
    padding: 0 20px;
    color: red;
    font-size: 12px;
    border: none;
    outline: none;
  }

  .closed-load-wrap .filter-wrapper .search-button {
    background: #6495ed;
    border: none;
    border-radius: 30px;
    padding: 6px 10px;
    color: white;
    cursor: pointer;
    outline: none;
  }

  .closed-load-wrap .filter-wrapper .search-button-disabled {
    background: #323d46;
    border: none;
    border-radius: 30px;
    padding: 6px 10px;
    color: white;
    cursor: pointer;
    outline: none;
  }

  .closed-load-wrap .trucker-list {
    width: 100%;
    display: inline-block;
    padding: 0;
  }

  .closed-load-wrap h3 {
    color: #ffbb0f;
    font-size: 18px;
    float: right;
  }

  .closed-load-wrap .trucker-selected p {
    font-size: 12px;
    display: inline-block;
    padding: 10px;
  }

  .closed-load-wrap .trucker-selected p.trucker-name-value {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0;
    vertical-align: middle;
    width: 150px;
  }

  .closed-load-wrap .trucker-selected p.trailer-license {
    width: 66px;
  }

  .closed-load-wrap .trucker-selected p.pending-trucks {
    width: 32px;
    color: #ffbb0f;
  }

  .closed-load-wrap .trucker-selected p.trucker-cel-value {
    width: 90px;
  }

  .mobile-next-button {
    display: block;
    background: #ffbb0f;
    width: 172px;
    outline: none;
    text-align: center;
    border-radius: 30px;
    font-size: 16px;
    padding: 7px;
    cursor: pointer;
    color: #1c2023;
    float: right;
    margin: 40px 0;
  }

  .mobile-next-button-disabled {
    display: block;
    background: #25262c;
    width: 172px;
    outline: none;
    text-align: center;
    border-radius: 30px;
    font-size: 16px;
    padding: 7px;
    cursor: pointer;
    color: gray;
    float: right;
    margin: 40px 0;
  }

  .flat-image-uri {
    border: 2px dashed #ffbb0f;
    width: 150px;
    height: 100px;
    border-radius: 10px;
    margin: 20px auto;
  }

  .flat-image-uri img {
    width: 100%;
    height: 95px;
    border-radius: 13px;
  }

  .camera-icon {
    color: #ffbb0f;
    font-size: 34px;
    width: 100%;
    margin-top: 30px;
  }

  ${media.tablet} {
    .form {
      width: 50%;
      max-width: unset;
      margin: unset;
    }

    .side-info {
      float: right;
      width: 50%;
      max-width: unset;
      margin: unset;
      background: rgb(48, 53, 78);
    }

    .btn-wrapper {
      padding: 20px 26px;
    }
  }

  ${media.desktop} {
    margin: 0 auto;
    .sm-side-info {
      height: 90vh;
    }
    .lg-side-info,
    .step-two-wrap {
      height: 90pc;
    }

    .form {
      width: 68%;
    }

    .side-info {
      width: 32%;
    }

    .side-info .leaflet-container {
      margin: 14px;
      border-radius: 12px;
    }

    h2 {
      font-size: 22px;
    }

    h4 {
      font-size: 22px;
    }

    .md-input-wrap,
    .humber-dropdown {
      display: inline-block;
      width: 300px;
      margin: 0 7px;
    }

    .xsm-input-wrap {
      display: inline-block;
      width: 110px;
      margin: 0 20px;
    }

    .l-input-wrap {
      display: inline-block;
      width: 400px;
    }

    .sm-input-wrap {
      display: inline-block;
      width: 210px;
      margin: 0 20px;
    }

    .sm-input-wrap .humber-input label {
      padding: 2px;
    }

    .selection-btn-wrapper {
      display: inline-block;
    }

    .upload-image-wrapper {
      margin: 0;
    }

    .btn-wrapper .btn-enabled {
      background: #ffbb0f;
      border: none;
      border-radius: 20px;
      padding: 5px 40px;
      outline: none;
    }

    .btn-wrapper .btn-disabled {
      background: #25262c;
      color: gray;
      border: none;
      border-radius: 20px;
      padding: 5px 40px;
      outline: none;
    }
  }
`;

const LoadTypeWrap = styled.div`
  min-height: 22pc;
`;

const LoadTypeOptionsWrap = styled.div`
  ${media.desktop} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const ProductTypeQuickAccess = styled.div`
  background: #2f3345;
  border-radius: 10px;
  margin-bottom: 40px;
  height: 5pc;

  p {
    color: #ffbb0f;
    padding: 10px 17px 0 17px;
    font-size: 15px;
    margin-top: 20px;
  }

  button {
    background: transparent;
    border: none;
    color: #6495ed;
    padding: 10px 16px 12px 16px;
    outline: none;
  }
`;

const OptionalInfo = styled.div`
  margin: 35px auto;
  ${media.desktop} {
    margin: 0 auto;
  }
`;

const LoadOriginWrap = styled.div`
  padding: 1px;
`;

const LoadDetail = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    width: 90%;
    margin: 0 auto;

    h4 {
      border-bottom: unset;
      color: #fff;
      font-weight: 300;
      text-align: center;
      padding-top: 20px;
    }

    h1 {
      color: #19c482;
      font-weight: 300;
      margin: 30px 0 14px 0;
      font-size: 30px;
      text-align: center;
    }

    .distance-wrap {
      display: inline-block;
      text-align: center;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #ffbb0f;
    }

    p {
      color: #fff;
      font-size: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .distance-wrap p {
      width: 130px;
      margin: 0 auto;
      background: rgb(48, 53, 78);
    }

    p.label {
      color: #ffbb0f;
      font-size: 12px;
      padding-top: 10px;
    }

    .from-wrapper {
      display: inline-block;
      width: 33%;
      vertical-align: top;
    }

    .to-wrapper {
      float: right;
      width: 33%;
    }

    .load-info {
      display: inline-block;
    }

    .trucks-info {
      float: right;
    }

    .last-info {
      padding-top: 40px;
      text-align: center;
    }
  }
`;

const LoadDetailMobile = styled.div`
  padding: 20px;
  margin: 24px 0 55px 0;

  p {
    color: whitesmoke;
  }

  small {
    color: #ffbb0f;
  }

  .btn-submit {
    text-align: center;
  }

  ${media.desktop} {
    display: none;
  }
`;

export default {
  Headers,
  AddLoadWrap,
  LoadTypeWrap,
  ProductTypeQuickAccess,
  LoadTypeOptionsWrap,
  LoadOriginWrap,
  OptionalInfo,
  LoadDetail,
  LoadDetailMobile,
};
