import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const LoadWrap = styled.div`
  border: 1px solid rgb(28, 32, 35);
  border-bottom: 1px solid #363636;
  padding: 5px;
  margin: 10px;
  max-width: 1400px;
  cursor: pointer;

  &:hover {
    border: 1px solid #989898;
    border-radius: 6px;
  }

  .requested-icon,
  p.trucks-requested {
    color: #87cefa;
  }

  .confirmed-icon,
  p.trucks-confirmed {
    color: #19c482;
  }

  .pending-icon,
  p.trucks-pending {
    color: #ffbb0f;
  }

  ${media.desktop} {
    margin: 0;
  }
`;

const LoadMobile = styled.div`
  position: relative;

  p {
    color: white;
    font-size: 14px;
    padding-left: 32px;
  }

  h3 {
    display: inline-block;
    font-size: 16px;
    color: white;
    margin: 0;
  }

  .premium-wrap {
    display: inline-block;
  }

  .premium-wrap p {
    padding: 0 10px 0 0;
    font-size: 18px;
    color: #ffbb0f;
  }

  .trucks-wrap {
    position: absolute;
    top: 20px;
    right: 0;
  }

  .trucks-wrap p {
    display: inline-block;
    padding: 0 8px 0 4px;
  }

  .load-info {
    display: inline-block;
  }

  p.open {
    position: absolute;
    right: 0;
    top: 0;
    background: #29a475;
    color: lightgray;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 800;
    padding: 0px;
    width: 58px;
    text-align: center;
  }

  p.closed {
    position: absolute;
    right: 0;
    top: 0;
    background: #e03f3f;
    color: lightgray;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 800;
    padding: 0px;
    width: 58px;
    text-align: center;
  }

  p.load-hour {
    padding: 0;
  }

  p.load-date {
    font-size: 20px;
    padding-left: 0;
  }
  .fare {
    color: #19c482;
  }

  .delete-pending-wrap {
    position: absolute;
    right: 2px;
    top: 50px;
  }

  .create-carta-porte-wrap {
    position: absolute;
    right: 30px;
    top: 50px;
    font-size: 10px;
    color: white;
    font-weight: 600;
    border: 0.5px solid white;
    border-radius: 6px;
  }

  .delete-pendings-disabled,
  .delete-pendings {
    display: inline-block;
    color: #e03f3f;
    font-size: 14px;
    cursor: pointer;
  }

  .comments {
    background: transparent;
    color: #ffbb0f;
    border: none;
    position: absolute;
    right: 0;
    bottom: 0px;
    outline: none;
  }

  .comments-wrap {
    width: 100%;
    margin: 27px 0 0 0;
  }

  ${media.desktop} {
    display: none;
  }
`;

const LoadDesktop = styled.div`
  display: none;

  ${media.tablet} {
    display: none;
  }

  ${media.desktop} {
    display: inline-block;
    width: 100%;
    height: 30px;

    .load-info {
      position: relative;
    }

    p.deleted-pending-error,
    p.deleted-pending {
      position: absolute;
      right: 10px;
      color: #19c482;
      font-size: 10px;
      top: -4px;
    }

    p.deleted-pending-error {
      color: #e03f3f;
    }

    .premium-wrap {
      display: inline-block;
      vertical-align: baseline;
    }

    .premium-wrap p {
      padding: 0 10px 0 0;
    }

    p {
      display: inline-block;
      color: white;
      font-size: 12px;
      padding: 0 10px;
    }

    p.load-hour {
      padding: 0 4px 0 0;
    }

    p.load-date {
      padding-left: 0;
    }

    .load-time-wrap {
      display: inline-block;
    }

    .load-comments {
      color: #ffbb0f;
      font-size: 12px;
      margin-right: 5px;
    }

    p.to-city,
    p.from-city {
      width: 130px;
      padding: 0 0 4px 0;
      vertical-align: middle;
    }

    p.distance {
      width: 90px;
      text-align: right;
    }

    p.fare {
      color: #19c482;
      width: 117px;
      text-align: left;
      padding: 0;
    }

    .trucks-wrap {
      width: 148px;
      display: inline-block;
    }

    .trucks-wrap p {
      width: 25px;
      padding: 0 4px;
    }

    p.open,
    p.closed {
      display: inline-block;
      width: 100%;
      text-align: right;
      padding-bottom: 5px;
    }

    p.open {
      background: #29a475;
      color: lightgray;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 800;
      padding: 0px;
      width: 58px;
      text-align: center;
    }

    p.closed {
      background: #e03f3f;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 800;
      padding: 0px;
      width: 58px;
      color: lightgray;
      text-align: center;
    }

    .delete-pending-wrap {
      margin-left: 5px;
      margin-right: 5px;
    }

    .create-carta-porte-wrap {
      right: 45px;
      top: 10px;
      font-size: 15px;
      color: white;
      font-weight: 600;
      border: 0.5px solid white;
      border-radius: 6px;
    }

    .delete-pendings-disabled,
    .delete-pendings {
      display: inline-block;
      color: #e03f3f;
      font-size: 14px;
      outline: none;
      cursor: pointer;
    }

    .delete-pendings-disabled {
      color: gray;
      outline: none;
    }

    .load-type-product-wrap {
      display: inline-block;
      width: 110px;
    }

    .load-type-product-wrap .load-type,
    .load-type-product-wrap .product-type {
      display: block;
      width: 120px;
    }

    .load-type-product-wrap .load-type {
      font-size: 10px;
      color: white;
      font-weight: 600;
    }

    .doc-premium {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 600px;
      background: gray;
    }

    .doc-premium div {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .imgs-popup {
      width: 90%;
    }
  }
`;

export default { LoadWrap, LoadMobile, LoadDesktop };
