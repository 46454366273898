/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './selection-button.css';

const SelectionButton = ({
  renderList,
  idChangeAction = () => {},
  renderValueChangeAction = () => {},
  renderValueChangeActionItem = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState();
  return (
    <div className="humber-selection-button">
      <ul>
        {renderList.map(renderItem => {
          return (
            <li key={renderItem._id}>
              <label
                className={renderItem.renderValue === selectedValue ? 'option-selected' : 'option'}
                htmlFor={renderItem._id}
              >
                <input
                  className="hidden-input"
                  id={renderItem._id}
                  type="radio"
                  value={renderItem.renderValue}
                  checked={renderItem.renderValue === selectedValue}
                  onClick={() => {
                    setSelectedValue(renderItem.renderValue);
                    renderValueChangeAction(renderItem);
                    renderValueChangeActionItem(renderItem.renderValue);
                    idChangeAction(renderItem._id);
                  }}
                />
                {renderItem.renderValue}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SelectionButton.propTypes = {
  idChangeAction: PropTypes.func.isRequired,
  renderValueChangeAction: PropTypes.func.isRequired,
  renderValueChangeActionItem: PropTypes.func.isRequired,
  renderList: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default SelectionButton;
