/* eslint-disable no-underscore-dangle */
import React from 'react';
import { PropTypes, arrayOf, objectOf } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

const OrderListWrap = styled.div`
  padding: 30px;
  color: gray;
  display: flex;
  justify-content: space-around;

  h3 {
    color: #ffbb0f;
  }

  p {
    color: white;
    width: 100%;
    padding-bottom: 10px;
  }

  .gas-order {
    padding: 20px;
    border: 1px solid gray;
    border-radius: 20px;
    width: 300px;
    margin: 20px;
  }
`;

const GasOrderList = ({ historyItem }) => {
  const { shipment, truck, truckerBillingDataRazonSocial, truckerBillingDataCuit } = historyItem;
  const { gasOrders } = shipment;
  const { truckPatent, trailerPatent } = truck;

  return (
    <OrderListWrap>
      {gasOrders.map(gasOrder => {
        return (
          <>
            <span className="gas-order" key={gasOrder._id}>
              <h3>Órden emitida</h3>
              <strong>Fecha de órden</strong>
              <p>{moment(gasOrder.createdAt).format('DD-MM-YYYY HH:MM a')}</p>
              <strong>Razón social estacion de servicio</strong>
              <p>{gasOrder.nombre}</p>
              <strong>Razón social facturación</strong>
              <p>{truckerBillingDataRazonSocial}</p>
              <strong>CUIT facturación</strong>
              <p>{truckerBillingDataCuit}</p>
              <strong>Patente tractor</strong>
              <p>{truckPatent}</p>
              <strong>Patente remolque</strong>
              <p>{trailerPatent}</p>
              <strong>Litros</strong>
              <p>{gasOrder.litros}</p>
            </span>
          </>
        );
      })}
    </OrderListWrap>
  );
};

GasOrderList.propTypes = {
  historyItem: objectOf(PropTypes.object),
  shipment: objectOf(PropTypes.object),
  gasOrders: arrayOf(PropTypes.object),
};

GasOrderList.defaultProps = {
  historyItem: {},
  gasOrders: [],
  shipment: {},
};

export default GasOrderList;
