import { message } from 'antd';
import client from '../api/api';
import { getSessionUserData } from './user';

const openCPEPdf = ({ ctg }) => {
  client.endpoints.generic
    .getWaybillPdf(getSessionUserData().data.cuit, ctg)
    .then(({ data: { pdf } }) => {
      const pdfWindow = window.open('');
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          pdf
        )}'></iframe>`
      );
    })
    .catch(error => {
      message.error(error.response.data);
    });
};

export default openCPEPdf;
