import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const TruckerListWrap = styled.div`
  display: none;
  ${media.desktop} {
    position: relative;
    display: block;
    background: rgb(48, 53, 78);
    margin-top: 20px;
    border-radius: 10px;
    padding-bottom: 12px;
    cursor: auto;

    p {
      color: lightgray;
      font-size: 12px;
      width: 100px;
      display: inline-block;
    }

    ul {
      width: 99%;
      padding: 0 10px 10px 10px;
      margin: 0 auto;
      border-bottom: 1px solid #3e3e3e;
    }

    ul li {
      display: inline-block;
      list-style: none;
      color: #ffbb0f;
      font-size: 11px;
    }

    li.trucker-label {
      width: 288px;
    }

    li.truck {
      width: 297px;
    }

    li.carrier {
      width: 120px;
    }

    .loading {
      position: absolute;
      right: 8px;
      top: 6px;
    }

    .create-carta-porte-wrap {
      right: 45px;
      top: 10px;
      font-size: 15px;
      color: white;
      font-weight: 600;
      border: 0.5px solid white;
      border-radius: 6px;
      background-color: transparent;
    }
  }
`;

const Trucker = styled.div`
  display: none;

  ${media.desktop} {
    display: block;
    position: relative;
    margin: 0 auto;

    .label {
      color: lightgray;
      position: absolute;
      right: 8px;
      font-size: 9px;
      top: -13px;
    }

    p.label {
      display: block;
      font-size: 9px;
      color: gray;
      padding: 10px 0 3px 0;
    }

    .carrier-info-wrap,
    .truck-info-wrap,
    .trucker-info-wrap {
      position: relative;
      display: inline-block;
      border-bottom: 1px solid gray;
      width: 330px;
      padding: 8px 0;
      margin: 0 4px;
    }

    .trucker-info-wrap {
      width: 280px;
    }

    .trucker-info-wrap p.status {
      display: block;
      background: #e03f3f;
      color: white;
      border-radius: 1px;
      font-size: 9px;
      font-weight: 800;
      text-align: center;
    }

    .truck-info-wrap {
      width: 290px;
    }

    .carrier-info-wrap {
      width: 260px;
      padding-bottom: 12px;
    }

    .trucker-info-wrap p.name,
    .carrier-info-wrap p.billing-b-name {
      width: 100%;
      margin-left: 4px;
      color: white;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .trucker-info-wrap p.name {
      vertical-align: bottom;
    }

    .carrier-info-wrap .billing-cuit-wrap {
      width: 150px;
      line-height: 0px;
    }

    .trucker-info-wrap p.cuit {
      width: 90px;
    }

    .folder,
    .camera,
    .phone,
    .id-card {
      font-size: 14px;
      color: gray;
      margin: 0 5px;
    }

    .folder,
    .camera-ok,
    .camera {
      width: 24px;
      height: 24px;
      padding: 6px;
      cursor: pointer;
      display: inline-block;
      background: #53617b;
      color: darkgray;
      border: none;
      border-radius: 20px;
    }

    .camera-ok {
      background: #19c482;
      color: white;
      margin: 0 5px;
    }

    .images-wrap {
      position: absolute;
      right: 22px;
      bottom: 0px;
      border-bottom: 1px solid gray;
      padding-bottom: 12px;
    }

    .images-wrap .folder {
      background: #09f;
      color: white;
    }

    .trucker-info-wrap p.cel {
      width: 80px;
    }

    .truck-info-wrap p.patent {
      border: 1px solid #d3d3d3;
      border-top: 5px solid #09f;
      border-radius: 3px;
      text-align: center;
      width: 75px;
      display: inline-block;
      margin: 0 3px;
    }

    .truck-info-wrap p.truck {
      width: 120px;
      padding-left: 5px;
    }

    .bottom-icons-wrap {
      display: inline-block;
      margin-left: 12px;
    }

    .doc-premium-wrap {
      display: inline-block;
      text-align: center;
      width: 50%;
    }

    .cam-icon,
    .doc-premium-icon {
      color: gray;
      font-size: 20px;
      width: 35px;
    }
  }
`;

const ReportPicsPopUp = styled.div`
  display: block;

  ${media.desktop} {
    z-index: 1000;
    position: absolute;
    top: -11pc;
    width: 100%;
    height: 807px;
    background: #20293e;
    text-align: center;
    overflow-y: scroll;

    img {
      width: 95%;
      border-radius: 12px;
      margin-top: 67px;
    }

    h3 {
      position: absolute;
      top: 11px;
      color: #ffbb0f;
      padding: 0 30px 8px 3px;
      margin-left: 26px;
    }

    .close-btn {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 40px;
      height: 40px;
      padding: 6px;
      color: #ffbb0f;
      border: none;
      border-radius: 20px;
    }
  }
`;

export default { TruckerListWrap, Trucker, ReportPicsPopUp };
