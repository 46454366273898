/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import CSS from './terms-styles';
import client from '../../api/api';

const SettlementAdvanceTerms = () => {
  const [termsTemplate, setTermsTemplate] = useState('');
  const [showError, setShowError] = useState(false);

  const getTerms = async () => {
    try {
      const res = await client.endpoints.generic.getTermsAdvance();
      if (res.status === 200) {
        setTermsTemplate(res.data);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <CSS.TermsWrap>
      <CSS.HumberLogo>
        <img src="../../../images/icons/humber-logo.png" alt="humber-logo" />
      </CSS.HumberLogo>
      <div className="sign-up-terms">
        <div>
          {showError ? (
            <p style={{ animation: `fadeIn 5s` }}>Hubo un error al cargar los términos.</p>
          ) : (
            ''
          )}
        </div>
        {termsTemplate ? <div dangerouslySetInnerHTML={{ __html: termsTemplate }} /> : ''}
      </div>
    </CSS.TermsWrap>
  );
};

export default SettlementAdvanceTerms;
