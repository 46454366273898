import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import compareVersions from 'compare-versions';
import client from '../../api/api';
import { saveLastWebVersion, getLastWebVersion } from '../../libs/lastWebVersion';
import { showToast, closeToast } from '../../libs/toast/warningToast';
import { saveSessionUserData, getSessionUserData } from '../../libs/user';

const useToast = () => {
  const [isOutdated, setIsOutdated] = useState(false);
  const [lastVersion, setLastVersion] = useState(getLastWebVersion());
  const location = useLocation();

  const checkLastWebVersion = async () => {
    const { data: response } = await client.endpoints.generic.getLastWebVersion();
    if (response.tcPending) {
      const sessionData = getSessionUserData();
      sessionData.data.tcPending = true;
      saveSessionUserData(sessionData);
    }
    setLastVersion(response.lastWebVersion);
    let lastWebVersionStorage = getLastWebVersion();
    if (!lastWebVersionStorage) {
      saveLastWebVersion(response.lastWebVersion);
      lastWebVersionStorage = getLastWebVersion();
    }

    // eslint-disable-next-line no-use-before-define
    setIsOutdated(isMinorVersion(lastWebVersionStorage, response.lastWebVersion));
  };

  useEffect(() => {
    checkLastWebVersion();
  }, [location]);

  const RefreshButton = styled.a`
    background: #ffbb0f;
    font-size: 14px;
    margin: 10px;
    width: 20em;
    text-align: center;
    border-radius: 20px;
    padding: 10px;
  `;

  const RefreshPageMsg = ({ closeToast: closeToastAction }) => (
    <>
      <h1>Hay una nueva versión de la web</h1>
      <h2>
        Recordá que si no estamos como transportistas en la carta de porte te vamos a pedir la foto
        de fin de viaje
      </h2>
      <RefreshButton
        type="button"
        onClick={() => {
          saveLastWebVersion(lastVersion);
          closeToastAction();
          window.location.reload(true);
        }}
      >
        Actualizar
      </RefreshButton>
    </>
  );

  RefreshPageMsg.propTypes = {
    closeToast: PropTypes.func.isRequired,
  };

  useEffect(() => {
    if (isOutdated) {
      showToast({
        type: 'warning',
        component: RefreshPageMsg,
        options: {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          draggable: false,
          closeButton: false,
          toastId: 'outdatedVersionToast',
        },
      });
    } else {
      closeToast({ toastId: 'outdatedVersionToast' });
    }
  }, [isOutdated]);
};

export default useToast;

/**
 * Checks if the version passed as first parameter is minor than the version passed as second parameter.
 * i.e.: isMinorVersion('2.8.8', '2.8.9') returns true
 * @param {String} localVersion
 * @param {String} lastVersion
 */
function isMinorVersion(localVersion, lastVersion) {
  return compareVersions(lastVersion, localVersion);
}
