import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import HumberInput from '../Global';
import Logo from '../LandingPage/humber-logo.png';
import client from '../../api/api';
import './password-reset.css';

const PasswordReset = () => {
  const [newPass, setNewPass] = useState('');
  const [newPassConfirm, setNewPassConfirm] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { id, token } = useParams();
  const history = useHistory();

  function validatePassword() {
    if (newPass !== newPassConfirm) {
      setErrorMsg('Las contraseñas deben coincidir');
      return false;
    }
    return true;
  }

  async function onSubmit(event) {
    event.preventDefault();
    if (!validatePassword()) return;
    try {
      const newPassFormData = new FormData();
      newPassFormData.append('newPassword', newPass);
      await client.endpoints.generic.resetPassword({
        id,
        token,
        newPassFormData,
      });
      setSuccessMsg('Contraseña cambiada correctamente. Serás redirigido automáticamente');
      setTimeout(() => {
        history.push({
          pathname: '/',
        });
      }, 3000);
    } catch (error) {
      const msg = error.response.data.message || 'Hubo un error. Comunicate con nosotros';
      setErrorMsg(msg);
    }
  }

  return (
    <div className="password-reset-wrapper">
      <div className="logo">
        <a href="/">
          <img className="logo-img" src={Logo} alt="humber logo" />
        </a>
      </div>
      <form onSubmit={onSubmit}>
        <HumberInput
          id="newPassword"
          label="Nueva Contraseña"
          value={newPass}
          onChange={setNewPass}
          errorMsg={errorMsg}
          type="password"
        />
        <HumberInput
          id="newPassword"
          label="Confirmar Contraseña"
          value={newPassConfirm}
          onChange={setNewPassConfirm}
          type="password"
        />
        {successMsg && (
          <div className="title">
            <p>{successMsg}</p>
          </div>
        )}
        <input type="submit" value="Aceptar" />
      </form>
    </div>
  );
};

export default PasswordReset;
