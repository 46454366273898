/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable react/jsx-props-no-spreading

import React, { useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { useTable, useFilters, useSortBy, usePagination, useExpanded } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';
import _ from 'lodash';
import './carrier-record-table.css';

// eslint-disable-next-line react/prop-types
function defaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
}

const CarrierRecordTable = ({
  columns,
  data,
  fetchData,
  pageCount: controlledPageCount,
  renderRowSubComponent,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: defaultColumnFilter,
    }),
    []
  );

  const [debounceCallback] = useDebouncedCallback(filters => fetchData(filters), 1500, {
    maxWait: 2000,
  });

  // const tableState = useTableState({ pageIndex: 0 });

  // const [{ sortBy, filters, pageIndex, pageSize }] = tableState;

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: { filters, pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualFilters: true,
      manualPagination: true,
      pageCount: controlledPageCount,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // de esta forma me aseguro que no se ejecuten requests en el mount del component
    if (Number.isInteger(pageCount)) {
      if (!_.isEmpty(filters)) {
        debounceCallback({
          ...{ filters },
          ...{ sortBy },
          ...{ pageSize },
          ...{ pageIndex },
        });
      } else {
        fetchData({
          ...{ filters },
          ...{ sortBy },
          ...{ pageSize },
          ...{ pageIndex },
        });
      }
    }
  }, [pageIndex, pageSize, filters, fetchData, debounceCallback]);

  return (
    <div className="carrier-record-table-wrapper">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map(column => {
                return (
                  <>
                    <th {...column.getHeaderProps()} key={column.id}>
                      <div className="hedear" {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                      </div>
                      <div className="filter-input">
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  </>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="tableBody">
          {page.map(row => {
            return (
              prepareRow(row) || (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                          className={
                            row.status === 'cancelada'
                              ? 'cancelled-shipment'
                              : ''
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {renderRowSubComponent && row.isExpanded ? (
                    <tr>
                      <td colSpan={allColumns.length}>{renderRowSubComponent({ row })}</td>
                    </tr>
                  ) : null}
                </>
              )
            );
          })}
        </tbody>
      </table>
      <div className="pagination-wrapper">
        <div className="page-number">
          <p>Ir a página</p>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            min={0}
            onChange={e => {
              const p = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(p);
            }}
          />
        </div>
        <div className="page-counter">
          <p>
            {pageIndex + 1} / {pageOptions.length}
          </p>
        </div>
        <div className="pagination-btn-wrapper">
          <div className="pagination-button">
            <span role="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} color="#ffbb0f" />
            </span>{' '}
          </div>
          <div className="pagination-button">
            <span role="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <FontAwesomeIcon icon={faAngleLeft} color="#ffbb0f" />{' '}
            </span>
          </div>
          <div className="pagination-button">
            <span role="button" onClick={() => nextPage()} disabled={!canNextPage}>
              <FontAwesomeIcon icon={faAngleRight} color="#ffbb0f" />{' '}
            </span>
          </div>
          <div className="pagination-button">
            <span role="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <FontAwesomeIcon icon={faAngleDoubleRight} color="#ffbb0f" />{' '}
            </span>
          </div>
          <select
            className="pagination-rows-btn"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pSize => (
              <>
                <option key={pSize} value={pSize}>
                  Filas {pSize}
                </option>
              </>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default CarrierRecordTable;
