import React from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startIcon from '../../../../img/StartIcon';
import endIcon from '../../../../img/EndIcon';

const MapWrap = styled.span`
  .leaflet-container .leaflet-marker-icon {
    margin-left: -12px !important;
    margin-top: -40px !important;
  }

  .active-load-start-pin {
    font-size: 30px;
    color: #b64dea;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }

  .active-load-end-pin {
    font-size: 30px;
    color: #000;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }
`;

const LoadsMap = ({ loadSelected }) => {
  const defaultPosition = [-34.603722, -58.381592];
  return (
    <MapWrap>
      <Map
        center={loadSelected ? loadSelected.fromGeoCode : defaultPosition}
        scrollWheelZoom={false}
        zoom={6}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {loadSelected ? (
          <>
            <Marker position={loadSelected.fromGeoCode} icon={startIcon} />
            <Marker position={loadSelected.toGeoCode} icon={endIcon} />
          </>
        ) : (
          ''
        )}
      </Map>
    </MapWrap>
  );
};

LoadsMap.propTypes = {
  loadSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default LoadsMap;
