import { Tbody, Td, Th, Thead, Tr, Table, VStack, Text, useToast, IconButton, Spinner } from '@chakra-ui/react';
import { useSortBy, useTable } from 'react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import toCurrency from '../../../libs/currencyUtils';
import client from '../../../api/api';

const CheckingAccountTable = ({
    data }) => {

    const toast = useToast();
    const downloadTostRef = useRef();

    const downloadDocument = (record) => {
        downloadTostRef.current = toast({
            description: 'El comprobante se esta descargando. Aguarde unos instantes.',
            status: 'info',
            position: 'bottom',
            variant: 'solid'
        });
        client.endpoints.carrier.getDocumentToDownload(record.documentInformation.document, record.documentInformation.impresionName)
            .then(response => {
                const file = new Blob([response.data], { type: "application/pdf" });

                const downloadUrl = window.URL.createObjectURL(file);

                const link = document.createElement("a");

                link.href = downloadUrl;

                link.setAttribute("download", record.documentInformation.description1);

                document.body.appendChild(link);

                link.click();

                link.remove();

            }).catch(error => {
                toast({
                    description: 'Ocurrió un error abrir el documento',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top',
                    variant: 'solid'
                });
            }).finally(() => {
                if (downloadTostRef.current) {
                    toast.close(downloadTostRef.current);
                }
            })
    }

    const columns = useMemo(() => [
        {
            Header: 'Fecha',
            accessor: (original, index) => {
                return new Date(original.date).toLocaleDateString();
            },
            minWidth: 150
        },
        {
            Header: 'Movimiento',
            width: 400,
            accessor: (original, index) => {
                if (original.documentInformation) {
                    return (
                        <VStack alignItems={'start'} width={'100%'}>
                            <Text as='strong'>{original.documentInformation.description1}</Text>
                            <Text>{original.documentInformation.description2}</Text>
                        </VStack>
                    )
                }
            }
        },
        {
            Header: 'Importe',
            accessor: (original, index) => {
                return (
                    <Text textAlign={'end'}>
                        {toCurrency(original.amount)}
                    </Text>
                )
            }

        },
        {
            Header: 'Comprobante',
            accesor: 'file',
            id: 'file',
            width: 150,
            accessor: (original, index) => {
                return original && original.documentInformation.document ? (
                    <IconButton icon={
                        <DownloadIcon onClick={() => { downloadDocument(original); }} w={6} h={6} ></DownloadIcon >
                    } />

                ) : ('');
            }
        }
    ], []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data });
    return (
        <>
            <Table {...getTableProps()} fontSize={'1xl'} mt={'4'}>
                <Thead >
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th fontSize={'1xl'}
                                >
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.length === 0 &&
                        'No se encontraron datos'
                    }
                    {rows && rows.map((row) => {
                        prepareRow(row)
                        return (

                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric} width={cell.column.width}>
                                        {cell.render('Cell')}
                                    </Td>
                                ))}

                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </>
    )
}

export default React.memo(CheckingAccountTable, (prev, post) => {
    return prev.data === post.data;
});