const allProvinces = [
  {
    id: '6',
    value: 'Buenos Aires',
  },
  {
    id: '10',
    value: 'Catamarca',
  },
  {
    id: '22',
    value: 'Chaco',
  },
  {
    id: '26',
    value: 'Chubut',
  },
  {
    id: '2',
    value: 'Ciudad Autonoma De Bs As',
  },
  {
    id: '14',
    value: 'Cordoba',
  },
  {
    id: '18',
    value: 'Corrientes',
  },
  {
    id: '30',
    value: 'Entre Rios',
  },
  {
    id: '34',
    value: 'Formosa',
  },
  {
    id: '38',
    value: 'Jujuy',
  },
  {
    id: '42',
    value: 'La Pampa',
  },
  {
    id: '46',
    value: 'La Rioja',
  },
  {
    id: '50',
    value: 'Mendoza',
  },
  {
    id: '54',
    value: 'Misiones',
  },
  {
    id: '58',
    value: 'Neuquen',
  },
  {
    id: '62',
    value: 'Rio Negro',
  },
  {
    id: '66',
    value: 'Salta',
  },
  {
    id: '70',
    value: 'San Juan',
  },
  {
    id: '74',
    value: 'San Luis',
  },
  {
    id: '78',
    value: 'Santa Cruz',
  },
  {
    id: '82',
    value: 'Santa Fe',
  },
  {
    id: '86',
    value: 'Santiago Del Estero',
  },
  {
    id: '94',
    value: 'Tierra Del Fuego',
  },
  {
    id: '90',
    value: 'Tucuman',
  },
];

export default allProvinces;
