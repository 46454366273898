/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LoadWrap = styled.div`
  p {
    color: white;
  }

  p.conditions {
    font-size: 12px;
    color: lightgray;
  }

  h4 {
    color: #ffbb0f;
    border-bottom: 1px solid gray;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 400;
  }
`;

const LoadDetail = ({ loadSelected }) => {
  const conditions = loadSelected.conditions ? loadSelected.conditions : [];
  return (
    <LoadWrap style={{ animation: `fadeIn 2s` }}>
      <h4>Comentarios</h4>
      <p>
        {loadSelected.publicComments
          ? loadSelected.publicComments
          : '- El dador de carga no ingresó detalles'}
      </p>
      <h4>Condiciones</h4>
      {conditions.map(cond => (
        <p key={cond.id} className="conditions">
          - {cond.condition}
        </p>
      ))}
      <h4>Sucursal</h4>
      {loadSelected.branchOffice && (
        <p className="conditions">
          - {loadSelected.branchOffice.name} - Cel: {loadSelected.branchOffice.phoneNumber}
        </p>
      )}
    </LoadWrap>
  );
};

LoadDetail.propTypes = {
  loadSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default LoadDetail;
