import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Alert,
  AlertIcon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  List,
  ListItem,
  ListIcon,
  Box,
  Image,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import actionImg from './actions.png';
import permisosImg from './permisos.png';

const AlertWrap = styled.div`
  background: #bee3f8;

  p {
    padding: 0 10px;
    color: #003c75;
  }
`;

const Alerts = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const textStyles = {
    padding: '20px 0',
  };

  const modalBodyStyles = {
    padding: '0 40px',
  };

  const imgStyles = {
    width: '100%',
    margin: '20px 0',
  };

  return (
    <AlertWrap>
      <Alert status="info">
        <AlertIcon style={{ color: '#3182ce' }} />
        <p>
          Mejoramos la plataforma para que a partir de ahora puedas administrar los permisos de tus
          camioneros..
        </p>
        <Button colorScheme="blue" onClick={openModal}>
          Permisos
        </Button>
      </Alert>
      <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Gestión de permisos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box sx={modalBodyStyles}>
              <Box sx={textStyles}>
                <p>Ahora podes elegir qué pueden ver o hacer los camioneros de tu flota</p>
              </Box>

              <List spacing={3}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Ver la facturación y las liquidaciones .{' '}
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Tomar cargas.
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Ver las tarifas de las cargas.
                </ListItem>
              </List>
              <Box sx={textStyles}>
                <p>
                  Es importante que definas los permisos de cada camionero para poder tener
                  prioridad de carga.
                </p>
              </Box>
              <Box sx={textStyles}>
                <p>1 - Buscá los candados en "Mi flota"</p>
              </Box>
            </Box>
            <Box sx={modalBodyStyles}>
              <Box sx={imgStyles}>
                <Image src={actionImg} alt="actions" />
              </Box>
              <Box sx={textStyles}>
                <p>2 - Elegí los permisos que le das a cada camionero</p>
              </Box>
              <Box boxSize="sm">
                <Image src={permisosImg} alt="permisos" />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeModal}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AlertWrap>
  );
};

export default Alerts;
