import Resizer from 'react-image-file-resizer';

const resizeImage = async ({
  file, //is the file of the new image that can now be uploaded...
  maxWidth = 1500, // is the maxWidth of the  new image
  maxHeight = 2800, // is the maxHeight of the  new image
  quality = 100, // is the quality of the  new image
  rotation = 0, // is the rotatoion of the  new image
  outputType = 'blob',
}) => {
  const promise = new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = function() {
      // file is loaded
      const img = new Image();

      img.onload = function() {
        if (img.width > maxWidth || img.height > maxHeight) {
          const compressFormat =
            file.type && file.type.includes('/') ? file.type.split('/')[1].toUpperCase() : 'JPEG';
          return Resizer.imageFileResizer(
            file,
            maxWidth,
            maxHeight,
            compressFormat,
            quality,
            rotation,
            uri => resolve(new File([uri], file.name)),
            outputType
          );
        } else {
          resolve(file);
        }
      };

      img.src = fr.result; // is the data URL because called with readAsDataURL
    };
    try {
      fr.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
};

export default resizeImage;
