/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { PropTypes, object } from 'prop-types';

const InputProviderWrap = styled.div`
  label {
    width: 100%;
    font-weight: 600;
    color: gray;
  }

  input {
    background: transparent;
    border: none;
    height: 45px;
    font-size: 16px;
    color: white;
    width: 100%;
    border-bottom: 1px solid #787878;
  }

  input:focus {
    outline: none;
  }
`;

const InputProvider = ({ label, value, setValue, id, onBlurValidation, type, disabled }) => {
  const handleChange = (e, key) => {
    e.preventDefault();
    if (key === 'cuit' && e.currentTarget.value.length < 12) {
      setValue(e.target.value);
    }

    if (key === 'cbu' && e.currentTarget.value.length < 23) {
      setValue(e.target.value);
    }
  };

  return (
    <InputProviderWrap>
      {id === 'cuit' ? (
        <label htmlFor={id}>
          {label}
          <input
            id={id}
            type="text"
            value={value}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onBlur={() => onBlurValidation(value)}
            onChange={e => {
              handleChange(e, id);
            }}
            disabled={disabled}
          />
        </label>
      ) : (
        ''
      )}
      {id === 'cbu' ? (
        <label htmlFor={id}>
          {label}
          <input
            id={id}
            type="text"
            value={value}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={e => {
              handleChange(e, id);
            }}
            disabled={disabled}
          />
        </label>
      ) : (
        ''
      )}
      {id !== 'cbu' && id !== 'cuit' ? (
        <label htmlFor={id}>
          {label}
          <input
            id={id}
            type={type}
            defaultValue=""
            onChange={e => {
              setValue(e.target.value);
            }}
            disabled={disabled}
          />
        </label>
      ) : (
        ''
      )}
    </InputProviderWrap>
  );
};

InputProvider.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.bool,
  value: PropTypes.objectOf(object).isRequired,
  setValue: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  onBlurValidation: PropTypes.func.isRequired,
};

InputProvider.defaultProps = {
  setValue: () => {},
  id: false,
};

export default InputProvider;
