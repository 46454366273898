/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { Map, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCamera, faSearch } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';
import Async from 'react-select/async';
import moment from 'moment';
import CSS from './add-load-styles';
import { getSessionUserData } from '../../../libs/user';
import HumberInput from '../../Global';
import HumberDropdown from './components/humber-dropdown';
import SelectionButton from './components/SelectionButton';
import startIcon from '../../../img/StartIcon';
import endIcon from '../../../img/EndIcon';
import client from '../../../api/api';
import Loading from '../../../libs/loading/index';
import OptionsRedirect from '../../OptionsRedirect/OptionsRedirect';
import CommonDestinationsModal from '../ActiveLoadsView/components/CommonDestinationsModal';
import AddLoadForm from '../../Kyc/AddLoadForm';
import config from '../../../api/config';
import VirreyesAddLoadForm from "../../Virreyes/AddLoadForm";

const loadOriginOption = [
  { value: 'Cosecha', id: 'Cosecha' },
  { value: 'Bolson', id: 'Bolson' },
  { value: 'Silos', id: 'Silos' },
  { value: 'Otros', id: 'Otros' },
];

const publicationTimeArr = [
  { value: 'por 2 Hs', id: 2 },
  { value: 'por 4 Hs', id: 4 },
  { value: '8', id: 8 },
];

const TODAY_STRING = '(Hoy)';
const ALL_FIELD_COMPLETED_ERR_MSG = 'Todos los campos con * deben estar completos';

const AddLoadView = props => {
  const { closedLoadEnabled, cuit, _id } = getSessionUserData().data;

  const mapState = { lat: -36.0, lng: -64.0 };
  const initialMarker = L.latLng(-38.0, -57.55);
  const destinationMarker = L.latLng(-31.53, -68.53);
  const initialbounds = L.latLngBounds(initialMarker, destinationMarker);
  const initialPosition = [mapState.lat, mapState.lng];
  const bounds = initialbounds;
  const [commonDestionationsModalOpen, toggleCommonDestinationsModal] = useState(false);
  const [destinationOptionsValue, setDestinationOptionsValue] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setERRORMsg] = useState('');
  const [stepOne, setStepOne] = useState(true);
  const [position, setPosition] = useState(initialPosition);
  const [renderFromMarker, setRenderFromMarker] = useState(false);
  const [fromMarkerPosition, setFromMarkerPosition] = useState('');
  const [renderToMarker, setRenderToMarker] = useState(false);
  const [toMarkerPosition, setToMarkerPosition] = useState('');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [fromId, setFromId] = useState('');
  const [plusKmFrom, setPlusKmFrom] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [toId, setToId] = useState('');
  const [plusKmTo, setPlusKmTo] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [distance, setDistance] = useState(0);
  const [loadTypes, setLoadTypes] = useState('');
  const [loadTypeSelected, setLoadTypeSelected] = useState('');
  const [productTypes, setProductTypes] = useState('');
  const [productTypeSelected, setProductTypeSelected] = useState('');
  const [units, setUnits] = useState('');
  const [loadOrigin, setLoadOrigin] = useState('');
  const [showClosedLoadForm, setShowClosedLoadForm] = useState(false);
  const [trucksRequested, setTrucksRequested] = useState('');
  const [productQuantity, setProductQuantity] = useState('');
  const [unitSelected, setUnitSelected] = useState('');
  const [loadDate, setLoadDate] = useState('');
  const [hour, setHour] = useState('');
  const [publicComment, setPublicComment] = useState('');
  const [privateComment, setPrivateComment] = useState('');
  const [flatImage, setFlatImage] = useState('');
  const [flatImageUri, setFlatImageUri] = useState('');
  const [publicationTime, setPublicationTime] = useState('');
  const [loadStatus, setLoadStatus] = useState('open');
  const [filterTrucker, setFilterTrucker] = useState('');
  const [truckerSelectedList, setTruckerSelectedList] = useState([]);
  const [trucker, setTrucker] = useState('');
  const [manualFare, setManualFare] = useState(0);
  const [fare, setFare] = useState('');
  const [fareType, setFareType] = useState('');
  const [shipmentInsurance, setShipmentInsurance] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const [avtiveLoadsRedirect, setActiveLoadsRedirect] = useState(false);

  const { RIZOBACTER_CUITS, VIRREYES_CUITS } = config;
  const [isRizobacterPoint, setIsRizobacterPoint] = useState(false);
  const [isVireyesPoint, setIsVireyesPoint] = useState(false);
  const [user, setUser] = useState(null);

  const {
    endpoints: { shipper, generic },
  } = client;

  useEffect(() => {
    if (cuit) {
      setIsRizobacterPoint(RIZOBACTER_CUITS.includes(cuit));
    }
  }, [cuit]);


  async function getShipperById(_id) {
    setIsLoading(true);
    const { data } = await shipper.getShipperById(_id);
    if (data?.user?.cuit === VIRREYES_CUITS) {
      setIsVireyesPoint(true);
      data.user.zone = data.zone;
      setUser(data?.user);
    }
    setIsLoading(false);
  }

  async function getLoadTypes() {
    const res = await shipper.getProductTypes();
    setLoadTypes(res.data);
  }

  const originOptions = async inputValue => {
    if (inputValue) {
      try {
        const { data: places } = await shipper.placesAutoComplete(inputValue);
        const { data: delegate } = await generic.afipDelegate(cuit);
        if (delegate) {
          const { data: afipPlaces } = await shipper.afipCitiesByProducer(cuit, inputValue);
          return [
            ...afipPlaces.map(ap => ({
              label: `${ap.name}, ${ap.province.name}`,
              value: ap.code,
            })),
            ...places,
          ];
        }
        return places;
      } catch (e) {
        console.error(e);
      }
    }
    return null;
  };

  const destinationOptions = async inputValue => {
    if (inputValue) {
      try {
        const { data } = await shipper.placesAutoComplete(inputValue);
        if (await generic.afipDelegate(cuit)) {
          const { data: commonDestinations } = await generic.waybillCommonDestinations();
          return [
            ...data,
            ...commonDestinations.map(cd => ({
              label: `${cd.addressee} - ${cd.cityName}, ${cd.provinceName}`,
              value: cd.plantCode,
            })),
          ];
        }
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  };

  const getGeocode = async (city, flag) => {
    if (city) {
      try {
        const res = await shipper.getMarkerGeocode(city);
        if (flag === 'origen') {
          setFromMarkerPosition([
            res.data.results[0].geometry.location.lat,
            res.data.results[0].geometry.location.lng,
          ]);
        }
        if (flag === 'destino') {
          setToMarkerPosition([
            res.data.results[0].geometry.location.lat,
            res.data.results[0].geometry.location.lng,
          ]);
        }
      } catch (error) {
        setERRORMsg('Error al buscar la ciudad, intentá de nuevo mas tarde.');
      }
    }
  };

  const getDistance = async () => {
    try {
      const res = await shipper.getCityDistances(fromId, toId);
      const distanceOriginal = Number.parseInt(res.data, 10);
      const distancePlusFrom = Number.parseInt(plusKmFrom, 10)
        ? Number.parseInt(plusKmFrom, 10)
        : 0;
      const distancePlusTo = Number.parseInt(plusKmTo, 10) ? Number.parseInt(plusKmTo, 10) : 0;
      const dist = distanceOriginal + distancePlusFrom + distancePlusTo;
      setDistance(Number.parseInt(dist, 10));
    } catch (error) {
      console.error(error);
    }
  };

  // Array de fechas para mostrar al usuario
  const buildDateArr = days => {
    const dateArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; days >= i; i++) {
      if (i === 0) {
        dateArr.push(
          `${moment()
            .locale('es')
            .format('DD-MMM-YYYY')} ${TODAY_STRING}`
        );
      } else {
        dateArr.push(
          moment(new Date())
            .add(i, 'days')
            .locale('es')
            .format('DD-MMM-YYYY')
        );
      }
    }
    return dateArr;
  };

  const uploadFlatImage = file => {
    const newFile = new File([file], `imgPlano.jpeg`, {
      type: 'image/jpeg',
    });
    setFlatImage(newFile);
  };

  const handleRemoveItem = e => {
    setTruckerSelectedList(truckerSelectedList.filter(item => item.truckerName !== e.truckerName));
  };

  const addTrucker = t => {
    if (!truckerSelectedList.some(i => i._id === t._id)) {
      setTruckerSelectedList([...truckerSelectedList, t]);
    } else {
      setERRORMsg('El camionero ya fue agregado.');
    }
  };

  const checkTruckerInBlackList = async truckerId => {
    const res = await shipper.searchTruckerInBlackList(truckerId);

    if (res.data) {
      throw new Error('truckerInBlackList');
    }
  };

  const searchTrucker = async truckerCuit => {
    try {
      if (truckerSelectedList.filter(item => item.cuit !== truckerCuit)) {
        const res = await shipper.searchTruckerByCuit(truckerCuit);

        const { _id, datosFacturacion, truckId } = res.data;

        if (!_id) {
          throw new Error('truckerNotExists');
        }

        await checkTruckerInBlackList(_id);

        if (!datosFacturacion) {
          throw new Error('truckerNoBillingData');
        }

        if (!truckId) {
          throw new Error('truckerNoTruck');
        }

        setTrucker(res.data);
      } else {
        setERRORMsg('El cuit ingresado ya se encuentra agregado en la lista de seleccionados.');
      }
    } catch (error) {
      if (error.response) {
        setERRORMsg(error.response.data.message);
      }
      if (error.message === 'truckerNotExists') {
        setERRORMsg(`El camionero con CUIT ${truckerCuit} no existe.`);
      }
      if (error.message === 'truckerInBlackList') {
        setERRORMsg('El camionero se encuentra en la lista negra de Humber.');
      }
      if (error.message === 'truckerNoBillingData') {
        setERRORMsg('El camionero no tiene datos de facturación registrados en Humber.');
      }
      if (error.message === 'truckerNoTruck') {
        setERRORMsg('El camionero no tiene camión registrados en Humber.');
      }
      if (!error.message && !error.response) {
        setERRORMsg('Error al buscar camionero.');
      }
    }
  };

  const upload = async () => {
    const loadDateAndTime = moment(
      `${loadDate.replace(TODAY_STRING)} ${hour}`,
      'DD-MMM-YYYY HH:mm'
    ).toISOString();
    try {
      const formData = new FormData();
      formData.append('productQuantity', Number.parseInt(productQuantity, 10));
      formData.append('trucksRequested', trucksRequested);
      formData.append('loadDate', loadDateAndTime);
      formData.append('fromCity', fromCity);
      formData.append('toCity', toCity);
      formData.append('fromAddress', fromAddress);
      if (fromId && Number(fromId)) formData.append('fromAfipCityCode', Number(fromId));
      formData.append('toAddress', toAddress);
      if (toId && Number(toId)) formData.append('destinationPlantCode', Number(toId));
      formData.append('fromGeoCodeLat', fromMarkerPosition[0]);
      formData.append('fromGeoCodeLong', fromMarkerPosition[1]);
      formData.append('toGeoCodeLat', toMarkerPosition[0]);
      formData.append('toGeoCodeLong', toMarkerPosition[1]);
      formData.append('fare', manualFare);
      formData.append('fareType', fareType);
      formData.append('distance', `${distance.toString()} Km`);
      formData.append('shipmentInsurance', shipmentInsurance);
      formData.append('insuranceFare', '130000');
      formData.append('publicComment', publicComment);
      formData.append('privateComment', privateComment);
      formData.append('publicationTime', publicationTime);
      formData.append('productId', productTypeSelected._id);
      formData.append('unitId', unitSelected._id);
      formData.append('hour', loadDateAndTime);
      formData.append('adjustmentPercentage', 0);
      formData.append('insuranceCategory', 4);
      formData.append('loadOrigin', loadOrigin);
      formData.append('flatImage', flatImage);
      formData.append('notify', true);
      formData.append('status', loadStatus);
      if (loadStatus === 'close') {
        formData.append('truckers', truckerSelectedList.map(t => t._id));
      }
      const load = { formData };
      const res = await shipper.addLoad(load);
      if (res.status === 200) {
        setStepOne(false);
        setIsLoading(false);
        setUploadSuccess(true);
      }
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setERRORMsg(error.response.data.message);
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (resetFields) {
      setRenderFromMarker(false);
      setFromMarkerPosition('');
      setFromCity('');
      setFromId('');
      setRenderToMarker(false);
      setToMarkerPosition('');
      setToCity('');
      setToId('');
      setDistance(0);
      setPosition(initialPosition);
      setFare('');
      setFareType('');
      setPlusKmFrom('');
      setPlusKmTo('');
      setLoadTypeSelected('');
      setLoadDate('');
      setProductTypeSelected('');
      setFlatImage('');
      setFlatImageUri('');
      setFromAddress('');
      setToAddress('');
      setPrivateComment('');
      setPublicComment('');
      setLoadOrigin('');
      setTrucksRequested('');
      setHour('');
      setProductQuantity('');
      setPublicationTime('');
      setTruckerSelectedList([]);
      setShowClosedLoadForm(false);
      setTrucker('');
      setFilterTrucker('');
    }
    setResetFields(false);
  }, [resetFields]);

  const stageChangeUpload = () => {
    const now = moment(new Date());
    if (loadDate && hour) {
      const loadDateAndTime = moment(
        `${loadDate.replace(TODAY_STRING)} ${hour}`,
        'DD-MMM-YYYY HH:mm'
      );
      if (
        loadTypeSelected !== '' &&
        loadDate !== '' &&
        publicationTime !== '' &&
        trucksRequested !== '0' &&
        trucksRequested !== '' &&
        productQuantity !== '0' &&
        productQuantity !== '' &&
        productTypeSelected !== '' &&
        unitSelected !== '' &&
        publicComment !== '' &&
        privateComment !== '' &&
        loadDateAndTime.isAfter(now)
      ) {
        setERRORMsg('');
        setIsLoading(true);
        upload();
      } else {
        setERRORMsg(
          loadDateAndTime.isBefore(now)
            ? 'La hora tiene que ser mayor a la actual'
            : ALL_FIELD_COMPLETED_ERR_MSG
        );
        setStepOne(false);
      }
    } else {
      setERRORMsg(ALL_FIELD_COMPLETED_ERR_MSG);
      setStepOne(false);
    }
  };

  const unlessOneTruckerListed = () => {
    if (truckerSelectedList.length === 0) {
      setERRORMsg('Debe agregar por lo menos un camionero a la lista para continuar');
    }
  };

  useEffect(() => {
    if (flatImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFlatImageUri(reader.result);
      };
      reader.onerror = () => {
        console.error(reader.error);
      };
      reader.readAsDataURL(flatImage);
    }
  }, [flatImage, flatImageUri]);

  useEffect(() => {
    getLoadTypes();
    getShipperById(_id);
  }, []);

  async function setFareData(dist) {
    try {
      const { data: newFare } = await generic.getFare(dist, loadTypeSelected.id);
      setFare(parseInt(newFare, 10));
      setManualFare(newFare.toFixed(2));
    } catch (err) {
      setERRORMsg('Error obteniendo la tarifa. Volvé a intentar en unos minutos');
    }
    if (loadTypeSelected.tipoCarga === 'Granel') {
      setFareType('TN');
      setShipmentInsurance(true);
    } else {
      setFareType('viaje');
      setShipmentInsurance(false);
    }
    setProductTypes(loadTypeSelected.tipoProducto);
    setProductTypeSelected('');
    setUnits(productTypeSelected.unidad);
    setUnitSelected('');
  }

  useEffect(() => {
    if (loadTypeSelected) {
      setFareData(distance);
    }
  }, [loadTypeSelected, productTypes]);

  useEffect(() => {
    if (errorMsg) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorMsg]);

  useEffect(() => {
    if (errorMsg || stepOne || uploadSuccess) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorMsg, stepOne, uploadSuccess]);

  useEffect(() => {
    setERRORMsg('');
  }, [trucker, truckerSelectedList]);

  useEffect(() => {
    if (loadStatus === 'close') {
      setPublicationTime(0);
    } else {
      setPublicationTime('');
    }
    if (truckerSelectedList.length > 0) setTrucksRequested(truckerSelectedList.length);
  }, [loadStatus, truckerSelectedList]);

  useEffect(() => {
    if (fromMarkerPosition) {
      setRenderFromMarker(true);
      setPosition(fromMarkerPosition);
    }
    if (toMarkerPosition) {
      setRenderToMarker(true);
      setPosition(toMarkerPosition);
    }
  }, [fromMarkerPosition, toMarkerPosition]);

  useEffect(() => {
    if ((fromId && toId) || plusKmFrom || plusKmTo) getDistance();
  }, [fromId, toId, plusKmFrom, plusKmTo]);

  useEffect(() => {
    if (avtiveLoadsRedirect) {
      // eslint-disable-next-line react/prop-types
      props.history.push('/point/active-loads');
    }
  }, [avtiveLoadsRedirect]);

  // Hacer validaciones de cada campo
  const fieldValidation = () => {
    return true;
  };

  if (isLoading) {
    return (<Loading />);
  }

  return isRizobacterPoint ? (
    <AddLoadForm />
  ) : isVireyesPoint ? <VirreyesAddLoadForm user={user} /> : (
    <CSS.AddLoadWrap>
      {errorMsg !== '' ? <p className="error-msg">{errorMsg}</p> : ''}
      <div className={stepOne ? 'sm-side-info side-info' : 'lg-side-info side-info'}>
        {!uploadSuccess ? (
          <Map center={position} scrollWheelZoom={false} bounds={bounds}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {renderFromMarker ? <Marker position={fromMarkerPosition} icon={startIcon} /> : ''}
            {renderToMarker ? <Marker position={toMarkerPosition} icon={endIcon} /> : ''}
          </Map>
        ) : (
          ''
        )}
        <CSS.LoadDetail>
          <h4>Resumen de tu carga</h4>
          <div className="from-wrapper">
            {fromCity ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  <b>Origen</b>
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{fromCity}</p>
              </>
            ) : (
              ''
            )}
            {plusKmFrom ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Kms Adicionales
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{plusKmFrom}</p>
              </>
            ) : (
              ''
            )}
            {fromAddress ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Dirección
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{fromAddress}</p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="to-wrapper">
            {toCity ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  <b>Destino</b>
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{toCity}</p>
              </>
            ) : (
              ''
            )}
            {plusKmTo ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Kms Adicionales
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{plusKmTo}</p>
              </>
            ) : (
              ''
            )}
            {toAddress ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Dirección
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{toAddress}</p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="distance-wrap" style={{ animation: `fadeIn 5s` }}>
            {distance >= 0 ? (
              <>
                <p className="label">Distancia Total</p>
                <p>{distance} kms</p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="load-info">
            {loadTypeSelected ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Tipo de carga
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{loadTypeSelected.tipoCarga}</p>
              </>
            ) : (
              ''
            )}
            {productTypeSelected ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Tipo de producto
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{productTypeSelected.label}</p>
              </>
            ) : (
              ''
            )}
            {loadOrigin ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Origen de tu carga
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{loadOrigin}</p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="trucks-info">
            {trucksRequested ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Cantidad de camiones
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{trucksRequested}</p>
              </>
            ) : (
              ''
            )}
            {productQuantity ? (
              <>
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Cantidad de producto por camión
                </p>
                <p style={{ animation: `fadeIn 5s` }}>
                  {productQuantity} {unitSelected.label}
                </p>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="last-info">
            {loadDate ? (
              <span className="item">
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Fecha de carga
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{loadDate}</p>
              </span>
            ) : (
              ''
            )}
            {hour ? (
              <span className="item">
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Hora de carga
                </p>
                <p style={{ animation: `fadeIn 5s` }}>{hour}</p>
              </span>
            ) : (
              ''
            )}
            {publicationTime === 0 ? (
              <span className="item">
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Duración de la publicación
                </p>
                <p style={{ animation: `fadeIn 5s` }}>Hasta la fecha y hora de carga</p>
              </span>
            ) : (
              ''
            )}

            {publicationTime > 0 ? (
              <span className="item">
                <p className="label" style={{ animation: `fadeIn 5s` }}>
                  Duración de la publicación
                </p>
                <p style={{ animation: `fadeIn 5s` }}>por {publicationTime} hs.</p>
              </span>
            ) : (
              ''
            )}
            {fare ? (
              <>
                <h1>${`${manualFare} / ${fareType}`}</h1>
                {!uploadSuccess ? (
                  <div className="sm-input-wrap fare-input">
                    <HumberInput
                      label="Ajustá tu tarifa"
                      value={manualFare}
                      min={0}
                      type="number"
                      onChange={setManualFare}
                      onBlur={fieldValidation}
                      id="plusKmFrom"
                      htmlFor="plusKmFrom"
                      errorMsg=""
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            {!stepOne ? (
              <>
                {closedLoadEnabled && showClosedLoadForm ? (
                  <div className="btn-submit-wrap">
                    <div
                      role="button"
                      tabIndex={0}
                      className="btn-submit"
                      onClick={() => {
                        setStepOne(false);
                        stageChangeUpload();
                        unlessOneTruckerListed();
                      }}
                    >
                      Subir carga cerrada
                    </div>
                  </div>
                ) : (
                  <div className="btn-submit-wrap">
                    {!uploadSuccess ? (
                      <div
                        role="button"
                        tabIndex={0}
                        className="btn-submit"
                        onClick={() => {
                          setStepOne(false);
                          stageChangeUpload();
                        }}
                      >
                        Subir carga
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </CSS.LoadDetail>
      </div>
      <div className="form">
        {stepOne ? (
          <div className="step-one-wrap">
            <h4 className="first">Origen</h4>
            <div className="humber-dropdown">
              <label htmlFor="fromCity">
                Ciudad *
                <Async
                  id="fromCity"
                  className="dropdown"
                  defaultOptions
                  placeholder={fromCity}
                  loadOptions={originOptions}
                  onBlur={() => getGeocode(fromCity, 'origen')}
                  onChange={opt => {
                    setFromCity(opt.label.split(',')[0]);
                    setFromId(opt.value);
                  }}
                />
              </label>
            </div>

            <div className="xsm-input-wrap">
              <HumberInput
                label="Kms adicionales"
                value={plusKmFrom}
                type="number"
                onChange={setPlusKmFrom}
                onBlur={fieldValidation}
                id="plusKmFrom"
                htmlFor="plusKmFrom"
                errorMsg=""
              />
            </div>

            <div className="l-input-wrap">
              <HumberInput
                label="Dirección"
                value={fromAddress}
                type="text"
                onChange={setFromAddress}
                id="fromAddres"
                htmlFor="fromAddres"
                errorMsg=""
              />
            </div>

            <h4>Destino</h4>
            <div className="humber-dropdown">
              <label htmlFor="toCity">
                Ciudad *
                <Async
                  id="toCity"
                  className="dropdown"
                  defaultOptions
                  placeholder={toCity}
                  loadOptions={destinationOptions}
                  onBlur={() => getGeocode(toCity, 'destino')}
                  onChange={opt => {
                    setToCity(opt.label.split(',')[0]);
                    setToId(opt.value);
                  }}
                  value={destinationOptionsValue}
                />
              </label>
            </div>
            {showAdditionalInfo ? (
              <>
                <div className="xsm-input-wrap" style={{ animation: `fadeIn 2s` }}>
                  <HumberInput
                    label="Kms adicionales"
                    value={plusKmTo}
                    type="number"
                    onChange={setPlusKmTo}
                    id="plusKmTo"
                    htmlFor="plusKmTo"
                    errorMsg=""
                  />
                </div>
                <div className="l-input-wrap" style={{ animation: `fadeIn 2s` }}>
                  <HumberInput
                    label="Dirección"
                    value={toAddress}
                    type="text"
                    onChange={setToAddress}
                    id="toAddres"
                    htmlFor="toAddres"
                    errorMsg=""
                  />
                </div>
              </>
            ) : (
              ''
            )}
            <div
              className="humber-link"
              onClick={() => toggleCommonDestinationsModal(!commonDestionationsModalOpen)}
              role="button"
              tabIndex={0}
            >
              Frecuentes
              <FontAwesomeIcon className="humber-link-icon" />
            </div>
            <CommonDestinationsModal
              visible={commonDestionationsModalOpen}
              handleClose={() => toggleCommonDestinationsModal(false)}
              callback={selectedDestination => {
                setDestinationOptionsValue({
                  label: `${selectedDestination.addressee} - ${selectedDestination.cityName}, ${selectedDestination.provinceName}`,
                  value: selectedDestination.cityCode,
                });
                setToCity(selectedDestination.cityName);
                setToId(selectedDestination.plantCode);
                getGeocode(selectedDestination.cityName, 'destino');
                setShowAdditionalInfo(true);
                setToAddress(`${selectedDestination.address} - ${selectedDestination.reference}`);
                toggleCommonDestinationsModal(false);
              }}
            />
            {!showAdditionalInfo ? (
              <div
                className="humber-link"
                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                role="button"
                tabIndex={0}
              >
                Agregar detalles del destino
                <FontAwesomeIcon icon={faEdit} className="humber-link-icon" />
              </div>
            ) : (
              ''
            )}
            {showAdditionalInfo ? (
              <div
                className="humber-link"
                onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
                role="button"
                tabIndex={0}
              >
                Ocultar detalles del destino
              </div>
            ) : (
              ''
            )}
            <div className="btn-wrapper">
              {fromCity && toCity ? (
                <button
                  className="btn-enabled"
                  onClick={() => {
                    setStepOne(false);
                    getDistance();
                  }}
                >
                  Siguiente
                </button>
              ) : (
                <button className="btn-disabled">Siguiente</button>
              )}
              {fromCity && toCity && !uploadSuccess ? (
                <div
                  role="button"
                  tabIndex={0}
                  className="mobile-next-button"
                  onClick={() => {
                    setStepOne(false);
                    getDistance();
                    getGeocode(fromCity, 'origen');
                    getGeocode(toCity, 'destino');
                  }}
                >
                  Siguiente
                </div>
              ) : (
                <>{stepOne ? <div className="mobile-next-button-disabled">Siguiente</div> : ''}</>
              )}
            </div>
          </div>
        ) : (
          <>
            {!uploadSuccess ? (
              <div className="step-two-wrap">
                <CSS.LoadTypeWrap>
                  <h4 className="first">¿Qué tipo de carga vas a transportar? *</h4>
                  <SelectionButton
                    renderList={loadTypes.map(i => ({
                      id: i._id,
                      renderValue: i.tipoCarga,
                      tipoCarga: i.tipoCarga,
                      tipoProducto: i.tipoProducto,
                    }))}
                    renderValueChangeAction={setLoadTypeSelected}
                  />
                  <CSS.LoadTypeOptionsWrap>
                    {loadTypeSelected ? (
                      <HumberDropdown
                        list={productTypes}
                        onChange={opt => {
                          setProductTypeSelected(opt);
                          setUnits(opt.unidad);
                        }}
                        value={productTypeSelected}
                        label="¿Qué producto vas a cargar? *"
                        htmlFor="loadTypeSelect"
                        id="loadTypeSelect"
                        required
                      />
                    ) : (
                      ''
                    )}
                    {productTypes && loadTypeSelected.tipoCarga === 'Granel' ? (
                      <CSS.OptionalInfo>
                        <CSS.ProductTypeQuickAccess>
                          <p>Acceso rápido:</p>
                          {productTypes.map(productType => {
                            if (
                              productType.label === 'Soja' ||
                              productType.label === 'Maiz' ||
                              productType.label === 'Girasol' ||
                              productType.label === 'Trigo'
                            )
                              return (
                                <button
                                  tabIndex={0}
                                  onClick={() => {
                                    setProductTypeSelected(productType);
                                    setUnits(productType.unidad);
                                  }}
                                >
                                  {productType.label}
                                </button>
                              );
                            return '';
                          })}
                        </CSS.ProductTypeQuickAccess>
                        <CSS.LoadOriginWrap>
                          <h3>¿De dónde vas a cargar?</h3>
                          <SelectionButton
                            renderList={loadOriginOption.map(i => ({
                              renderValue: i.value,
                              _id: i.id,
                            }))}
                            idChangeAction={setLoadOrigin}
                            dynamicClass="selection-button-horizontal-li"
                          />
                        </CSS.LoadOriginWrap>
                      </CSS.OptionalInfo>
                    ) : (
                      ''
                    )}
                  </CSS.LoadTypeOptionsWrap>
                </CSS.LoadTypeWrap>
                <h2>Configurá los camiones</h2>
                {closedLoadEnabled ? (
                  <div className="closed-load-buttons">
                    {showClosedLoadForm ? (
                      <button
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setShowClosedLoadForm(!showClosedLoadForm);
                          setLoadStatus('open');
                        }}
                      >
                        Carga abierta
                      </button>
                    ) : (
                      <button
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setShowClosedLoadForm(!showClosedLoadForm);
                          setLoadStatus('close');
                        }}
                      >
                        Carga cerrada
                      </button>
                    )}
                  </div>
                ) : (
                  ''
                )}

                {showClosedLoadForm ? (
                  <div className="closed-load-wrap">
                    <div className="humber-input filter-wrapper">
                      <label htmlFor="search">
                        Buscar por CUIT de chofer
                        <input
                          id="search"
                          type="text"
                          className="form-control"
                          value={filterTrucker}
                          onChange={event => setFilterTrucker(event.target.value)}
                        />
                      </label>
                      {filterTrucker.length === 11 ? (
                        <button
                          className="search-button"
                          role="button"
                          onClick={() => searchTrucker(filterTrucker)}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      ) : (
                        <button className="search-button-disabled" role="button">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      )}
                    </div>
                    <div className="trucker">
                      {trucker ? (
                        <>
                          <p>{trucker.nombre}</p>
                          <button role="button" tabIndex={0} onClick={() => addTrucker(trucker)}>
                            Agregar
                          </button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    {truckerSelectedList ? (
                      <>
                        <CSS.Headers>
                          <p className="trucker-cuit">CUIT</p>
                          <p className="trucker-name">Nombre</p>
                          <p className="trucker-cel">Cel</p>
                          <p className="truck-license">Tractor</p>
                          <p className="trailer-license">Remolque</p>
                          <p className="truck-type">Carrocería.</p>
                        </CSS.Headers>
                        <ul className="trucker-list">
                          {truckerSelectedList.map(e => (
                            <li key={e._id} className="trucker-selected">
                              <p>{e.cuit}</p>
                              <p className="trucker-name-value">{e.nombre}</p>
                              <p className="trucker-cel-value">{e.celNumero}</p>
                              <p>{e.patente}</p>
                              <p className="trailer-license">{e.patenteRemolque}</p>
                              <p>{e.truckType}</p>
                              <button
                                role="button"
                                tabIndex={0}
                                onClick={() => handleRemoveItem(e)}
                              >
                                Eliminar
                              </button>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="sm-input-wrap">
                      <HumberInput
                        label="Cant. de producto por camión *"
                        value={productQuantity}
                        min={0}
                        type="number"
                        onChange={setProductQuantity}
                        id="plusKmFrom"
                        htmlFor="plusKmFrom"
                        errorMsg=""
                        required
                      />
                    </div>
                    {loadTypeSelected ? (
                      <div className="sm-input-wrap unit">
                        <HumberDropdown
                          list={units}
                          onChange={opt => setUnitSelected(opt)}
                          value={unitSelected}
                          label="Unidad de medida *"
                          htmlFor="unit"
                          id="unit"
                          required
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <>
                    <div className="sm-input-wrap">
                      <HumberInput
                        label="Cantidad de camiones *"
                        value={trucksRequested}
                        min="0"
                        type="number"
                        onChange={setTrucksRequested}
                        id="plusKmFrom"
                        htmlFor="plusKmFrom"
                        errorMsg=""
                        required
                      />
                    </div>
                    <div className="sm-input-wrap">
                      <HumberInput
                        label="Cant. de producto por camión *"
                        value={productQuantity}
                        min={0}
                        type="number"
                        onChange={setProductQuantity}
                        id="plusKmFrom"
                        htmlFor="plusKmFrom"
                        errorMsg=""
                        required
                      />
                    </div>
                    {loadTypeSelected ? (
                      <div className="sm-input-wrap unit">
                        <HumberDropdown
                          list={units}
                          onChange={opt => setUnitSelected(opt)}
                          value={unitSelected}
                          label="Unidad de medida *"
                          htmlFor="unit"
                          id="unit"
                          required
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}

                <div className="selection-btn-wrapper">
                  <h4>¿Cuándo vas a cargar? *</h4>
                  <SelectionButton
                    renderList={buildDateArr(3).map(i => ({
                      renderValue: i,
                    }))}
                    renderValueChangeActionItem={setLoadDate}
                  />
                </div>
                <div className="sm-input-wrap">
                  <h4>Hora de carga*</h4>
                  <HumberInput
                    label="Hora"
                    value={hour}
                    type="time"
                    onChange={setHour}
                    id="hour"
                    htmlFor="hour"
                    errorMsg=""
                  />
                </div>
                <h4>Información adicional de la carga</h4>
                <div className="humber-input md-input-wrap">
                  <label htmlFor="publicComments">
                    Comentarios públicos *
                    <textarea
                      placeholder="Este comentario lo van a ver todos los interesados en la carga..."
                      value={publicComment}
                      id="publicComments"
                      className="form-control"
                      onChange={e => setPublicComment(e.target.value)}
                    />
                  </label>
                </div>
                <div className="humber-input md-input-wrap">
                  <label htmlFor="privateComments">
                    Comentarios privados *
                    <textarea
                      placeholder="Este comentario sólo lo verán los que hayan tomado la carga...."
                      value={privateComment}
                      id="privateComments"
                      className="form-control"
                      onChange={e => setPrivateComment(e.target.value)}
                    />
                  </label>
                </div>
                <div className="upload-image-wrapper">
                  <div className="flat-image-uri">
                    {flatImageUri ? (
                      <img src={flatImageUri} alt="Plano con dirección del campo" />
                    ) : (
                      <FontAwesomeIcon className="camera-icon" icon={faCamera} />
                    )}
                  </div>
                  <div htmlFor="uploadImage" className="btn-upload-wrapper">
                    <label htmlFor="uploadImg" className="btn-upload">
                      Subir plano
                      <input
                        id="uploadImg"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={event => uploadFlatImage(event.target.files[0])}
                      />
                    </label>
                  </div>
                </div>
                {!showClosedLoadForm ? (
                  <>
                    <h4>Quiero que mi carga esté publicada: *</h4>
                    <SelectionButton
                      renderList={publicationTimeArr.map(i => ({
                        renderValue: i.value,
                        _id: i.id,
                      }))}
                      idChangeAction={setPublicationTime}
                    />
                  </>
                ) : (
                  ''
                )}
                <CSS.LoadDetailMobile>
                  <h4>Resumen de tu carga</h4>
                  <small>{fromCity ? 'Desde' : ''}</small>
                  <p>{fromCity}</p>
                  <small>{toCity ? 'Hasta' : ''}</small>
                  <p>{toCity}</p>
                  <small>{distance ? 'Distancia' : ''}</small>
                  <p>{distance}</p>
                  <small>{loadTypeSelected ? 'Tipo de carga' : ''}</small>
                  <p>{loadTypeSelected.tipoCarga}</p>
                  <small>{trucksRequested ? 'Cantidad de camiones' : ''}</small>
                  <p>{productTypeSelected.label}</p>
                  <p>{trucksRequested}</p>
                  <small>{productTypeSelected ? 'Tipo de producto' : ''}</small>
                  <p>{productTypeSelected.label}</p>
                  <small>{loadOrigin ? 'Origen de tu carga' : ''}</small>
                  <p>{loadOrigin}</p>
                  <small>{loadDate ? 'Fecha de carga' : ''}</small>
                  <p>{loadDate}</p>
                  <small>{hour ? 'Hora' : ''}</small>
                  <p>{hour}</p>
                  <small>{loadDate ? 'Duración de publicación' : ''}</small>
                  {publicationTime === 0 ? (
                    <p style={{ animation: `fadeIn 5s` }}>Hasta la fecha y hora de carga</p>
                  ) : (
                    ''
                  )}
                  {publicationTime > 0 ? (
                    <p style={{ animation: `fadeIn 5s` }}>por {publicationTime} hs.</p>
                  ) : (
                    ''
                  )}
                  <div className="btn-submit-wrap">
                    {!uploadSuccess ? (
                      <div
                        role="button"
                        tabIndex={0}
                        className="btn-submit"
                        onClick={() => {
                          setStepOne(false);
                          stageChangeUpload();
                        }}
                      >
                        Subir carga
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </CSS.LoadDetailMobile>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
      {uploadSuccess ? (
        <div>
          <OptionsRedirect
            genMsj="¡Tu carga fue subida con éxito!"
            msjBtn1="Subir otra carga"
            msjBtn2="Ver mis cargas"
            action1={() => {
              setUploadSuccess(false);
              setStepOne(true);
              setResetFields(true);
            }}
            // eslint-disable-next-line react/prop-types
            action2={() => {
              setActiveLoadsRedirect(true);
              setResetFields(true);
            }}
          />
        </div>
      ) : (
        ''
      )}
    </CSS.AddLoadWrap>
  );
};

export default AddLoadView;
