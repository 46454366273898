import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onMessage } from 'firebase/messaging';
import components from './components';
import Loading from './libs/loading';
import AuthProvider from './components/PrivateRoute/AuthProvider';
import WarningToast from './libs/toast/warningToast';
import { getSessionUserData } from './libs/user';
import { messaging } from './firebase';

const {
  LandingPage,
  SignUp,
  Point,
  Carrier,
  VerifyAccount,
  PasswordReset,
  Terms,
  SettlementAdvance,
  SettlementAdvanceTerms,
} = components;

const PrivateRouteLazy = lazy(() => import('./components/PrivateRoute'));

const publicRoutes = [
  {
    path: '/',
    component: LandingPage,
  },
  {
    path: '/sign-up',
    component: SignUp,
  },
  {
    path: '/verify/:id/:token',
    component: VerifyAccount,
  },
  {
    path: '/password-reset/:id/:token',
    component: PasswordReset,
  },
  {
    path: '/terms',
    component: Terms,
  },
  {
    path: '/settlementAdvance/terms',
    component: SettlementAdvanceTerms,
  },
  {
    path: '/settlementadvance/:billingId/:token',
    component: SettlementAdvance,
  },
  {
    path: '/signup',
    component: SignUp,
  },
];

const privateRoutes = [
  {
    path: '/point',
    component: Point,
  },
  {
    path: '/carrier',
    component: Carrier,
  },
  {
    path: '/carrier/my-fleet',
    component: Carrier,
  },
  {
    path: '/carrier/add-trucker',
    component: Carrier,
  },
  {
    path: '/carrier/carrier-record',
    component: Carrier,
  },
  {
    path: '/carrier/carrier-active',
    component: Carrier,
  },
  {
    path: '/carrier/carrier-record-billing',
    component: Carrier,
  },
  {
    path: '/carrier/checking-account',
    component: Carrier,
  },
  {
    path: '/carrier/profile',
    component: Carrier,
  },
  {
    path: '/point/active-loads',
    component: Point,
  },
  {
    path: '/point/point-record',
    component: Point,
  },
  {
    path: '/',
    component: LandingPage,
  },
];

function routing() {
  const publics = publicRoutes.map(route => (
    <Route exact key={route.path} path={route.path} component={route.component} />
  ));
  const privates = privateRoutes.map(route => (
    <PrivateRouteLazy exact key={route.path} path={route.path} component={route.component} />
  ));

  return [...publics, ...privates];
}

toast.configure({
  position: toast.POSITION.TOP_CENTER,
});

const App = () => {
  const [tcPending, setTcPending] = useState(
    (getSessionUserData() && getSessionUserData().data.tcPending) || false
  );

  useEffect(() => {
    onMessage(messaging, message => {
      toast(message.notification.body);
    });
  }, []);

  return (
    <AuthProvider>
      <Suspense fallback={<Loading />}>
        {tcPending ? (
          <Terms setTcPending={setTcPending} userAlreadyCreated />
        ) : (
          <Router>
            <WarningToast />
            <Switch>{routing()}</Switch>
          </Router>
        )}
      </Suspense>
    </AuthProvider>
  );
};

export default App;
