/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// TODO - mejorar estos disables que pusimos acá arriba
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'moment/locale/es';
import React, { useCallback, useEffect, useState } from 'react';
import { BallClipRotate } from 'react-pure-loaders';
import styled from 'styled-components';
import client from '../../../api/api';
import toCurrency from '../../../libs/currencyUtils';
import CarrierRecordTable from './CarrierRecordTable/CarrierRecordTable';
import GasoilModal from './GasoilModal';

const PhotoIcon = styled.div`
  .cancelled-icon {
    color: #424141;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: auto;
  }
`;

const TableWrap = styled.div`
  .gasoil-list,
  .local-gas-station {
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
  }
`;

const CarrierRecordView = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [typeContentModal, setTypeContentModal] = useState('');
  const [shipmentId, setShipmentId] = useState('');
  const [historyItem, setHistoryItem] = useState('');

  // eslint-disable-next-line react/prop-types
  const DateFilter = ({ column: { setFilter } }) => {
    const [value, setValue] = useState('');
    useEffect(() => {
      if (value && value.length === 10) {
        const dateFilter = moment(value, 'DD-MM-YYYY');
        if (dateFilter.isValid()) {
          setFilter(dateFilter.format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        }
      }
    }, [value]);
    return (
      <input
        value={value || ''}
        onChange={e => {
          setValue(e.target.value || undefined);
        }}
      />
    );
  };

  const columns = [
    {
      Header: 'Ver CPE',
      disableFilters: true,
      disableSorting: true,
      width: 40,
      Cell: ({ row }) => (
        <>
          {row?.original?.tripDocument ? (
            isLoading === row?.original?.id ? (
              <div key={row?.original?.id}>
                <BallClipRotate color="#ffbb0f" loading />
              </div>
            ) : (
              <button
                className="gasoil-list"
                type="button"
                onClick={async e => {
                  setIsLoading(row?.original?.id);
                  try {
                    const res = await client.endpoints.generic.getReports(
                      row?.original?.waybillPdf || row?.original?.signedReport
                    );
                    const pdfWindow = window.open('');
                    const reader = new FileReader();
                    reader.readAsDataURL(res.data);
                    reader.onloadend = () => {
                      const base64data = reader.result;
                      // eslint-disable-next-line no-unused-expressions
                      pdfWindow?.document.write(
                        `<iframe width='100%' height='100%' src='${base64data}'></iframe>`
                      );
                    };
                    reader.onerror = () => {
                      alert('Hubo un error al abrir el archivo');
                      setIsLoading(false);
                    };
                  } catch (error) {
                    alert('Hubo un error al abrir el archivo');
                  } finally {
                    setIsLoading(false);
                  }
                  // eslint-disable-next-line no-unused-expressions
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} size="lg" />
              </button>
            )
          ) : null}
        </>
      ),
    },
    {
      Header: 'Fecha del viaje',
      accessor: ({ load }) => {
        return moment(load.date)
          .locale('es')
          .format('DD-MMM-YYYY');
      },
      id: 'date',
      width: 40,
      Filter: DateFilter,
      disableSorting: true,
    },
    {
      Header: 'Fecha liquidación',
      accessor: ({ settlementDay }) =>
        settlementDay ? moment(settlementDay).format('DD-MMM-YYYY') : '',
      id: 'settlementDay',
      width: 40,
      Filter: DateFilter,
      disableSorting: true,
    },
    {
      Header: 'Fecha de pago',
      accessor: ({ paymentDay }) => (paymentDay ? moment(paymentDay).format('DD-MMM-YYYY') : ''),
      id: 'paymentDay',
      width: 40,
      Filter: DateFilter,
      disableSorting: true,
    },
    {
      Header: 'Tipo de carga',
      accessor: row => {
        return row?.load?.product?.loadType?.name;
      },
      id: 'loadType',
      disableSorting: true,
    },
    {
      Header: 'Producto',
      accessor: row => {
        return row?.load?.product?.name;
      },
      id: 'productType',
      disableSorting: true,
    },
    {
      Header: 'Origen',
      accessor: row => {
        return row?.load?.from;
      },
      id: 'fromCity',
      disableSorting: true,
    },
    {
      Header: 'Destino',
      accessor: row => {
        return row?.load?.to;
      },
      id: 'toCity',
      disableSorting: true,
    },
    {
      Header: 'Distancia',
      accessor: 'load.distance',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Tarifa',
      accessor: row => {
        if (row.transportFare === null) {
          return toCurrency(row?.load?.transportFare);
        }
        return toCurrency(row?.transportFare);
      },
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Transporte',
      accessor: row => {
        return row?.trucker?.user?.legalName;
      },
      id: 'truckerName',
    },
    {
      Header: 'Camión',
      accessor: 'truck.plate',
      id: 'truckPatent',
      disableSorting: true,
    },
    {
      Header: 'Remolque',
      accessor: 'trailer.plate',
      id: 'trailerPatent',
      disableSorting: true,
    },
    {
      Header: 'Tipo de carrocería',
      accessor: 'trailer.trailerType.name',
      id: 'truckBodyType',
    },
    {
      Header: 'Comentarios Privados',
      accessor: 'load.privateComments',
      id: 'private',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Dirección origen',
      accessor: 'load.fromAddress',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Dirección destino',
      accessor: 'load.toAddress',
      disableFilters: true,
      disableSorting: true,
    },
  ];

  const fetchAction = async filters => {
    const resp = await client.endpoints.carrier.getShipmentHistory(filters);
    setIsLoading(false);
    setData(resp.data.rows);
    setPageCount(Math.ceil(resp.data.count / filters.limit));
  };

  const fetchShipments = useCallback(params => {
    // TODO - cambier filters por where o algo por el estilo
    const { filters = [], sortBy = {}, pageIndex = 0, pageSize = 10 } = params || {};
    const fetch = async () => {
      setIsLoading(true);
      let fetchFilters = {
        ...filters.reduce((acc, curr) => {
          acc[curr.id] = curr.value;
          return acc;
        }, {}),
        ...{ limit: pageSize, page: pageIndex + 1, active: true },
      };
      if (sortBy && sortBy['0']) {
        const sorting = `${sortBy[0].id}:${sortBy[0].desc ? 'desc' : 'asc'},`;
        fetchFilters = { ...fetchFilters, ...{ sorting } };
      }
      if (!sortBy['0']) {
        fetchAction(fetchFilters);
      } else {
        await fetchAction(fetchFilters);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <TableWrap>
        <CarrierRecordTable
          columns={columns}
          data={data}
          fetchData={fetchShipments}
          loading={isLoading}
          pageCount={pageCount}
        />
      </TableWrap>
      <GasoilModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        typeContentModal={typeContentModal}
        shipmentId={shipmentId}
        historyItem={historyItem}
      />
    </>
  );
};

export default CarrierRecordView;
