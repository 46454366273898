export default {
  API1_URL: process.env.REACT_APP_API1_URL,
  ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT,
  REPORTS: process.env.REACT_APP_REMITOS_BUCKET,
  BUCKET_API_KEY: process.env.REACT_APP_BUCKET_API_KEY,
  PREMIUM_DOCS_BUCKET: process.env.REACT_APP_PREMIUM_BUCKET,
  HUMBER_BUCKET_URL: process.env.REACT_APP_BUCKET_API,
  AMAZON_SERVICE_URL: process.env.REACT_APP_AMAZON_URL,
  SETTLEMENTS_BUCKET: process.env.REACT_APP_SETTLEMENTS_BUCKET,
  BILLING_BUCKET: process.env.REACT_APP_BILLING_BUCKET,
  LOAD_COMMENTS_BUCKET: process.env.REACT_APP_LOAD_COMMENTS_BUCKET,
  SHIPMENTAPI_URL: process.env.REACT_APP_SHIPMENTAPI_URL,
  WAYBILL_URL: process.env.REACT_APP_WAYBILLAPI_URL,
  BILLINGAPI_URL: process.env.REACT_APP_BILLINGAPI_URL,
  TRUCKERAPI_URL: process.env.REACT_APP_TRUCKERAPI_URL,
  LOADAPI_URL: process.env.REACT_APP_LOADAPI_URL,
  GA_UID: process.env.REACT_APP_GA_UID,
  GTM_ID: process.env.REACT_APP_GTM_ID,
  USERSAPI_URL: process.env.REACT_APP_USERSAPI_URL,
  GOOGLE_API_URL: process.env.REACT_APP_GOOGLE_API_URL,
  RIZOBACTER_CUITS: ['30593174057', '33710831659'],
  VIRREYES_CUITS: '30682713018',
  BIOCERES_ID: process.env.REACT_APP_BIOCERES_ID,
  FIREBASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
