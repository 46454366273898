/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faIdCard } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import client from '../../../../../api/api';
import openCPEPdf from '../../../../../libs/utils';

const TruckerListWrap = styled.div`
  padding: 5px;
  margin-top: 20px;

  p {
    color: whitesmoke;
    font-size: 12px;
  }
`;

const Trucker = styled.div`
  padding: 12px 10px;
  margin: 10px 0;
  background: #30354e;
  border-radius: 7px;
  position: relative;

  p.name {
    font-size: 18px;
  }

  .patent-wrap {
    display: inline-block;
    margin-top: 6px;
  }

  p.patent {
    display: inline-block;
    border: 1px solid #d3d3d3;
    border-top: 5px solid #09f;
    border-radius: 3px;
    text-align: center;
    width: 90px;
    margin: 0 10px 5px 0;
  }

  p.status {
    background: #e03f3f;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 800;
    padding: 1px;
    width: 73px;
    color: lightgray;
    text-align: center;
  }

  .status-wrap {
    width: 100%;
    height: 8px;
    text-align: right;
  }

  .user-icon {
    position: absolute;
    right: 2px;
    top: -7px;
    color: gray;
    font-size: 27px;
    margin: 17px;
  }

  .bottom-icons-wrap {
    height: 34px;
    border-top: 1px solid gray;
    padding-top: 5px;
    margin-top: 7px;
  }

  .origin-cam-icon {
    font-size: 20px;
    width: 33%;
    bottom: 17px;
    color: gray;
  }

  p.label {
    color: gray;
    font-size: 10px;
    bottom: 2px;
  }

  .btn-pics {
    display: inline-block;
    text-align: center;
    width: 50%;
    outline: none;
  }

  .doc-premium-wrap {
    display: inline-block;
    text-align: center;
    width: 50%;
  }

  .doc-premium-icon {
    font-size: 20px;
    width: 33%;
    bottom: 17px;
    color: gray;
  }

  p.label {
    color: gray;
    font-size: 10px;
    bottom: 2px;
  }

  .create-carta-porte-wrap {
    right: 45px;
    top: 10px;
    font-size: 15px;
    color: white;
    font-weight: 600;
    border: 0.5px solid white;
    border-radius: 6px;
    background-color: transparent;
  }
`;

const ReportPicsPopUp = styled.div`
  z-index: 1000;
  border-radius: 7px;
  position: absolute;
  left: 0;
  width: 100%;
  height: 400px;
  background: gray;
  margin: 10px 0;

  img {
    width: 100%;
    border-radius: 7px;
  }

  .close-btn {
    width: 100%;
    color: whitesmoke;
    background: #6495ed;
    border: none;
    font-size: 20px;
    padding: 12px 0px;
  }
`;

// eslint-disable-next-line react/prop-types
const TruckerListMobile = ({ shipments, openCartaPorte }) => {
  const [showReports, setShowReports] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [showPremiumImg, setShowPremiumImg] = useState(false);
  const [truckerPremiumPhotos, setTruckerPremiumPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const premiumImagesList = [];

  const fetchPremiumImg = async trucker => {
    Object.entries(trucker.premiumPhotos || {}).forEach(e => {
      if (typeof e[1] === 'string' && e[1].includes('.jpg')) {
        premiumImagesList.push({ name: e[0], value: e[1] });
      }
    });

    try {
      premiumImagesList.forEach(async e => {
        const res = await client.endpoints.generic.getImagesPremium(e.value);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setTruckerPremiumPhotos(p => p.concat([base64data]));
          setShowPremiumImg(!showPremiumImg);
        };
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <TruckerListWrap>
      {isLoading ? <p>Cargando...</p> : ''}
      {showReports ? (
        <ReportPicsPopUp>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              setReportList([]);
              setShowReports(false);
            }}
          >
            CERRAR
          </button>
          {reportList.map(pic => (
            <>
              <img src={pic} alt="Foto del viaje" key={new Date().getTime()} />
            </>
          ))}
        </ReportPicsPopUp>
      ) : (
        ''
      )}
      {showPremiumImg ? (
        <ReportPicsPopUp>
          <button
            type="button"
            className="close-btn"
            onClick={() => {
              setTruckerPremiumPhotos([]);
              setShowPremiumImg(false);
            }}
          >
            CERRAR
          </button>
          {truckerPremiumPhotos.map(pic => (
            <>
              <img src={pic} alt="Foto del viaje" key={new Date().getTime()} />
            </>
          ))}
        </ReportPicsPopUp>
      ) : (
        ''
      )}
      {shipments.map(e => {
        return (
          <Trucker>
            {e.status === 'cancelada' ? (
              <p className="status">CANCELADO</p>
            ) : (
              <div className="status-wrap">
                <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
              </div>
            )}
            <p className="name">{e.trucker.truckerName}</p>
            <p>CUIT: {e.trucker.truckerCuit}</p>
            <p>Cel: {e.trucker.truckerCellPhone}</p>
            <p className="truck-info">
              {e.truck.truckMake} - {e.truck.truckBodyType}
            </p>
            <div className="patent-wrap">
              <p className="patent-label">Tractor</p>
              <p className="patent">{e.truck.truckPatent}</p>
            </div>
            <div className="patent-wrap">
              <p className="patent-label">Remolque</p>
              <p className="patent">{e.truck.trailerPatent}</p>
            </div>
            <div className="bottom-icons-wrap">
              <span
                role="button"
                tabIndex={0}
                className="btn-pics"
                onClick={() => {
                  fetchPremiumImg(e);
                }}
              >
                <FontAwesomeIcon icon={faIdCard} className="doc-premium-icon" />
                <p className="label">PREMIUM</p>
              </span>
              <div className="images-wrap">
                {!e.tripDocument && (
                  <button
                    type="button"
                    className="create-carta-porte-wrap"
                    title="Carta de porte"
                    tabIndex={0}
                    onClick={() => {
                      openCartaPorte(e);
                    }}
                  >
                    CPE
                  </button>
                )}
                {e.tripDocument && (
                  <a
                    href="#"
                    onClick={() => {
                      openCPEPdf({ ctg: e.tripDocument });
                    }}
                  >
                    {e.tripDocument}
                  </a>
                )}
              </div>
            </div>
          </Trucker>
        );
      })}
    </TruckerListWrap>
  );
};

export default TruckerListMobile;

TruckerListMobile.propTypes = {
  shipments: PropTypes.objectOf().isRequired,
};
