import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Logo from './humber-logo.png';
import { AuthContext } from '../../PrivateRoute/AuthProvider';
import './nav.css';
import useToast from '../../Global/outdatedVersionToast';
import { getSessionUserData } from '../../../libs/user';
import client from '../../../api/api';

const CarrierNav = () => {
  const { logout } = useContext(AuthContext);
  const { _id } = getSessionUserData().data;
  const {
    endpoints: { carrier },
  } = client;

  const logOut = async () => {
    await logout();
    await carrier.fbTokenLogOut(_id);
  };

  const options = [
    { label: 'Buscar viajes', path: '/carrier' },
    { label: 'Mi flota', path: '/carrier/my-fleet' },
    { label: 'Agregar Humber', path: '/carrier/add-trucker' },
    { label: 'Historial', path: '/carrier/carrier-record' },
    { label: 'Viajes Activos', path: '/carrier/carrier-active' },
    { label: 'Cuenta corriente', path: '/carrier/checking-account' },
    { label: 'Facturación', path: '/carrier/carrier-record-billing' },
    { label: 'Mi perfil', path: '/carrier/profile' },
    { label: 'Salir', path: '/', action: () => logOut() },
  ];

  useToast();

  return (
    <nav className="point">
      <div className="logo">
        <a href="/">
          <img className="logo-img" src={Logo} alt="humber logo" />
        </a>
      </div>
      <div className="landing-items">
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon" />
        </label>
        <ul className="menu">
          {options.map(l => {
            return (
              <li key={`key-${l.label}`}>
                <Link to={l.path} onClick={() => (l.action ? l.action() : () => {})}>
                  {l.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(CarrierNav);
