import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Loading from '../../libs/loading';
import client from '../../api/api';
import './verify-account.css';

const VerifyAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { id, token } = useParams();
  const history = useHistory();

  useEffect(() => {
    const verify = async () => {
      try {
        const resp = await client.endpoints.generic.verify({ id, token });
        setIsLoading(false);
        const { username, role } = resp.data;
        setTimeout(() => {
          history.push({
            pathname: '/',
            state: {
              username,
              role,
            },
          });
        }, 3000);
      } catch (error) {
        const { response } = error;
        if (response) {
          const { status } = response;
          if (status === 400) {
            setErrorMessage(response.error.message);
            return;
          }
        }
        setErrorMessage(process.env.REACT_APP_DEFAULT_API_ERROR_MESSAGE);
      }
    };
    verify();
  }, []);

  return (
    <div className="verify-account-wrapper">
      {isLoading ? (
        <div>
          <span>Estamos verificando tu cuenta</span>
          <Loading />
        </div>
      ) : errorMessage !== '' ? (
        <span>{errorMessage}</span>
      ) : (
        <span>
          Verificamos tu email correctamente, en unos instantes vas a ser redirigido a nuestra web
        </span>
      )}
    </div>
  );
};

export default VerifyAccount;
