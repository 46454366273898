/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const IS_EMPTY_ERROR_MSG = 'Por favor, completa este campo';
const HumberDropdown = ({ list, onChange, onClick, label, htmlFor, id, required, value }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [optDrop, setOptDrop] = useState(false);
  const checkEmpty = value => {
    if (!value) {
      setIsEmpty('');
    } else {
      setIsEmpty('');
    }
  };
  return (
    <div className="humber-dropdown">
      <label htmlFor={htmlFor}>
        {label}
        <Select
          id={id}
          options={list}
          placeholder=""
          value={value}
          className="dropdown"
          isSearchable={false}
          onChange={opt => {
            setOptDrop(opt);
            onChange(opt);
          }}
          onClick={() => {
            onClick(true);
          }}
          onBlur={() => {
            if (required) {
              checkEmpty(optDrop);
            }
          }}
        />
      </label>
    </div>
  );
};

HumberDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
  list: PropTypes.objectOf(PropTypes.object).isRequired,
};

HumberDropdown.defaultProps = {
  onClick: () => {},
  required: false,
  value: '',
};

export default HumberDropdown;
