/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable react/jsx-props-no-spreading

import React, { useEffect, useMemo } from 'react';
import { useTable, useFilters, useSortBy, usePagination, useExpanded } from 'react-table';
import { useDebouncedCallback } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Pagination from '../../../../libs/Pagination';
import Loading from '../../../../libs/loading';
import './carrier-record-billing-table.css';
import PdfViewer from '../../../Global/pdfViewer';

// eslint-disable-next-line react/prop-types
function defaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
}

const CarrierRecordBillingTable = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  renderRowSubComponent,
  settlement,
  invoiceList,
  showInvoices,
  debitNoteList,
  showDebitNotes,
  creditNoteList,
  showCreditNotes,
  gasoilInvoicesList,
  showGasoilInvoices,
  showSettlementPopUp,
  setShowSettlementPopUp,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: defaultColumnFilter,
    }),
    []
  );

  const [debounceCallback] = useDebouncedCallback(filters => fetchData(filters), 1500, {
    maxWait: 2000,
  });

  // const tableState = useTableState({ pageIndex: 0 });

  // const [{ sortBy, filters, pageIndex, pageSize }] = tableState;

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    state: { sortBy, filters, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualFilters: true,
      manualPagination: true,
      pageCount: controlledPageCount,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // de esta forma me aseguro que no se ejecuten requests en el mount del component
    if (Number.isInteger(pageCount)) {
      if (!_.isEmpty(filters)) {
        debounceCallback({
          ...{ filters },
          ...{ sortBy },
          ...{ pageSize },
          ...{ pageIndex },
        });
      } else {
        fetchData({
          ...{ filters },
          ...{ sortBy },
          ...{ pageSize },
          ...{ pageIndex },
        });
      }
    }
  }, [pageIndex, pageSize, filters, fetchData, debounceCallback, sortBy]);
  return (
    <div className="carrier-record-billing-table-wrapper">
      {loading ? (
        <Loading />
      ) : (
        <>
          {showSettlementPopUp ? (
            <>
              <div className="doc-settlements">
                <span tabIndex={0} role="button" onClick={() => setShowSettlementPopUp(false)}>
                  <h3>x</h3>
                </span>
                <div className="pdf-list-wrapper">
                  <p>Liquidación</p>
                  <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
                  <a href={settlement} download>
                    Descargar liquidación
                  </a>
                  <div className="settlement-wrap" tabIndex={0} role="button">
                    <PdfViewer file={settlement} />
                  </div>
                  {showInvoices ? (
                    <>
                      <p>Líquido de producto</p>
                      {invoiceList.map(invoice => (
                        <>
                          <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
                          <a href={invoice.pdf} download>
                            Descargar líquido de producto
                          </a>
                          <PdfViewer file={invoice.pdf} />
                        </>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                  {showDebitNotes ? (
                    <>
                      <p>Notas de debito</p>
                      {debitNoteList.map(debitNote => (
                        <>
                          <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
                          <a href={debitNote.pdf} download>
                            Descargar nota de débito
                          </a>
                          <PdfViewer file={debitNote.pdf} />
                        </>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                  {showCreditNotes ? (
                    <>
                      <p>Notas de crédito</p>
                      {creditNoteList.map(creditNote => (
                        <>
                          <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
                          <a href={creditNote.pdf} download>
                            Descargar nota de crédito
                          </a>
                          <PdfViewer file={creditNote.pdf} />
                        </>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                  {showGasoilInvoices ? (
                    <>
                      <p>Facturas Gasoil</p>
                      {gasoilInvoicesList.map(gasoilInvoice => (
                        <>
                          <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
                          <a href={gasoilInvoice.pdf} download>
                            Descargar factura gasoil
                          </a>
                          <PdfViewer file={gasoilInvoice.pdf} />
                        </>
                      ))}
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>{' '}
            </>
          ) : (
            ''
          )}
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.getHeaderGroupProps().key}
                >
                  {headerGroup.headers.map(column => {
                    return (
                      <th {...column.getHeaderProps()} key={column.id}>
                        <div {...column.getSortByToggleProps()}>{column.render('Header')}</div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {page.map(row => {
                return (
                  prepareRow(row) || (
                    <>
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {renderRowSubComponent && row.isExpanded ? (
                        <tr className="record-shipment">
                          <td colSpan={allColumns.length}>{renderRowSubComponent({ row })}</td>
                        </tr>
                      ) : null}
                    </>
                  )
                );
              })}
            </tbody>
          </table>
        </>
      )}
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        pageIndex={pageIndex}
      />
    </div>
  );
};

export default CarrierRecordBillingTable;
