import styled from 'styled-components';

const TermsWrap = styled.div`
  .terms,
  .sign-up-terms {
    color: white;
    margin: 5% 15% 30% 15%;

    h1 {
      color: white;
    }

    .footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: black;
      height: 12pc;
      padding: 90px;
      text-align: right;
    }

    .footer button {
      display: inline-block;
      border-radius: 30px;
      outline: none;
      background: #ffbb0f;
      color: gray;
      border: 1px solid #ffbb0f;
      padding: 1px 22px;
      margin: 0 20px;
    }

    .footer button:focus {
      border: none;
      outline: none;
    }

    .footer button:hover {
      border: 1px solid black;
    }

    .footer p {
      color: white;
      background: red;
      position: absolute;
      right: 28%;
      top: 0;
      margin: 20px;
      padding: 17px 166px;
      border-radius: 5px;
    }

    .footer h3 {
      display: inline-block;
      color: white;
      font-size: 20px;
    }
  }
`;

export default { TermsWrap };
