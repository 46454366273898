/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCrown, faTruckMoving } from '@fortawesome/free-solid-svg-icons';

const LoadSelected = ({ loadSelected, setShowLoadList }) => {
  const [showLoadDetail, setShowLoadDetail] = useState(true);
  const conditions = loadSelected.conditions ? loadSelected.conditions : [];

  const media = {
    tablet: '@media(min-width: 768px)',
    desktop: '@media(min-width: 1366px)',
  };

  const LoadSelectedWrap = styled.div`
    p.left-arrow {
      color: #6495ed;
      display: inline-block;
      margin: 7px;
      font-size: 14px;
      vertical-align: top;
    }

    h3 {
      font-weight: 300;
      color: #ffbb0f;
    }

    h3 {
      font-size: 24px;
    }

    .left-arrow {
      color: #6495ed;
      margin: 10px 0 0 20px;
      display: inline-block;
    }

    .details {
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      margin: 40px 1px 0 1px;
      background: #30354e;
      padding: 20px 26px 100px;
    }

    p.conditions {
      font-size: 14px;
      color: lightgray;
    }

    h4 {
      color: #ffbb0f;
      border-bottom: 1px solid gray;
      font-size: 20px;
      font-weight: 300;
      margin-top: 15px;
    }

    ${media.desktop} {
      display: none;
    }
  `;

  const LoadSelectedDesktopWrap = styled.div`
    display: none;

    ${media.desktop} {
      display: block;
      padding: 10px;

      .left-arrow {
        cursor: pointer;
        color: #6495ed;
        margin: 0 12px 0 0;
        display: inline-block;
      }
    }
  `;

  const LoadDesktop = styled.div`
    display: none;

    ${media.desktop} {
      display: block;
      border-bottom: 1px solid gray;
      border-left: 3px solid #1c2023;
      cursor: pointer;
      margin-top: 30px;

      &:hover {
        background: #30354e;
        border-left: 3px solid #ffbb0f;
      }

      p,
      .sm,
      .md,
      .lg {
        font-size: 12px;
        display: inline-block;
        color: white;
      }

      p.date {
        width: 160px;
      }

      p.load-type,
      p.product-type,
      p.from-city,
      p.to-city {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        vertical-align: sub;
      }

      p.load-type {
        width: 80px;
      }

      p.product-type {
        width: 60px;
      }

      p.from-city {
        width: 145px;
      }

      p.to-city {
        width: 80px;
        padding-right: 2px;
      }

      p.fare {
        width: 145px;
      }

      p.distance {
        width: 70px;
      }

      p.pending-trucks {
        width: 45px;
      }

      p.pending-trucks .icon {
        margin: 0 6px;
      }

      .humber-green {
        color: #19c482;
      }

      .humber-yellow {
        color: #ffbb0f;
      }

      button {
        display: none;
      }

      .premium-wrap {
        display: inline-block;
        width: 55px;
        text-align: center;
        margin: 4px 0;
      }

      .premium-wrap .premium {
        font-size: 12px;
      }

      .premium-wrap .no-premium {
        color: gray;
        font-size: 20px;
      }

      p.mobile-pending-trucks {
        display: none;
      }

      p.desktop-pending-trucks {
        display: inline-block;
      }
      p.mobile-pending-trucks {
        display: none;
      }

      p.desktop-pending-trucks {
        display: inline-block;
        padding: 5px 12px;
      }
    }
  `;

  const Load = styled.div`
    width: 90%;
    margin: 0 auto;

    p {
      color: white;
      text-align: left;
      padding-left: 6px;
    }

    p.load-date {
      border-radius: 12px;
      width: 100%;
      margin-top: 10px;
      text-align: center;
      color: black;
      background: #ffbb0f;
      font-size: 20px;
      padding: 20px;
    }

    p.load-type {
      font-size: 14px;
      padding-top: 17px;
      line-height: 17px;
    }

    p.product-type {
      line-height: 40px;
      font-size: 20px;
    }

    p.city {
      line-height: 40px;
      font-size: 40px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p.fare {
      line-height: 70px;
      color: #19c482;
      font-size: 30px;
    }

    p.mobile-pending-trucks {
      display: block;
      padding-bottom: 10px;
    }

    p.desktop-pending-trucks {
      display: none;
    }
  `;

  return (
    <>
      {' '}
      {showLoadDetail ? (
        <>
          <LoadSelectedWrap>
            <FontAwesomeIcon className="left-arrow" icon={faArrowLeft} />
            <p
              role="button"
              tabIndex={0}
              className="left-arrow"
              onClick={() => {
                setShowLoadList(true);
                setShowLoadDetail(false);
              }}
            >
              Volver a cargas
            </p>
            <Load>
              <p className="load-date">
                {moment(loadSelected.date)
                  .locale('es')
                  .format('DD-MMM-YYYY - HH:mm')}{' '}
                hs
              </p>
              <p className="load-type">{loadSelected.loadType}</p>
              <p className="product-type">{loadSelected.productType}</p>
              <p className="city">{loadSelected.from}</p>
              <p className="city">{loadSelected.to}</p>
              <p className="fare">
                ${loadSelected.transportFare} x {loadSelected.fareType} + IVA
              </p>
              <p className="mobile-pending-trucks">
                {loadSelected.pendingTrucks} camiones pendientes
              </p>
              <p className="desktop-pending-trucks">{loadSelected.pendingTrucks}</p>
            </Load>
            <div className="details">
              <h3>Detalles de la carga</h3>
              <h4>Comentarios</h4>
              <p>
                {loadSelected.publicComments
                  ? loadSelected.publicComments
                  : '- El dador de carga no ingresó detalles'}
              </p>
              <h4>Condiciones</h4>
              {conditions.map(cond => (
                <p key={cond.id} className="conditions">
                  - {cond.condition}
                </p>
              ))}
              <h4>Sucursal</h4>
              {loadSelected.branchOffice && (
                <p className="conditions">
                  - {loadSelected.branchOffice.name} - Cel: {loadSelected.branchOffice.phoneNumber}
                </p>
              )}
            </div>
          </LoadSelectedWrap>
          <LoadSelectedDesktopWrap>
            <FontAwesomeIcon className="left-arrow" icon={faArrowLeft} />
            <p
              className="left-arrow"
              onClick={() => {
                setShowLoadList(true);
                setShowLoadDetail(false);
              }}
            >
              Volver a cargas
            </p>
            <LoadDesktop>
              <div className="premium-wrap">
                <p>
                  {loadSelected.premium ? (
                    <FontAwesomeIcon className="premium humber-yellow" icon={faCrown} />
                  ) : (
                    <FontAwesomeIcon className="no-premium" icon={faCrown} />
                  )}
                </p>
              </div>
              <p className="date">
                {moment(loadSelected.date)
                  .locale('es')
                  .format('DD-MMM-YYYY - HH:mm')}{' '}
                hs
              </p>
              <p className="load-type">{loadSelected.loadType}</p>
              <p className="product-type">{loadSelected.productType}</p>
              <p className="from-city">de {loadSelected.from}</p>
              <p className="to-city">a {loadSelected.to}</p>
              <p className="distance">{loadSelected.distance}</p>
              <p className="fare humber-green">
                ${loadSelected.transportFare} x {loadSelected.fareType} + IVA
              </p>
              <p className="pending-trucks humber-yellow">
                <FontAwesomeIcon icon={faTruckMoving} className="icon" />
                {loadSelected.pendingTrucks}
              </p>
            </LoadDesktop>
          </LoadSelectedDesktopWrap>
        </>
      ) : (
        ''
      )}
    </>
  );
};

LoadSelected.propTypes = {
  setShowLoadList: PropTypes.func.isRequired,
  loadSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default LoadSelected;
