import React from 'react';
import { Checkbox, HStack, Stack, InputGroup, Input, InputRightElement, IconButton, Button } from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';

const Filter = ({
    updateFilters,
    setDocumentNumberSearch,
    searchCheckingBalanceData
}) => {
    return (
        <>
            <HStack mb='1'>
                <Stack spacing={5} direction='row' >
                    <Checkbox defaultChecked onChange={(e) => updateFilters('all', e.target.checked)} > Todos los movimientos</Checkbox>
                    <Checkbox onChange={(e) => updateFilters('NC', e.target.checked)}> Notas de crédito </Checkbox>
                    <Checkbox onChange={(e) => updateFilters('ND', e.target.checked)}> Notas de débito </Checkbox>
                    <Checkbox onChange={(e) => updateFilters('LP', e.target.checked)}> Liquido producto</Checkbox>
                    <Checkbox onChange={(e) => updateFilters('FC', e.target.checked)}> Facturas de combustible</Checkbox>
                    <Checkbox onChange={(e) => updateFilters('OP', e.target.checked)}> Pagos </Checkbox>
                    <Checkbox onChange={(e) => updateFilters('RE', e.target.checked)}> Retenciones</Checkbox>
                    <Checkbox onChange={(e) => updateFilters('AE', e.target.checked)}> Adelantos de efectivo </Checkbox>
                </Stack>

            </HStack>
            <HStack>
                <Input onChange={(e) => { setDocumentNumberSearch(e.target.value) }}
                    size={'md'}
                    type={'text'}
                    placeholder='Buscar movimiento'
                    w={300}
                />
                <Button w={100} onClick={searchCheckingBalanceData}>Filtrar</Button>
            </HStack>
        </>
    )
}

export default Filter;