/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = styled(ToastContainer).attrs({})`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .Toastify__toast--warning {
    background: unset;
    min-height: unset;
    position: unset;
    margin: unset;
    padding: unset;
    border-radius: unset;
    max-height: unset;
    height: unset;
    display: unset;
    text-align: center;
  }
`;

export const showToast = ({ message, component, options }) => {
  toast.warning(component || message, {
    ...(options && options),
  });
};

export const closeToast = ({ toastId }) => {
  toast.dismiss(toastId);
};

const WarningToast = () => {
  return <Toast />;
};

export default WarningToast;
