import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const ActiveLoadsWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1400px;

  .humber-yellow {
    color: #ffbb0f;
  }

  .load-wrap {
    outline: none;
  }
`;

const MapWrap = styled.div`
  display: none;

  .leaflet-container {
    height: 25pc;
    width: 100%;
  }

  ${media.tablet} {
    width: 50%;
    min-height: 600px;
    float: right;

    .leaflet-container {
      width: 90%;
      max-width: 400px;
      margin: 70px auto;
      border-radius: 12px;
    }

    .active-load-start-pin {
      font-size: 30px;
      color: #b64dea;
      background: transparent;
      text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    }
    .active-load-end-pin {
      font-size: 30px;
      color: #000;
      background: transparent;
      text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    }
  }

  ${media.desktop} {
    display: inline-block;
    width: 25%;
    padding: 18px 0px;

    .leaflet-container {
      width: 100%;
      border-radius: 12px;
      height: 21pc;
      margin: 12px 5px 0 0;
    }

    h1 {
      color: white;
      text-align: center;
      margin-top: 10px;
      font-size: 30px;
    }

    .no-loads-icon-map {
      color: darkgray;
      padding: 20px;
      font-size: 20pc;
      margin-top: 34px;
    }
  }
`;

const ActiveLoadsMobile = styled.div`
  background: rgb(28, 32, 35);
  min-width: 340px;

  .no-loads-wrap {
    text-align: center;
  }

  .no-loads-wrap h1 {
    color: #ffbb0f;
    font-size: 18px;
    margin: 30px auto;
    padding-bottom: 8pc;
    width: 14pc;
  }

  .no-loads-wrap .no-loads-icon {
    color: gray;
    font-size: 80px;
    margin: 30px auto;
  }

  .no-loads-wrap .add-load-btn {
    text-decoration: none;
    background: #ffbb0f;
    padding: 8px 40px;
    border-radius: 20px;
    color: darkslategray;
  }

  h4 {
    font-size: 25px;
    font-weight: 400;
    color: rgb(255, 187, 15);
    text-align: left;
    padding: 10px 20px;
    margin: 0px;
  }

  .load-list-wrap {
    padding: 0 0 40px 0;
  }

  .load-list-wrap {
    outline: none;
  }

  ${media.tablet} {
    width: 50%;
    min-height: 600px;
    margin: 0;
    border-radius: unset;
    box-shadow: unset;
  }

  ${media.desktop} {
    display: none;
  }
`;

const ActiveLoadsDesktop = styled.div`
  display: none;

  ${media.desktop} {
    display: inline-block;
    width: 1050px;
    padding-bottom: 100px;

    .no-loads-wrap {
      text-align: center;
    }

    .no-loads-wrap h1 {
      color: #ffbb0f;
      font-size: 18px;
      margin: 30px auto;
      padding-bottom: 8pc;
      width: 14pc;
    }

    .no-loads-wrap .no-loads-icon {
      color: gray;
      font-size: 80px;
      margin: 30px auto;
    }

    .no-loads-wrap .add-load-btn {
      text-decoration: none;
      background: #ffbb0f;
      padding: 8px 40px;
      border-radius: 20px;
      color: darkslategray;
    }

    h4 {
      font-size: 25px;
      color: rgb(255, 187, 15);
      text-align: left;
      margin: 0px;
      padding: 20px 19px 20px 0;
      border-bottom: 1px solid #363636;
    }
  }
`;

export default { ActiveLoadsWrap, MapWrap, ActiveLoadsMobile, ActiveLoadsDesktop };
