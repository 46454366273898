/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CSS from './terms-styles';
import { getSessionUserData, saveSessionUserData } from '../../libs/user';
import client from '../../api/api';

const Terms = ({ setTcPending, userAlreadyCreated }) => {
  const [termsAcceptedSuccess, setTermsAcceptedSuccess] = useState(false);
  const [termsTemplate, setTermsTemplate] = useState('');
  const [showError, setShowError] = useState(false);
  const [close, setClose] = useState(false);

  const accept = async () => {
    try {
      const res = await client.endpoints.carrier.acceptTerms(getSessionUserData().data._id);
      if (res.status === 200) {
        const sessionData = getSessionUserData();
        sessionData.data.tcPending = false;
        saveSessionUserData(sessionData);
        setTcPending(false);
        setTermsAcceptedSuccess(true);
      }
    } catch (error) {
      setTcPending(false);
      setShowError(true);
    }
  };

  const getTerms = async () => {
    try {
      const res = await client.endpoints.generic.getTerms();
      if (res.status === 200) {
        setTermsTemplate(res.data);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <CSS.TermsWrap>
      {userAlreadyCreated ? (
        <>
          <div className="terms">
            {termsTemplate ? <div dangerouslySetInnerHTML={{ __html: termsTemplate }} /> : ''}
            <div className="footer">
              {showError ? (
                <p style={{ animation: `fadeIn 5s` }}>
                  Hubo un error al aceptar los términos. Comunicate con nosotros
                </p>
              ) : (
                ''
              )}
              <h3>He leído y acepto los terminos y condiciones</h3>
              <button type="button" onClick={() => accept()}>
                Aceptar
              </button>
            </div>
            {termsAcceptedSuccess ? <h1>Terminos y condiciones aceptados correctramente</h1> : ''}
          </div>
        </>
      ) : (
        <>
          {!close ? (
            <div className="sign-up-terms">
              {termsTemplate ? <div dangerouslySetInnerHTML={{ __html: termsTemplate }} /> : ''}
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </CSS.TermsWrap>
  );
};

Terms.propTypes = {
  setTcPending: PropTypes.func.isRequired,
  userAlreadyCreated: PropTypes.bool.isRequired,
};

export default Terms;
