import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import client from '../../api/api';
import CarrierUbicationForm from '../Kyc/CarrierUbicationForm';
import CarrierNav from './CarrierNav';
import SearchLoadsView from './SearchLoadsView';
import MyFleet from './MyFleet';
import AddTrucker from './AddTrucker';
import CarrierRecordView from './CarrierRecordView';
import CarrierActiveView from './CarrierActiveView';
import CarrierRecordBilling from './CarrierRecordBilling';
import Profile from './Profile';
import { CheckingAccount } from './CheckingAccount';

const routes = [
  {
    path: '/carrier',
    component: SearchLoadsView,
  },
  {
    path: '/carrier/my-fleet',
    component: MyFleet,
  },
  {
    path: '/carrier/add-trucker',
    component: AddTrucker,
  },
  {
    path: '/carrier/carrier-record',
    component: CarrierRecordView,
  },
  {
    path: '/carrier/carrier-active',
    component: CarrierActiveView,
  },
  {
    path: '/carrier/carrier-record-billing',
    component: CarrierRecordBilling,
  },
  {
    path: '/carrier/checking-account',
    component: CheckingAccount,
  },
  {
    path: '/carrier/profile',
    component: Profile,
  },
];

function routing() {
  return routes.map(route => (
    <Route exact key={route.path} path={route.path} component={route.component} />
  ));
}

const Carrier = () => {
  const [updateGeocode, setUpdateGeocode] = useState('');
  
  const checkUpdatedGeocode = async () => {
    const response = await client.endpoints.carrier.getUpdatedGeocode();
    setUpdateGeocode(response.data);
  };

  useEffect(() => {
    checkUpdatedGeocode();
  }, []);

  return (
    <Router>
      <>
        {updateGeocode && <CarrierUbicationForm />}
        <CarrierNav />
        <Switch>{routing()}</Switch>
      </>
    </Router>
  );
};

export default Carrier;
