import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const SearchLoadsViewWrap = styled.div`
  margin: 0 auto;
  background: #1c2023;

  p {
    color: white;
  }

  .filter-wrapper {
    background: #1c2023;
    padding: 0 1px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin: -40px 0 -20px;
  }
  .filter-wrapper label {
    padding: 22px;
  }

  ${media.desktop} {
    width: 1340px;

    .filter-wrapper {
      width: 100%;
      background: #1c2023;
      padding: 0 1px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      margin: 0;
      text-align: center;
    }

    .filter-wrapper label {
      padding: 0 22px;
      width: 25pc;
    }

    .filter-wrapper input {
      border-radius: 20px;
    }
  }
`;

const LoadListWrap = styled.div`
  p {
    color: white;
  }

  p.loading-list {
    font-size: 14px;
    color: gray;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  ${media.desktop} {
    display: inline-block;
    width: 70%;
    height: 100vh;
  }
`;

const TruckerListWrap = styled.div`
  p {
    color: white;
  }
  ${media.desktop} {
    display: inline-block;
    width: 70%;
    height: 100vh;
    overflow-y: hidden;
  }
`;

const MapWrap = styled.div`
  display: none;
  background: #30354e;
  height: 100vh;
  padding: 10px 30px;
  vertical-align: top;
  float: right;

  .leaflet-container {
    height: 23pc;
    width: 400px;
    margin: 0 auto;
    border-radius: 10px;
  }

  ${media.desktop} {
    display: inline-block;
    width: 30%;
    padding: 2px 10px;

    .leaflet-container {
      width: 100%;
    }

    h1 {
      color: white;
      text-align: center;
      margin-top: 10px;
      font-size: 30px;
    }
  }
`;

const Title = styled.h1`
  width: 100%;
  padding: 20px;
  color: white;
  font-size: 30px;
  text-align: center;
  background: #333750;

  ${media.desktop} {
    width: 68%;
    background: #1c2023;
    font-size: 26px;
    font-weight: 300;
    color: #ffbb0f;
    text-align: left;
    padding: 6px 17px;
    margin: 0;
  }
`;

const MapIconWrap = styled.div`
  span {
    color: black;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background: #ffbb0f;
    float: right;
    text-align: center;
    margin: 10px 22px;
    padding: 2px;
  }
`;

const TodayLoadsWrapper = styled.div`
  width: 90%;
  overflow-x: auto;
  padding: 10px 0 0 10px;
  display: inline-block;

  h1 {
    margin: 0;
    color: #ffbb0f;
    width: 100%;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    border-bottom: unset;
    padding-bottom: 10px;
  }

  p {
    color: white;
  }

  ${media.desktop} {
    width: 98%;
    padding: 10px;
    margin: 10px 10px 0 10px;
    background: #2b333e;
    border-radius: 10px;
    min-height: 10pc;

    h1 {
      border-bottom: 1px solid gray;
    }

    p.empty-list {
      color: gray;
      width: 100%;
      text-align: center;
      margin-top: 33px;
    }
  }
`;

const TomorrowLoadsWrapper = styled.div`
  width: 90%;
  overflow-x: auto;
  padding: 10px 0 0 10px;
  display: inline-block;

  h1 {
    margin: 0;
    color: #ffbb0f;
    width: 100%;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    border-bottom: unset;
    padding-bottom: 10px;
  }

  p {
    color: white;
  }

  ${media.desktop} {
    width: 98%;
    padding: 10px;
    margin: 10px 10px 0 10px;
    background: #2b333e;
    border-radius: 10px;
    min-height: 10pc;

    h1 {
      border-bottom: 1px solid gray;
    }

    p.empty-list {
      color: gray;
      width: 100%;
      text-align: center;
      margin-top: 33px;
    }
  }
`;

const NextDaysLoadsWrapper = styled.div`
  width: 90%;
  overflow-x: auto;
  padding: 10px 0 0 10px;
  display: inline-block;
  margin-bottom: 10pc;

  h1 {
    margin: 0;
    color: #ffbb0f;
    width: 100%;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    border-bottom: unset;
    padding-bottom: 10px;
  }

  p {
    color: white;
  }
  ${media.desktop} {
    width: 98%;
    padding: 10px;
    margin: 10px 10px 0 10px;
    background: #2b333e;
    border-radius: 10px;
    min-height: 10pc;

    h1 {
      border-bottom: 1px solid gray;
    }

    p.empty-list {
      color: gray;
      width: 100%;
      text-align: center;
      margin-top: 33px;
    }
  }
`;

const LoadSelectedWrap = styled.div`
  background: #1c2023;
  h1 {
    color: #ffbb0f;
  }
`;

export default {
  SearchLoadsViewWrap,
  LoadListWrap,
  TruckerListWrap,
  MapWrap,
  Title,
  MapIconWrap,
  TodayLoadsWrapper,
  TomorrowLoadsWrapper,
  NextDaysLoadsWrapper,
  LoadSelectedWrap,
};
