import { useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useModal = (initialMode = false) => {
  const [modalOpen, setModalOpen] = useState(initialMode);
  const toggle = () => {
    setModalOpen(!modalOpen);
  };
  return [modalOpen, setModalOpen, toggle];
};
