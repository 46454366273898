import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import client from '../../api/api';
/* eslint-disable no-underscore-dangle */

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const HumberLogo = styled.div`
  width: 10pc;
  padding: 14px 14px 0 14px;

  img {
    width: 100%;
  }
`;

const SettlementAdvanceWrap = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2,
  h3,
  h4 {
    color: white;
  }

  h2 {
    display: inline-flex;
    font-size: 2pc;
    margin-bottom: 2pc;
  }

  h3 {
    font-size: 3pc;
    margin: 40px 0 17px 0;
  }

  h4 {
    margin: 0;
  }

  h5 {
    color: #ffbb0f;
    font-size: 14px;
    font-weight: bold;
  }

  p {
    color: white;
    margin-bottom: 25px;
  }

  button {
    border-radius: 25px;
    width: 100%;
    border: none;
    padding: 6px;
    font-size: 22px;
    margin: 16px auto;
  }

  button.confirm {
    background: #ffbb0f;
  }

  button.reject {
    background: transparent;
    color: #ffbb0f;
    border: 1px solid #ffbb0f;
  }

  small {
    color: white;
    font-size: 23px;
    margin-left: 10px;
  }

  ${media.tablet} {
    button {
      width: 18pc;
    }
  }

  ${media.desktop} {
    width: 50%;
    margin: 0 auto;
  }
`;

const Separator = styled.div`
  background: #ffbb0f;
  width: 6pc;
  height: 1pc;
  margin-bottom: 4pc;

  ${media.desktop} {
    width: 14pc;
    margin-bottom: 1pc;
  }
`;

const Content = styled.div`
  ${media.tablet} {
    padding: 40px;
  }
`;

const ButtonsWrap = styled.div`
  width: 100%;
  margin: 0 auto;

  p {
    width: 100%;
    font-size: 25px;
    text-align: center;
  }

  p.success {
    color: #19c482;
  }

  p.rejected {
    color: rgb(224, 63, 63);
  }

  ${media.tablet} {
    display: flex;
    justify-content: column-reverse;
    flex-direction: row-reverse;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;

  a {
    margin-top: 10px;
  }
`;

const SettlementAdvance = () => {
  const { billingId, token } = useParams();
  const [advanceId, setAdvanceId] = useState('');
  const [originalAmount, setOriginalAmmout] = useState('...');
  const [cost, setCost] = useState('...');
  const [total, setTotal] = useState('...');
  const [success, setSuccess] = useState(false);
  const [advanceRejected, setAdvanceRejected] = useState(false);

  const acceptAdvance = async () => {
    const res = await client.endpoints.carrier.acceptSettlementAdvance(advanceId, token);
    if (res.status === 200) {
      setSuccess(true);
    }
  };

  const createAdvance = async () => {
    const advance = await client.endpoints.carrier.createSettlementAdvance(billingId, token);
    const id = advance.data._id;
    setAdvanceId(id);
    setOriginalAmmout(advance.data.final);
    setCost(advance.data.cost);
    setTotal(advance.data.totalAdvance);
  };
  const rejectAdvance = async () => {
    const res = await client.endpoints.carrier.rejectSettlementAdvance(advanceId, token);
    if (res.status === 200) {
      setAdvanceRejected(true);
    }
  };

  useEffect(() => {
    createAdvance();
  }, []);

  return (
    <>
      <HumberLogo>
        <img src="../../../images/icons/humber-logo.png" alt="humber-logo" />
      </HumberLogo>
      <SettlementAdvanceWrap>
        <h3 className="title">Adelanto de pago</h3>
        <Separator />
        <Content>
          <h4>Monto a adelantar</h4>
          <h2>${originalAmount.replace('.', ',')}</h2>

          <h4>Costo del adelanto</h4>
          <h2>${cost.replace('.', ',')}</h2>
          <small>+ IVA</small>

          <h4>Monto a depositar</h4>
          <h2>${total.replace('.', ',')}</h2>
          <h5>
            El monto a depositar puede demorar hasta 5 días hábiles dependiendo el momento de la
            solicitud.
          </h5>
        </Content>
        <ButtonsWrap>
          {advanceRejected ? (
            <p className="rejected">Si cambias de opinion el adelanto va a seguir disponible.</p>
          ) : (
            ''
          )}
          {success ? <p className="success">Tu solicitud de adelanto fue aprobada</p> : ''}
          {!advanceRejected && !success ? (
            <>
              <button
                type="button"
                className="confirm"
                onClick={() => {
                  acceptAdvance();
                }}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="reject"
                onClick={() => {
                  rejectAdvance();
                }}
              >
                Rechazar
              </button>
            </>
          ) : (
            ''
          )}
        </ButtonsWrap>
      </SettlementAdvanceWrap>
      <Footer>
        <Link to="/settlementAdvance/terms" target="_blank" className="link">
          Términos y condiciones
        </Link>
      </Footer>
    </>
  );
};

export default SettlementAdvance;
