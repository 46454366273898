/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const LoadInfoWrap = styled.div`
  display: none;

  ${media.tablet} {
    width: 90%;
    max-width: 400px;
    margin: 70px auto;
    border-radius: 12px;
  }

  ${media.desktop} {
    display: block;
    padding: 10px;
    background: rgb(48, 53, 78);
    margin: 15px 0 0 0;
    border-radius: 12px;
    min-height: 23pc;
    width: 100%;

    h3 {
      font-size: 12px;
      color: lightgray;
      text-align: left;
      font-weight: bold;
      padding: 14px 27px;
    }

    h4 {
      display: inline-block;
      color: lightgray;
      border-bottom: 1px solid gray;
      font-size: 17px;
      font-weight: 400;
      padding: 0 0 7px 1px;
      width: 90%;
      color: #ffbb0f;
    }

    p {
      color: lightgray;
      padding: 0 28px;
      font-size: 12px;
      font-style: italic;
      min-height: 60px;
    }

    .comment {
      max-height: 100px;
      overflow: scroll;
    }

    .comment p {
      font-style: italic;
    }

    .comment-icon {
      display: inline-block;
      color: #ffbb0f;
      width: 8%;
      font-size: 14px;
    }

    .internal-comments {
      width: 90%;
      background-color: slategray;
      border-radius: 4px;
      margin: 0 26px;
      color: white;
      font-size: 14px;
      padding: 5px 10px;
      border: none;
    }

    .internal-comments::placeholder {
      color: white;
    }

    .save-internal-comment-disabled,
    .save-internal-comment,
    .internal-comments:focus {
      border: none;
      outline: none;
    }

    .save-internal-comment-disabled,
    .save-internal-comment {
      background: #ffbb0f;
      border: none;
      margin-left: 25px;
      padding: 1px 10px;
      font-size: 12px;
      border-radius: 20px;
    }

    .save-internal-comment-disabled {
      background: gray;
      color: black;
      cursor: auto;
    }

    .internal-comment-error,
    .internal-comment-success {
      color: #29a475;
      padding: 0;
      margin: 3px 16px;
      font-size: 23px;
      position: absolute;
    }

    .internal-comment-error {
      color: red;
    }
  }
`;

const LoadInfo = ({ loadSelected }) => {
  const { comments } = loadSelected;
  /* TODO: Este componente quedó por separado porque tenia la funcionalidad
   para guardar comentarios internos del point. Como no se estaba usando 
   se saca hasta que se pueda hacer correctamente.
  */
  return (
    <LoadInfoWrap>
      <h3>
        {loadSelected.fromCity} <FontAwesomeIcon icon={faAngleRight} /> {loadSelected.toCity}
      </h3>
      <FontAwesomeIcon icon={faComments} className="comment-icon" />
      <h4>Comentarios privados</h4>
      <p>{comments ? comments.privados : ''}</p>
      <FontAwesomeIcon icon={faComments} className="comment-icon" />
      <h4>Comentarios publicos</h4>
      <p>{comments ? comments.publicos : ''}</p>
    </LoadInfoWrap>
  );
};

LoadInfo.propTypes = {
  loadSelected: PropTypes.object.isRequired,
};

export default LoadInfo;

