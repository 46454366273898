import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import {
  FormLabel,
  FormControl,
  Input,
  Button,
  Select,
  Box, 
  Heading, 
  Text,
  Flex,
  Container,
} from '@chakra-ui/react';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import client from '../../../api/api';
import provinces from '../../Global/GeoCode/provinces';
import cities from '../../Global/GeoCode/cities';
import styled from 'styled-components';


const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;

  .icon {
    font-size: 30px;
    color: #ffbb0f;
    margin: 0 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  .title {
    line-height: 48px;
    text-align: right;
    padding: 30px 0 90px 0;
}
  }

  .text {
    padding: 0 0 10px 0;
    line-height: 25px;
    text-align: right;
    border-bottom: 1px solid #ffbb0f;
    margin-bottom: 6pc;
  }

  .text p {
    color: #a0a0a0;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileWrapper = styled.div`
  background: radial-gradient(black, transparent);
  width: 100%;
  height: 100vh;
  padding: 30px;


  form label {
    margin-top: 20px
  }

  .humber-btn {
    background: #ffbb0f;
    padding: 10px 33px;
    border-radius: 37px;
    float: right;
  }

  #btn1 {
    float: unset;
}

  .humber-btn:hover {
    background: #ffbb0f;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Esto todavia hay que darle una vuelta para poder validar todos los forms de esta manera
const validationSchema = Yup.object().shape({
  cityName: Yup.string().required(),
});

const Profile = () => {
  const [formStatus, setFormStatus] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [formValues, setFormValues] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    provinceName: '',
    cityName: ''
  });

  const { register, handleSubmit, formState: { errors, isSubmitting }, setValue } = useForm({
    defaultValues: formValues,
    resolver: yupResolver(validationSchema)
  });
  
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [citiesByProvince, setCitiesByProvince] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleProvinceChange = (event) => {
    const newProvince = event.target.value;
    setFormValues((prevState) => ({
      ...prevState,
      provinceName: newProvince,
      cityName: ''
    }));
    const newProvinceId = provinces.find(prov => prov.value === newProvince)?.id;
    const { list } = cities.find(({ id }) => id === newProvinceId) || { list: [] };
    setSelectedCity('');
    setValue("cityId", '');
    setValue('cityName', '');
    setSelectedProvince(newProvince);
    setCitiesByProvince(list);
  };

  const handleCityChange = (event) => {
    const newCity = event.target.value;
    const { id } = citiesByProvince.find(city => city.value === newCity);

    setFormValues((prevState) => ({
      ...prevState,
      cityName: newCity,
      cityId: id,
    }));
    
    setSelectedCity('');
    setValue("cityId", id);
    setValue('cityName', newCity);
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await client.endpoints.carrier.getUserData();
      const { username, email, phoneNumber, CarrierDetails: carrierDetails } = res.data;

      const { locationProvince, locationCity } = carrierDetails[0];
      const selectedProvinceData = cities.find(province => province.value === locationProvince);
      const citiesList = selectedProvinceData ? selectedProvinceData.list : [];

      const provinceId = provinces.find(province => province.value === locationProvince)?.id;
      const cityId = citiesList.find(city => city.value === locationCity)?.id;
      setCitiesByProvince(citiesList);

      setFormValues({
        username,
        email,
        phoneNumber,
        provinceName: locationProvince,
        cityName: locationCity,
        provinceId,
        cityId
      });
      await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${locationProvince}
        ,+${locationCity}&key=AIzaSyBnq1QcKZwrZYdEj2HuPsOKxQvrbm3_j5U`
      ).then(response => {
        setValue("username", username);
        setValue("email", email);
        setValue("phoneNumber", phoneNumber);
        setValue("provinceName", locationProvince);
        setValue("cityName", locationCity);
        setValue("provinceId", provinceId);
        setValue("cityId", cityId);
        setValue("geoCode", { 
          lat: Number(response.data.results[0].geometry.location.lat), 
          long: Number(response.data.results[0].geometry.location.lng)
        });
        
        setSelectedProvince(locationProvince);
      });
    };
    fetchData();
  }, []);

  const handleEdit = () => {
    setFormStatus(null)
  };

  const onSubmit = async (data) => {
    try {
      await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${data.provinceName}
        ,+${data.cityName}&key=AIzaSyBnq1QcKZwrZYdEj2HuPsOKxQvrbm3_j5U`
      ).then(async response => {
        setValue("geoCode", { 
          lat: Number(response.data.results[0].geometry.location.lat), 
          long: Number(response.data.results[0].geometry.location.lng)
        });
        const res = await client.endpoints.carrier.updateCarrier(data);
        console.log(res);
        if (res.status === 200) {
          setFormStatus('success');
          return;
        }
      });
      
    } catch (error) {
      console.log(error.response.data.error);
      setErrorMsg(error.response.data.error);
      setFormStatus('error');
    }
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  return (
    <TextWrapper>
      <LeftColumn>
        <div className='title'>
          <Text fontSize='6xl' color={'white'}>
            Ayudanos a mejorar
          </Text>
        </div>
        <div className='text'>
          <Text fontSize='2xl' color={'white'}>
            Ingresá un celular donde te podamos enviar notificaciones por ubicación y prioridad.
          </Text>
        </div>
        <div className='text'>
          <Text fontSize='2xl' color={'white'}>
            Ingresá una ubicación para recibir las mejores cargas en tu zona.
          </Text>
        </div>
      </LeftColumn>
      <ProfileWrapper>
        {formStatus === null ? (
          <form onSubmit={handleSubmit(onSubmit)} onKeyPress={e => handleOnKeyPress(e)}>
            <FormControl isInvalid={errors.name}>
              <FormLabel htmlFor='username'>Usuario</FormLabel>
              <Input
                id='username'
                placeholder='username'
                {...register('username')}
                value={formValues.username}
                onChange={event => handleInputChange(event)}
              />
              <p>{errors.username?.message}</p>

              <FormLabel htmlFor='email'>email</FormLabel>
              <Input
                id='email'
                type="email"
                placeholder='email'
                {...register("email")}
                value={formValues.email}
                onChange={(event) => handleInputChange(event)}
                
              />
              <p>{errors.email?.message}</p>

              <FormLabel htmlFor='phoneNumber'>Celular</FormLabel>
              <Input
                id='phoneNumber'
                type="tel"
                placeholder='phoneNumber'
                {...register("phoneNumber")}
                value={formValues.phoneNumber}
                onChange={(event) => handleInputChange(event)}
              />
              <p>{errors.phoneNumber?.message}</p>

              <FormLabel htmlFor='provinceName'>Provincia</FormLabel>
              <Select
                id='provinceName'
                value={selectedProvince}
                {...register('provinceName')}
                onChange={handleProvinceChange}
              >
                {provinces.map(prov => {
                  return <option key={prov.id} value={prov.value}>{prov.value}</option>;
                })}
              </Select>
              <p>{errors.provinceName?.message}</p>

              <FormLabel htmlFor='cityName'>Ciudad</FormLabel>
              <Select
                id='cityName'
                {...register('cityName')}
                onChange={handleCityChange}
              >
                {citiesByProvince.map(city => {
                  return <option key={city.id} value={city.value}>{city.value}</option>;
                })}
              </Select>
              <p>{errors.city?.message}</p>
            </FormControl>
            <Button className='humber-btn' mt={4} colorScheme='teal' isLoading={isSubmitting} type='submit'>
              Guardar
            </Button>
          </form>
        ) : formStatus === 'success' ? (
          <Box textAlign="center" py={10} px={6}>
            <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              ¡La información se guardó correctamente!
            </Heading>
            <Button id='btn1' className='humber-btn edit-btn' mt={4} colorScheme='teal' onClick={handleEdit} type='submit'>
              Volver a editar
            </Button>
          </Box>
        ) : (
          <Box textAlign="center" py={10} px={6}>
            <Box display="inline-block">
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bg={'red.500'}
                rounded={'50px'}
                w={'55px'}
                h={'55px'}
                textAlign="center">
                <CloseIcon boxSize={'20px'} color={'white'} />
              </Flex>
            </Box>
            <Heading as="h2" size="xl" mt={6} mb={2}>
              {errorMsg}
            </Heading>
            <Button id='btn1' className='humber-btn edit-btn' mt={4} colorScheme='teal' onClick={handleEdit} type='submit'>
              Volver a editar
            </Button>
          </Box>
        )}
      </ProfileWrapper>
      </TextWrapper>
  );
}

export default Profile;