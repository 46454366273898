/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import {
  Box,
  Flex,
  Text,
  Button,
  Divider,
  VStack,
  Input,
  IconButton,
  Switch,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
  Textarea,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Heading,
  useDisclosure,
  ModalCloseButton,
  ModalFooter,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { FaCamera } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import Async from 'react-select/async';
import styled from 'styled-components';
import { getSessionUserData } from '../../../libs/user';
import client from '../../../api/api';
import startIcon from '../../../img/StartIcon';
import endIcon from '../../../img/EndIcon';
import config from "../../../api/config";

const MapWrap = styled.div`
  width: 100%;
  height: 100vh;

  .leaflet-container .leaflet-marker-icon {
    margin-left: -12px !important;
    margin-top: -40px !important;
  }

  .active-load-start-pin {
    font-size: 30px;
    color: #b64dea;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }

  .active-load-end-pin {
    font-size: 30px;
    color: #000;
    background: transparent;
    text-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  }
`;

const AddLoadForm = () => {
  const toast = useToast();

  const { cuit } = getSessionUserData().data;
  const {
    endpoints: { shipper, generic },
  } = client;
  const processes = ['upstream', 'downstream'];
  const organizations = ['HB4 (Rizobacter)', 'Bioceres'];
  const requestingAreas = ['Comercial', 'Producción', 'Desarrollo'];
  const costCenters = {
    HB4_Comercial: '1051401111',
    HB4_Desarrollo: '-',
    HB4_Producción: '1051501211',
    Bioceres_Comercial: '1051401100',
    Bioceres_Desarrollo: '-',
    Bioceres_Producción: '1051501201',
  };
  const customLoadTypes = ['Insumo', 'Semilla', 'IMO'];
  const customTransportUnits = ['Equipo', 'Chasis', 'Utilitario', 'Batea', 'Escalable'];
  const customProductTypeNames = ['Trigo', 'Soja', 'Vicia'];
  const customUnits = ['kilogramos', 'toneladas', 'unidades'];

  const mapState = { lat: -36.0, lng: -64.0 };
  const initialMarker = L.latLng(-38.0, -57.55);
  const destinationMarker = L.latLng(-31.53, -68.53);
  const initialbounds = L.latLngBounds(initialMarker, destinationMarker);
  const initialPosition = [mapState.lat, mapState.lng];

  const [position, setPosition] = useState(initialPosition);
  const [productTypes, setProductTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const inputRef = useRef(null);

  const [originMarkerPosition, setOriginMarkerPosition] = useState('');
  const [destinationMarkerPosition, setDestinationMarkerPosition] = useState('');
  const [originId, setOriginId] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [distance, setDistance] = useState(0);
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialFocusModalRef = useRef(null);
  const [stopoverDestination, setStopoverDestination] = useState('');
  const [stopoverDestinationName, setStopoverDestinationName] = useState('');
  const [stopoverClient, setStopoverClient] = useState('');
  const [stopovers, setStopovers] = useState([]);

  const { BIOCERES_ID } = config;


  const asyncStyles = {
    menu: styles => ({
      ...styles,
      background: '#1c2023',
      border: '1px solid gray',
    }),
    control: baseStyles => ({
      ...baseStyles,
      borderColor: 'grey',
      background: '#1c2023',
      color: 'white !important',
    }),
    option: styles => ({
      ...styles,
      background: '#1c2023',
      color: 'white',
    }),
    valueContainer: styles => ({
      ...styles,
      color: 'white',
    }),
  };

  const labelStyles = {
    margin: 0,
    padding: '7px 10px',
    fontSize: '14px',
    color: 'gray',
  };

  const dividerColor = '#2d343a';

  const initialValues = {
    process: '',
    organization: '',
    requestingArea: '',
    costCenter: '',
    productType: '',
    customLoadType: '',
    grain: false,
    transportUnit: '',
    origin: '',
    destination: '',
    loadDate: '',
    client: '',
    quantity: '',
    unit: '',
    publicComment: '',
    privateComment: '',
  };

  const sideBoxForm = {
    width: '34pc',
    zIndex: 100,
    p: 30,
    bg: '#2d343a',
  };

  // Tamaño máximo de archivo de subir foto de plano: 5MB
  const MAX_FILE_SIZE = 5;

  const uploadFlatImage = useCallback(
    async flatImage => {
      // eslint-disable-next-line no-underscore-dangle
      const fileName = `imagenPlano_${getSessionUserData().data._id}_${new Date().getTime()}.jpg`;

      const newFile = new File([flatImage], fileName, {
        type: 'image/jpeg',
      });

      const formData = new FormData();
      formData.append('file', newFile);

      return generic.uploadFlatImage(formData);
    },
    [getSessionUserData]
  );

  const validateFlatImage = useCallback(value => {
    let error;

    if (value) {
      const sizeMb = value.size / (1024 * 1024);

      if (value.type.split('/')[0] !== 'image') {
        error = 'Debe seleccionar un archivo de imagen';
      } else if (sizeMb > MAX_FILE_SIZE) {
        error = 'El archivo no debe superar los 5 MB';
      }
    }

    return error;
  }, []);

  const validateRequired = useCallback(value => {
    let error;

    if (!value) {
      error = 'El campo es obligatorio';
    }

    return error;
  }, []);

  // Carga inicial de Productos custom (Trigo, Soja y Vicia).-
  const fetchProducts = useCallback(async () => {
    try {
      const { data } = await shipper.getProductTypes();

      const productsFiltered = data
        .flatMap(({ _id: loadType, tipoProducto }) => tipoProducto.map(tp => ({ ...tp, loadType })))
        .filter(products => customProductTypeNames.includes(products.label))
        .map(({ _id: value, label, loadType }) => ({ value, label, loadType }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setProductTypes(productsFiltered);
    } catch (error) {
      console.error(error);
    }
  }, [shipper]);

  useEffect(() => {
    if (productTypes.length === 0) {
      fetchProducts();
    }
  }, [productTypes, fetchProducts]);

  // Carga inicial de Unidades de medida custom (kilogramos, toneladas y unidades).-
  const fetchUnits = useCallback(async () => {
    try {
      const { data } = await shipper.getUnits();

      // Filtrar los objetos con las etiquetas deseadas
      const unitsFiltered = data
        .filter(u => customUnits.includes(u.label))
        .sort((a, b) => a.label.localeCompare(b.label));

      setUnits(unitsFiltered);
    } catch (error) {
      console.error(error);
    }
  }, [shipper]);

  useEffect(() => {
    if (units.length === 0) {
      fetchUnits();
    }
  }, [units, fetchUnits]);

  const placesOptions = useCallback(
    async inputValue => {
      if (inputValue) {
        try {
          const { data: places } = await shipper.placesAutoComplete(inputValue);
          const { data: delegate } = await generic.afipDelegate(cuit);

          if (delegate) {
            const { data: afipPlaces } = await shipper.afipCitiesByProducer(cuit, inputValue);
            return [
              ...afipPlaces.map(ap => ({
                label: `${ap.name}, ${ap.province.name}`,
                value: ap.code,
              })),
              ...places,
            ];
          }

          return places;
        } catch (e) {
          console.error(e);
        }
      }

      return null;
    },
    [shipper, generic]
  );

  const getGeocode = async (city, flag) => {
    if (city) {
      try {
        const res = await shipper.getMarkerGeocode(city);
        if (flag === 'origin') {
          setOriginMarkerPosition([
            res.data.results[0].geometry.location.lat,
            res.data.results[0].geometry.location.lng,
          ]);
        }
        if (flag === 'destination') {
          setDestinationMarkerPosition([
            res.data.results[0].geometry.location.lat,
            res.data.results[0].geometry.location.lng,
          ]);
        }
      } catch (error) {
        console.error('Error al buscar la ciudad, intentá de nuevo mas tarde.');
      }
    }
  };

  const showError = msgError => toast({ title: msgError, status: 'error', isClosable: true });

  const calculateDistance = useCallback(
    async (from, to) => {
      try {
        const { data } = await shipper.getCityDistances(from, to);
        return Number.parseInt(data, 10);
      } catch (error) {
        console.error(error);
        showError('Error al calcular distancia');
        throw error;
      }
    },
    [shipper]
  );

  const recalculateAllDistances = useCallback(
    async newStopovers => {
      // Si tiene paradas, recalcula todas las distancias intermedias.-
      if (newStopovers.length) {
        const promises = newStopovers.map(async (stopover, index, stopoversOrig) => {
          let newDistance;

          if (index === 0) {
            // 1er Tramo -> [Origen - Parada 1]
            newDistance = originId ? await calculateDistance(originId, stopover.destination) : 0;
          } else {
            // Tramos intermedios -> [Parada anterior - Parada actual]
            newDistance = await calculateDistance(
              stopoversOrig[index - 1].destination,
              stopover.destination
            );
          }

          // Último tramo -> [Última parada - Destino]
          if (index === stopoversOrig.length - 1) {
            setDistance(
              destinationId ? await calculateDistance(stopover.destination, destinationId) : 0
            );
          }

          return { ...stopover, distance: newDistance };
        });

        const stopoversRecalculated = await Promise.all(promises);
        setStopovers([...stopoversRecalculated]);
      } else {
        // Sino tiene paradas, calcula la distancia entre origen y destino (si éstos fueron cargados).-
        if (originId && destinationId) {
          setDistance(await calculateDistance(originId, destinationId));
        }

        setStopovers([...newStopovers]);
      }
    },
    [originId, destinationId, calculateDistance]
  );

  useEffect(() => {
    // Si cambia origen o destino, y tienen contenido.-
    if (originId && destinationId) {
      recalculateAllDistances(stopovers);
    }
  }, [originId, destinationId, recalculateAllDistances]);

  const clearForm = actions => {
    actions.resetForm();

    originRef.current.select.select.clearValue();
    destinationRef.current.select.select.clearValue();
    setOriginMarkerPosition('');
    setDestinationMarkerPosition('');
    setOriginId('');
    setDestinationId('');
    setDistance(0);

    setStopoverDestination('');
    setStopoverDestinationName('');
    setStopoverClient('');
    setStopovers([]);
  };

  const buildLoadData = useCallback(
    async values => {
      const {
        flatImage,
        process,
        organization,
        requestingArea,
        costCenter,
        customLoadType,
        grain,
        transportUnit,
        productType,
        unit,
        customId,
        ...rest
      } = values;

      let planImageName;

      if (flatImage) {
        const resUpload = await uploadFlatImage(flatImage);
        planImageName = resUpload.data.result.files.file[0].name;
      }

      const { loadType } = productTypes.find(pt => pt.value === productType);
      const fareType = units.find(u => u.value === unit)?.label === 'toneladas' ? 'TN' : 'viaje';

      return {
        distance,
        duration: 8,
        requestedTruckers: 1,
        status: 'close',
        unit,
        loadType,
        productType,
        fareType,
        fare: 0,
        ...(planImageName && { planImageName }),
        ...rest,
        custom: {
          process,
          organization,
          requestingArea,
          costCenter,
          customLoadType,
          grain,
          transportUnit,
          customId
        },
        ...(stopovers.length && {
          // eslint-disable-next-line no-shadow
          stopovers: stopovers.map(({ destination, client, distance }) => ({
            destination,
            client,
            distance,
          })),
        }),
      };
    },
    [distance, productTypes, units, stopovers, uploadFlatImage]
  );

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        const loadData = await buildLoadData(values);
        // console.log('loadData ==>', loadData);

        const { data } = await shipper.addLinkedLoad(loadData, BIOCERES_ID);

        toast({
          title: 'Carga creada con éxito',
          description: `Id: ${data?.customId}`,
          status: 'success',
          isClosable: true,
        });

        clearForm(actions);
      } catch (error) {
        console.error(error);
        showError(`Error al crear carga ${error.response?.data?.errors[0]?.msg || ''}`);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [buildLoadData, clearForm, shipper]
  );

  const FormObserver = ({ props }) => {
    const { values } = useFormikContext();

    useEffect(() => {
      const { organization, requestingArea } = values;

      if (organization && requestingArea) {
        const costCenterValue = costCenters[`${organization.split(' ')[0]}_${requestingArea}`];
        props.setFieldValue('costCenter', costCenterValue, false);
      }
    }, [values]);

    return null;
  };

  const onChangeStopoverDestination = useCallback(
    async opt => {
      setStopoverDestination(opt.value);
      setStopoverDestinationName(opt.label.split(',')[0]);
    },
    [originId, destinationId]
  );

  const onSaveStopover = () => {
    stopovers.push({
      destination: stopoverDestination,
      destinationName: stopoverDestinationName,
      client: stopoverClient,
    });
    recalculateAllDistances(stopovers);

    setStopoverDestination('');
    setStopoverDestinationName('');
    setStopoverClient('');

    onClose();
  };

  const onDeleteStopover = index => {
    stopovers.splice(index, 1);
    recalculateAllDistances(stopovers);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {props => (
        <Flex>
          <Form>
            <Flex>
              <FormObserver props={props} />
              <Box width="16pc" height="100vh">
                <Field name="process" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl isRequired isInvalid={form.errors.process && form.touched.process}>
                      <FormLabel style={labelStyles}>Proceso</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {processes.map(option => (
                            <Radio {...field} size="sm" key={option} value={option} ml={3}>
                              {`${option.charAt(0).toUpperCase()}${option.slice(1)}`}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.process}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="organization" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.organization && form.touched.organization}
                    >
                      <FormLabel style={labelStyles}>Organización</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {organizations.map(option => (
                            <Radio {...field} size="sm" key={option} value={option} ml={3}>
                              {option}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.organization}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="requestingArea" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.requestingArea && form.touched.requestingArea}
                    >
                      <FormLabel style={labelStyles}>Sector solicitante</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {requestingAreas.map(option => (
                            <Radio {...field} size="sm" key={option} value={option} ml={3}>
                              {option}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.requestingArea}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="costCenter">
                  {({ field }) => (
                    <FormControl>
                      <FormLabel style={labelStyles} color="white">
                        Centro de costo: {field.value || ''}
                      </FormLabel>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="productType" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.productType && form.touched.productType}
                    >
                      <FormLabel style={labelStyles}>Cultivo</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {productTypes.map(product => (
                            <Radio
                              {...field}
                              size="sm"
                              key={product.value}
                              value={product.value}
                              ml={3}
                            >
                              {product.label}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.productType}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="customLoadType" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.customLoadType && form.touched.customLoadType}
                    >
                      <FormLabel style={labelStyles}>Tipo de carga</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {customLoadTypes.map(option => (
                            <Radio {...field} size="sm" key={option} value={option} ml={3}>
                              {option}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.customLoadType}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="grain">
                  {({ field }) => (
                    <FormControl display="flex" alignItems="center">
                      <FormLabel style={labelStyles}>Granel</FormLabel>
                      <Switch {...field} m="0" isChecked={field.value} />
                    </FormControl>
                  )}
                </Field>
                <Divider borderColor={dividerColor} m={0} />
                <Field name="transportUnit" validate={validateRequired}>
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.transportUnit && form.touched.transportUnit}
                    >
                      <FormLabel style={labelStyles}>Unidad de transporte</FormLabel>
                      <RadioGroup {...field} ml="20px">
                        <Flex direction="column">
                          {customTransportUnits.map(option => (
                            <Radio {...field} size="sm" key={option} value={option} ml={3}>
                              {option}
                            </Radio>
                          ))}
                        </Flex>
                      </RadioGroup>
                      <FormErrorMessage>{form.errors.transportUnit}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>

              <Box {...sideBoxForm} position="relative">
                <Box position="absolute" right="-100px" top="20px">
                  <Flex>
                    <Field name="flatImage" validate={validateFlatImage}>
                      {({ form }) => (
                        <FormControl
                          pl={18}
                          isInvalid={form.errors.flatImage && form.touched.flatImage}
                        >
                          <label htmlFor="uploadImg">
                            <IconButton
                              htmlFor="uploadImg"
                              title="Subir foto de plano"
                              aria-label="Tomar foto"
                              icon={<FaCamera />}
                              rounded="full"
                              colorScheme="blue"
                              bg="#ffbb0f"
                              size="lg"
                              boxShadow="6px 5px 6px 0px rgba(0, 0, 0, 0.3)"
                              onClick={() => inputRef.current.click()}
                            />
                            <Input
                              ref={inputRef}
                              id="uploadImg"
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={({ target: { files } }) =>
                                form.setFieldValue('flatImage', files[0])
                              }
                            />
                          </label>
                          <FormErrorMessage>{form.errors.flatImage}</FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>
                </Box>
                <VStack>
                  <Field name="origin" validate={validateRequired}>
                    {({ field, form }) => (
                      <FormControl isRequired isInvalid={form.errors.origin && form.touched.origin}>
                        <div className="humber-dropdown">
                          <FormLabel style={labelStyles}>Origen</FormLabel>
                          <Async
                            ref={originRef}
                            styles={asyncStyles}
                            placeholder="Ingresá ciudad"
                            className="dropdown"
                            defaultOptions
                            loadOptions={placesOptions}
                            onBlur={() => {
                              form.setFieldTouched('origin', true);
                              setOriginId(field.value);
                            }}
                            onChange={opt => {
                              form.setFieldValue('origin', opt?.value || '');
                              if (opt) {
                                getGeocode(opt.label.split(',')[0], 'origin');
                              }
                            }}
                          />
                        </div>
                        <FormErrorMessage>{form.errors.origin}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Box w="100%" textAlign="right">
                    <Text mr={4} color="blue.500" cursor="pointer" onClick={onOpen}>
                      Agregar parada +
                    </Text>
                  </Box>
                  {stopovers.length > 0 && (
                    <Flex width="100%" justifyContent="center">
                      <Box
                        p={4}
                        borderRadius={6}
                        flexDirection="column"
                        bg="#323d46"
                        width="100%"
                        maxWidth="600px"
                      >
                        {stopovers.map((stopover, index) => (
                          <div
                            key={`${stopover.destinationName}${stopover.client}`}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              borderBottom: '1px solid gray',
                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <Text fontSize="xs">{stopover.destinationName}</Text>
                              <Text mb={2} fontSize="xs">
                                Cliente: {stopover.client}
                              </Text>
                            </div>
                            {/* <Text
                              fontSize="xl"
                              cursor="pointer"
                              onClick={() => onDeleteStopover(index)}
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                            >
                              x
                            </Text> */}
                            <Tooltip label="Quitar parada">
                              <IconButton
                                aria-label="Delete stopover"
                                isRound
                                variant="outline"
                                size="xs"
                                color="blue.500"
                                borderColor="blue.500"
                                icon={<MdClose />}
                                onClick={() => onDeleteStopover(index)}
                              />
                            </Tooltip>
                          </div>
                        ))}
                      </Box>
                    </Flex>
                  )}
                  <Field name="destination" validate={validateRequired}>
                    {({ field, form }) => (
                      <FormControl
                        isRequired
                        isInvalid={form.errors.destination && form.touched.destination}
                      >
                        <div className="humber-dropdown">
                          <FormLabel style={labelStyles}>Destino</FormLabel>
                          <Async
                            ref={destinationRef}
                            styles={asyncStyles}
                            placeholder="Ingresá ciudad"
                            colorScheme="blackAlpha"
                            className="dropdown"
                            defaultOptions
                            loadOptions={placesOptions}
                            onBlur={() => {
                              form.setFieldTouched('destination', true);
                              setDestinationId(field.value);
                            }}
                            onChange={opt => {
                              form.setFieldValue('destination', opt?.value || '');
                              if (opt) {
                                getGeocode(opt.label.split(',')[0], 'destination');
                              }
                            }}
                          />
                        </div>
                        <FormErrorMessage>{form.errors.destination}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Flex justifyContent="space-between" flexDirection="row" w="100%">
                    <Box>
                      <Field name="quantity" w="14pc" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            isRequired
                            isInvalid={form.errors.quantity && form.touched.quantity}
                          >
                            <FormLabel style={labelStyles}>Cantidad de producto</FormLabel>
                            <NumberInput
                              {...field}
                              w="195px"
                              min={1}
                              max={1000000}
                              onChange={val => form.setFieldValue(field.name, val)}
                            >
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="loadDate" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl
                            m="0 10px 0 0"
                            isRequired
                            isInvalid={form.errors.loadDate && form.touched.loadDate}
                          >
                            <FormLabel style={labelStyles}>Fecha y hora de carga</FormLabel>
                            <Input {...field} type="datetime-local" w="195px" />
                            <FormErrorMessage>{form.errors.loadDate}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box>
                      <Field name="unit" validate={validateRequired}>
                        {({ field, form }) => (
                          <FormControl isRequired isInvalid={form.errors.unit && form.touched.unit}>
                            <FormLabel style={labelStyles}>Unidades</FormLabel>
                            <Select {...field} w="195px" placeholder="Seleccioná...">
                              {units.map(unitName => (
                                <option key={unitName.value} value={unitName.value}>
                                  {unitName.label}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>{form.errors.unit}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="client">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel style={labelStyles}>Cliente destino</FormLabel>
                            <Input w="195px" {...field} />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>
                  <Flex justifyContent="left" width="100%">
                    <Box>
                      <Field name="customId">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel style={labelStyles}>Custom ID</FormLabel>
                            <Input w="195px" {...field} />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-between" w="100%">
                    <Box p="10px" w="14pc">
                      <Field name="publicComment">
                        {({ field }) => (
                          <FormControl>
                            <Text mb="8px">Comentarios públicos</Text>
                            <Textarea
                              {...field}
                              id="publicComment"
                              placeholder="Este comentario lo van a ver todos los interesados en la carga..."
                              size="sm"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box p="10px" w="14pc">
                      <Field name="privateComment">
                        {({ field }) => (
                          <FormControl>
                            <Text mb="8px">Comentarios privados</Text>
                            <Textarea
                              {...field}
                              placeholder="Este comentario lo van a ver todos los interesados en la carga..."
                              size="sm"
                            />
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </Flex>
                  <Button
                    mt={4}
                    bg="#ffbb0f"
                    alignSelf="end"
                    colorScheme="blue"
                    isLoading={props.isSubmitting}
                    type="submit"
                  >
                    Subir carga
                  </Button>
                </VStack>
              </Box>
            </Flex>
          </Form>
          <Flex w="60%">
            <MapWrap>
              <Map
                center={position}
                zoom={13}
                bounds={initialbounds}
                scrollWheelZoom={false}
                style={{
                  width: '100%',
                  height: '100%',
                  zIndex: 10,
                }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {originMarkerPosition && (
                  <Marker position={originMarkerPosition} icon={startIcon} />
                )}
                {destinationMarkerPosition && (
                  <Marker position={destinationMarkerPosition} icon={endIcon} />
                )}
              </Map>
            </MapWrap>
          </Flex>

          <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusModalRef}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader className="header">
                <Heading as="h6" size="md">
                  Parada
                </Heading>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Form>
                  <VStack spacing={5}>
                    <Field name="stopoverDestination">
                      {({ form }) => (
                        <FormControl isRequired>
                          <div className="humber-dropdown">
                            <FormLabel style={labelStyles}>Destino</FormLabel>
                            <Async
                              ref={initialFocusModalRef}
                              styles={asyncStyles}
                              placeholder="Ingresá ciudad"
                              className="dropdown"
                              defaultOptions
                              loadOptions={placesOptions}
                              onBlur={() => form.setFieldTouched('stopoverDestination', true)}
                              onChange={onChangeStopoverDestination}
                            />
                          </div>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="stopoverClient">
                      {() => (
                        <FormControl>
                          <FormLabel style={labelStyles}>Cliente</FormLabel>
                          <Input
                            value={stopoverClient}
                            onChange={({ target: { value } }) => setStopoverClient(value)}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </VStack>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button mt={4} colorScheme="blue" bg="#ffbb0f" onClick={onSaveStopover}>
                  Guardar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </Formik>
  );
};
export default AddLoadForm;
