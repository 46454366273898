import React, { useContext, useState, useEffect } from 'react';
import { __RouterContext as RouterContext } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import BgGeoLocation from './img/porqueImg/geolocalizacion.png';
import Gestion from './img/porqueImg/gestion.png';
import Favoritos from './img/porqueImg/favoritos.png';
import Flota from './img/porqueImg/flota.png';
import ActiveLoadsImg from './img/cargas-activas.png';
import SearchLoadsImg from './img/buscar-viajes.png';
import Login from '../Login';
import SignUp from '../SignUp';
import './landing-page.css';
import Logo from './humber-logo.png';
import { Box, HStack } from '@chakra-ui/react';

const LandingPage = () => {
  const [shipperSelected, setShipperSelected] = useState(true);
  const [carrierSelected, setCarrierSelected] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const { location } = useContext(RouterContext);

  const { state } = location || {};
  const { username, role } = state || {};

  useEffect(() => {
    if (showSignUp) {
      setShowSignUp(true);
    }
  }, [showSignUp]);

  useEffect(() => {
    if (showLogin) {
      setShowSignUp(false);
    }
  }, [showLogin]);

  return (
    <>
      <div className="landing-page">
        <Box h={'31.33px'} bg='#fcc007'>
          <HStack w='80%' m='auto' maxW={'1080px'} fontSize='12px' pt='9px' pb='9px' lineHeight={'12px'} >
            <Box
              id="et-info-phone" > 11 2642 5830</Box>

            <a href="mailto:humber@humber.com.ar"><span id="et-info-mail">humber@humber.com.ar</span></a>
          </HStack>
        </Box>
        <div className="section-one">
          <div className="landing-logo " style={{ animation: `fadeIn 5s` }}>
            <a href="/">
              <img className="logo-img" src={Logo} alt="humber logo" />
            </a>
          </div>
          <div className="phrase-wrapper">
            <h1 className="phrase">Simplificando la logística,</h1>
            <h2 className="sub-phrase animation">conectando personas</h2>
          </div>

        </div>
        {showLogin ? (
          <div className="login-landing-wrapper">
            <Login username={username} role={role} setShowSignUp={setShowSignUp} />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default LandingPage;
