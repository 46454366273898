import React from 'react';
import { bool, func, oneOf, PropTypes, object } from 'prop-types';

import ModalRegular from '../../../../ui/modals';
import GenerateGasOrder from '../../Gasoil/GenerateGasOrder';
import GasOrderList from '../../Gasoil/GasOrderList';

const GasoilModal = ({ openModal, setOpenModal, typeContentModal, shipmentId, historyItem }) => {
  return (
    <ModalRegular visible={openModal} onActionHandler={() => setOpenModal(false)}>
      <>
        {typeContentModal === 'generateGasOrder' ? (
          <GenerateGasOrder shipmentId={shipmentId} />
        ) : (
          <GasOrderList historyItem={historyItem} />
        )}
      </>
    </ModalRegular>
  );
};

GasoilModal.propTypes = {
  openModal: bool.isRequired,
  setOpenModal: func.isRequired,
  typeContentModal: oneOf(['generateGasOrder', 'gasOrderList', '']).isRequired,
  shipmentId: PropTypes.string,
  historyItem: PropTypes.objectOf(object),
};

GasoilModal.defaultProps = {
  historyItem: {},
  shipmentId: '',
};

export default GasoilModal;
