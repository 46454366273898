import React from 'react';
import styled from 'styled-components';
import { bool, func, oneOf, PropTypes, object } from 'prop-types';
import InputProvider from './InputProvider';
import DocumentStatus from './DocumentStatus';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const CardWrap = styled.div`
  position: relative;
  background: #1f1e29;
  width: 100%;
  min-width: 22pc;
  margin: 26px 0;
  padding: 5px 12px;

  h2 {
    color: white;
    margin: 10px;
  }
  h3 {
    color: white;
    font-size: 20px;
    margin-left: 3px;
  }

  small {
    color: white;
  }

  p {
    color: white;
    width: 90%;
    font-size: 23px;
    text-align: left;
    white-space: initial;
    overflow: hidden;
  }

  .btn-edit-round {
    position: absolute;
    right: 8px;
    top: 10px;
    width: 33px;
    border: none;
    background: transparent;
    padding: 0;
  }

  .btn-edit-round img {
    width: 34px;
  }

  .btn-edit-round:focus {
    outline: none;
  }

  ${media.tablet} {
    border-radius: 20px;
  }

  ${media.desktop} {
    width: 27pc;
    margin: 20px;
    height: 26pc;
  }
`;

const CardContent = styled.div`
  padding: 25px 0 0 12px;
  height: 15pc;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  border-bottom: 1px solid gray;
`;

const SubTitle = styled.div`
  background: #ffbb0f;
  display: flex;
  width: 100%;
  margin-left: -10px;
  padding: 10px 0;

  p {
    color: gray;
    font-size: 16px;
    font-weight: 700;
  }

  img {
    margin: 4px 20px 0 17px;
    width: 25px;
    height: 42px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 3px;

  .expiration {
    display: flex;
    flex-direction: column;
    margin-top: --3px;
  }
`;

const Card = ({
  title,
  fields,
  inputFields,
  editCardInfo,
  setEditCardInfo,
  img,
  uploadImgBtnLabel,
  billingData,
  setImg,
  setErrorMsg,
}) => {
  return (
    <CardWrap>
      <h2>{title}</h2>
      {billingData ? (
        <SubTitle>
          <img src="../../../images/icons/money.png" alt="money" />
          <p>Ingresá bien estos datos así podes cobrar en tiempo y forma</p>
        </SubTitle>
      ) : (
        ''
      )}
      <button
        type="button"
        className="btn-edit-round"
        onClick={() => setEditCardInfo(!editCardInfo)}
      >
        <img src="../../../images/icons/btn-edit-round.png" alt="btn-edit-round" />
      </button>
      <CardContent>
        {editCardInfo ? (
          <>
            {inputFields.map(e => {
              return (
                <span key={e.label}>
                  <InputProvider
                    label={e.label}
                    id={e.label}
                    type={e.type}
                    defaultValue={e.value}
                    setValue={e.func}
                    errorMsg=""
                    disabled={e.disabled}
                    setErrorMsg={setErrorMsg}
                  />
                </span>
              );
            })}
          </>
        ) : (
          <>
            {fields.map(field => {
              return (
                <>
                  <small>{field.label}</small>
                  <p>{field.value}</p>
                </>
              );
            })}
          </>
        )}
      </CardContent>
      {!billingData ? (
        <>
          <h3>{uploadImgBtnLabel}</h3>
          <Footer>
            <div className="expiration">
              <small>Vencimiento</small>
              <small>27/09/2022</small>
            </div>
            <DocumentStatus
              documentData={img}
              uploadImgBtnLabel={uploadImgBtnLabel}
              setImg={setImg}
            />
          </Footer>
        </>
      ) : (
        ''
      )}
    </CardWrap>
  );
};

Card.propTypes = {
  uploadImgBtnLabel: bool.isRequired,
  setEditCardInfo: func.isRequired,
  editCardInfo: bool.isRequired,
  setImg: func.isRequired,
  fields: oneOf([]),
  inputFields: oneOf([]),
  img: PropTypes.objectOf(object),
  title: PropTypes.string,
  billingData: PropTypes.objectOf(object),
  setErrorMsg: func.isRequired,
};

Card.defaultProps = {
  billingData: {},
  fields: [],
  inputFields: [],
  img: {},
  title: '',
};

export default Card;
