/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import 'moment/locale/es';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faSort } from '@fortawesome/free-solid-svg-icons';
import Shipment from '../../Shipment';
import client from '../../../api/api';
import PointRecordTable from '../RecordTablePoint/PointRecordTable';
import './point-record-view.css';
import toCurrency from '../../../libs/currencyUtils';

const PointRecordView = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [withShipment, setWithShipment] = useState(true);
  const columns = useMemo(() => [
    {
      Header: () => (
        <div
          title="Incluir cargas sin viajes"
          tabIndex={0}
          className="with-shipments"
          role="button"
          onClick={() => {
            setWithShipment(!withShipment);
          }}
        >
          <FontAwesomeIcon className="icon" icon={faSort} />
        </div>
      ),
      id: 'expander',
      Cell: ({ row }) => {
        const { original } = row;
        const { shipments } = original;

        return (
          <span {...row.getToggleRowExpandedProps()}>
            {shipments.length ? (
              <>
                {row.isExpanded ? (
                  <div className="opened">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                ) : (
                  <div className="open">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                )}
              </>
            ) : (
              <div className="no-shipments">
                <p>Sin viajes</p>
              </div>
            )}
          </span>
        );
      },
    },
    {
      Header: 'Fecha',
      accessor: ({ loadDate }) => {
        return moment(loadDate)
          .locale('es')
          .format('DD-MMM-YYYY');
      },
      id: 'loadDate',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Tipo de carga',
      accessor: 'loadType',
      id: 'loadType',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Producto',
      accessor: 'productType',
      id: 'productType',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Origen',
      accessor: 'fromCity',
      id: 'fromCity',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Destino',
      accessor: 'toCity',
      id: 'toCity',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Distancia',
      accessor: 'distance',
      disableFilters: true,
      disableSorting: true,
    },
    {
      Header: 'Tarifa',
      accessor: ({ fare, fareType }) => {
        return `$${toCurrency(fare)} x ${fareType} +IVA`;
      },
      disableFilters: true,
      disableSorting: true,
    },
  ]);

  const fetchAction = async filters => {
    const resp = await client.endpoints.shipper.getLoadsHistory(filters, withShipment);
    setIsLoading(false);
    setData(resp.data.docs);
    setPageCount(Math.ceil(resp.data.count / filters.limit));
  };

  const fetchShipments = useCallback(params => {
    // TODO - cambier filters por where o algo por el estilo
    const { filters = {}, sortBy = {}, pageIndex = 0, pageSize = 10 } = params || {};
    const fetch = async () => {
      setIsLoading(true);
      let fetchFilters = {
        ...filters,
        ...{ limit: pageSize, page: pageIndex + 1 },
      };
      if (sortBy && sortBy['0']) {
        const sorting = `${sortBy[0].id}:${sortBy[0].desc ? 'desc' : 'asc'},`;
        fetchFilters = { ...fetchFilters, ...{ sorting } };
      }
      if (!sortBy['0']) {
        fetchAction(fetchFilters);
      } else {
        await fetchAction(fetchFilters);
      }
    };
    fetch();
  }, []);

  const renderRowSubComponent = useCallback(({ row }) => {
    const { original } = row;
    const { shipments } = original;
    return shipments ? (
      <>
        {shipments.map(shipment => {
          return <Shipment key={shipment._id} shipment={shipment} />;
        })}
      </>
    ) : (
      'ESTA CARGA NO TIENE VIAJES'
    );
  }, []);

  return (
    <div className="point-record-view-wrapper">
      <PointRecordTable
        pageSizeOptions={[20, 30, 50, 100, 200, 500]}
        columns={columns}
        data={data}
        fetchData={fetchShipments}
        loading={isLoading}
        pageCount={pageCount}
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
};

export default PointRecordView;
