import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { PropTypes, object } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import TruckIcon from './markers';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const MapWrap = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;

    .leaflet-container {
      height: 100vh;
      width: 100%;
    }

    .leaflet-container .leaflet-marker-icon {
      margin-left: -12px !important;
      margin-top: -40px !important;
    }
  }
`;

const TruckersMap = ({ truckerSelected }) => {
  const defaultPosition = [-34.603722, -58.381592];

  return (
    <MapWrap>
      <Map
        center={truckerSelected ? truckerSelected.geoCode : defaultPosition}
        scrollWheelZoom={false}
        zoom={6}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {truckerSelected ? (
          <Marker
            icon={TruckIcon}
            position={
              truckerSelected.lastGeolocationReport
                ? truckerSelected.lastGeolocationReport.geolocation
                : truckerSelected.geoCode
            }
          >
            <Popup>
              {truckerSelected.lastGeolocationReport?.geolocation === ''
                ? 'No reporta geolocalización'
                : ''}
              <br />
              <b>Nombre: </b> {truckerSelected.truckerName} <br />
              <b>CUIT: </b> {truckerSelected.truckerCuit} <br />
              <b>Patente: </b> {truckerSelected.truck?.truckPatent} <br />
              <b>Cel: </b> {truckerSelected.truckerCellPhone} <br />
              {truckerSelected.lastGeolocationReport !== '' ? (
                <>
                  <b>Geolocalización reportada</b>
                  <br />
                  {moment(truckerSelected.lastGeolocationReport?.timestamp).format('DD-MMM-YYYY')}
                  <br />
                  {moment(truckerSelected.lastGeolocationReport?.timestamp).format('HH:mm')}
                  hs.
                </>
              ) : (
                <>
                  <b>Geolocalización desactualizada</b>
                  <br />
                </>
              )}
            </Popup>
          </Marker>
        ) : (
          ''
        )}
      </Map>
    </MapWrap>
  );
};

TruckersMap.propTypes = {
  truckerSelected: PropTypes.objectOf(object),
};

TruckersMap.defaultProps = {
  truckerSelected: {},
};

export default TruckersMap;
