import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';

const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />);

const startIcon = divIcon({
  className: 'active-load-start-pin ',
  html: iconMarkup,
  shadowUrl: null,
  shadowSize: [0, 0],
});

export default startIcon;
