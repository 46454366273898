import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';
import styled from 'styled-components';
import TruckIconImg from './truck-icon.png';

const IconWrap = styled.div`
  img {
    width: 36px;
    margin-top: 34px;
  }
`;

const iconMarkup = renderToStaticMarkup(
  <IconWrap>
    <img src={TruckIconImg} className="truck-icon" alt="icon" />
  </IconWrap>
);

const TruckIcon = divIcon({
  className: 'truck-icon',
  html: iconMarkup,
  shadowUrl: null,
  shadowSize: [0, 0],
});

export default TruckIcon;
