import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Switch,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  TableContainer,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ListItem,
  UnorderedList,
  Input,
  useToast,
} from '@chakra-ui/react';
import {
  faCrown,
  faMapMarkedAlt,
  faEdit,
  faLock,
  faUserLock,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

import TruckersMap from './TruckersMap';
import EditTruckerModal from './EditTruckerModal';
import client from '../../../api/api';
import InfoBar from '../../InfoBar/InfoBar';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .icon,
  .all-lock-icon {
    color: #3182ce;
    width: 1rem;
    height: 1rem;
    margin: 0 14px;
  }

  .all-lock-icon {
    margin: 0 0 0 8px;
  }

  .fleet-lock-icon {
    color: #3182ce;
    width: 30px;
    height: 25px;
    margin: 0 14px;
  }
`;

const CenteredContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ajusta la altura según tus necesidades */
`;

const TableWrap = styled.div`
  flex: 1 0 25%;

  a {
    padding: 10px 0 10px 10px;
    font-size: 12px;
    color: #3182ce;
  }

  img {
    width: 20px;
  }

  .premium {
    color: #ffbb0f;
  }

  .no-premium {
    color: gray;
  }

  .on-the-road {
    color: #ff7979;
    margin-bottom: 5px;
  }

  .trucker-available {
    color: #19c482;
    margin-bottom: 5px;
  }

  .card-number {
    color: #a7a7a7;
    padding: 5px 0 0 1px;
  }
`;

const TruckersMapContainer = styled.div`
  flex: 1;
  z-index: 0;
`;

const TableControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MyFleet = () => {
  const toast = useToast();
  const [truckerSelected, setTruckerSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [truckers, setTruckers] = useState([]);
  const [truckersFiltered, setTruckersFiltered] = useState([]);
  const [filterTrucker, setFilterTrucker] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isTruckerPermissionModalOpen, setIsTruckerPermissionModalOpen] = useState(false);
  const [isAllPermissionModalOpen, setIsAllPermissionModalOpen] = useState(false);
  const [isWappModalOpen, setIsWappModalOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [truckerPermissions, setTruckerPermissions] = useState([]);
  const [carrierName, setCarrierName] = useState('');
  const [truckersWappSended, setTruckerWappSended] = useState([]);
  const [truckersWappError, setTruckerWappError] = useState([]);

  const playstorLink = 'https://humber.page.link/dQkA';

  const wappMessageText = `El transportista ${carrierName} te solicita que descargues la aplicación de Humber para que puedas tener prioridad en las cargas. ${playstorLink}`;

  const sendWapp = async () => {
    try {
      setIsLoading(true);
      const userIds = truckers.map(trucker => trucker._id);
      const res = await client.endpoints.carrier.sendWappTrucker(wappMessageText, userIds);
      setTruckerWappSended(res.data.sended);
      setTruckerWappError(res.data.errors);

      const taostProps = {
        title: <Text ml="10px">Mensajes enviados correctamente</Text>,
        status: 'success',
        duration: 9000,
        isClosable: true,
      };

      return toast(taostProps);
    } catch (error) {
      setErrorMsg(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Carga inicial.-
  const fetchTruckers = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.endpoints.carrier.getTruckers();
      setTruckers(data.sort((a, b) => b.onTheRoad - a.onTheRoad));
    } catch (error) {
      console.error(error);
      setErrorMsg(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        setLoading(true);
        const { data } = await client.endpoints.carrier.getPermissions();
        setPermissions(data);
        setTruckerPermissions(data.map(permission => ({ ...permission, checked: false })));
      } catch (error) {
        console.error(error);
        setErrorMsg(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
    fetchTruckers();
  }, [fetchTruckers]);

  useEffect(() => {
    if (truckers) {
      setTruckersFiltered(
        filterTrucker
          ? truckers.filter(trucker =>
              String(trucker.truckerName)
                .toLowerCase()
                .includes(filterTrucker.toLowerCase())
            )
          : truckers
      );
    }
  }, [filterTrucker, truckers]);

  useEffect(() => {
    if (truckersFiltered.length) {
      setTruckerSelected(truckersFiltered[0]);
    }
  }, [truckersFiltered]);

  // Switch de permisos.-
  const handleCheckboxChange = useCallback(
    permission => {
      const updatedPermissions = truckerPermissions.map(item => {
        if (item.id === permission.id) {
          // eslint-disable-next-line no-param-reassign
          item.checked = !item.checked;
        }
        return item;
      });
      setTruckerPermissions(updatedPermissions);
    },
    [truckerPermissions]
  );

  // Permisos Generales.-
  const openAllPermissionModal = () => {
    setTruckerPermissions(permissions.map(permission => ({ ...permission, checked: false })));
    setIsAllPermissionModalOpen(true);
  };

  const closeAllPermissionModal = () => {
    setIsAllPermissionModalOpen(false);
  };

  const closeWappModal = () => {
    setIsWappModalOpen(false);
  };

  const updateAllFleetPermissions = useCallback(async () => {
    try {
      setIsLoading(true);

      await Promise.all(
        truckers.map(async trucker => {
          const { _id } = trucker;

          const permissionsToSave = truckerPermissions
            .filter(permission => permission.checked)
            .map(permission => permission.id);

          await client.endpoints.carrier.updatePermissions(_id, permissionsToSave);
          return { _id, updatedPermissions: permissionsToSave };
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [truckerPermissions]);

  // Permisos particulares.-
  const openTruckerPermissionModal = async trucker => {
    try {
      const { _id } = trucker;
      setTruckerSelected(trucker);
      const res = await client.endpoints.carrier.getPermissionsByUserId(_id);

      const updatedTruckerPermissions = permissions.map(permission => ({
        ...permission,
        checked: res.data.some(item => item.id === permission.id),
      }));

      setTruckerPermissions(updatedTruckerPermissions);
      setIsTruckerPermissionModalOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const closeTruckerPermissionModal = () => {
    setIsTruckerPermissionModalOpen(false);
  };

  const updateTruckerPermissions = useCallback(async () => {
    try {
      setIsLoading(true);

      const { _id } = truckerSelected;
      const permissionsToSave = truckerPermissions
        .filter(permission => permission.checked)
        .map(permission => permission.id);

      await client.endpoints.carrier.updatePermissions(_id, permissionsToSave);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [truckerSelected, truckerPermissions]);

  // Edición.-
  const onOpenModalHandle = useCallback(e => {
    e.preventDefault();
    setOpenModal(true);
  }, []);

  const listItemStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 20px 10px 0',
    borderBottom: '1px solid gray',
    margin: '10px',
  };

  const wappModalHeaderStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await client.endpoints.carrier.getUserData();
        const { legalName } = res.data;
        setCarrierName(legalName);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <Container>
      <InfoBar
        status="info"
        text="Descargá la aplicación de Humber en los dispositivos de tus choferes - Enviá el link de descarga por whatsapp."
        setIsWappModalOpen={setIsWappModalOpen}
      />
      {loading && (
        <CenteredContainer>
          <Spinner thickness="4px" speed="0.65s" emptyColor="#383d54" color="#ffbb0f" size="xl" />
        </CenteredContainer>
      )}
      <TableWrap>
        <TableControls>
          <Input
            w="400px"
            m="10px 0 10px 65px"
            variant="flushed"
            placeholder="Buscar... "
            size="lg"
            id="search"
            type="text"
            value={filterTrucker}
            onChange={event => setFilterTrucker(event.target.value)}
          />

          <Button m="27px" onClick={openAllPermissionModal} size="md">
            Gestionar permisos para toda la flota
            <FontAwesomeIcon
              cursor="pointer"
              className="fleet-lock-icon"
              icon={faUserLock}
              onClick={openAllPermissionModal}
            />
          </Button>
        </TableControls>
        <TableContainer>
          <Table variant="simple" size="sm" className="table-tiny">
            <Thead>
              <Tr>
                <Th />
                <Th>Chofer</Th>
                <Th>CUIT</Th>
                <Th>Patente</Th>
                <Th>Tarjeta Humber</Th>
                <Th>Nro. celular</Th>
                <Th>Acciones</Th>
              </Tr>
            </Thead>
            <Tbody>
              {truckersFiltered.map(trucker => (
                // eslint-disable-next-line no-underscore-dangle
                <Tr key={trucker._id}>
                  <Td>
                    <FontAwesomeIcon
                      className={trucker.premium ? 'premium' : 'no-premium'}
                      icon={faCrown}
                    />
                  </Td>
                  <Td maxW="500">
                    <Text
                      fontSize="xs"
                      className={trucker.onTheRoad ? 'on-the-road' : 'trucker-available'}
                    >
                      {trucker.onTheRoad ? 'En viaje' : 'Disponible'}
                    </Text>
                    <Text fontSize="md">{trucker.truckerName}</Text>
                  </Td>
                  <Td>{trucker.truckerCuit}</Td>
                  <Td>{trucker.truck?.truckPatent}</Td>
                  <Td>
                    <Text fontSize="md">
                      {trucker.cards && trucker.cards.hasOwnProperty('bambaCardBalance')
                        ? `$ ${trucker.cards.bambaCardBalance}`
                        : ''}
                    </Text>
                    <Text fontSize="xs" className="card-number">
                      {trucker.cards && trucker.cards.hasOwnProperty('bambaCardNumber')
                        ? `Nro. ${trucker.cards.bambaCardNumber}`
                        : ''}
                    </Text>
                  </Td>
                  <Td>{trucker.truckerCellPhone}</Td>
                  <Td>
                    <FontAwesomeIcon
                      title="Editar"
                      className="icon"
                      cursor="pointer"
                      icon={faEdit}
                      onClick={e => {
                        setTruckerSelected(trucker);
                        onOpenModalHandle(e);
                      }}
                    />
                    <FontAwesomeIcon
                      title="Ver en mapa"
                      className="icon"
                      icon={faMapMarkedAlt}
                      cursor="pointer"
                      onClick={() => setTruckerSelected(trucker)}
                    />
                    <FontAwesomeIcon
                      title="Permisos del camionero"
                      className="icon"
                      icon={faLock}
                      cursor="pointer"
                      onClick={() => openTruckerPermissionModal(trucker)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </TableWrap>

      <Modal isOpen={isAllPermissionModalOpen} onClose={closeAllPermissionModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Permisos para toda mi flota</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList>
              {permissions.map(item => (
                <ListItem key={item.id}>
                  <Box sx={listItemStyles}>
                    <p>{item.description}</p>
                    <Switch
                      size="md"
                      isChecked={item.checked}
                      onChange={() => handleCheckboxChange(item)}
                      defaultChecked={false}
                    />
                  </Box>
                </ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeAllPermissionModal}>
              Cerrar
            </Button>
            <Button isLoading={isLoading} colorScheme="blue" onClick={updateAllFleetPermissions}>
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isTruckerPermissionModalOpen} onClose={closeTruckerPermissionModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Permisos de {truckerSelected.truckerName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList>
              {truckerPermissions.map(item => (
                <ListItem key={item.id}>
                  <Box sx={listItemStyles}>
                    <p>{item.description}</p>
                    <Switch
                      size="md"
                      isChecked={item.checked}
                      onChange={() => handleCheckboxChange(item)}
                      defaultChecked={item.checked}
                    />
                  </Box>
                </ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={closeTruckerPermissionModal}>
              Cancelar
            </Button>
            <Button isLoading={isLoading} colorScheme="blue" onClick={updateTruckerPermissions}>
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isWappModalOpen} onClose={closeWappModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box sx={wappModalHeaderStyles}>
              <FontAwesomeIcon icon={faWhatsapp} />
              <Text m="10px" fontSize="md">
                Enviar invitación a tus choferes
              </Text>
            </Box>
            <Text ml="27px" fontSize="sm" color="darkgray">
              Los camioneros que ya tienen la app no van a recibir este mensaje
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea p="20px" h="120px" value={wappMessageText} />
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isLoading} colorScheme="whatsapp" onClick={sendWapp}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <TruckersMapContainer>
        {truckerSelected && <TruckersMap truckerSelected={truckerSelected} />}
      </TruckersMapContainer>
      {openModal ? (
        <EditTruckerModal
          trucker={truckerSelected}
          premiumPhotos={truckerSelected.premiumPhotos}
          openModal={openModal}
          setOpenModal={setOpenModal}
          fetchTruckerList={fetchTruckers}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default MyFleet;
