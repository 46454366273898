/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import { string } from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'lodash/debounce';
import client from '../../../../api/api';
import CSS from './gas-order-styles';

const GenerateGasOrder = ({ shipmentId }) => {
  const [gasStationSelected, setGasStationSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [creatingGasOrder, setCreatingGasOrder] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options.length === 0;

  const getGasStationsDelayed = useCallback(
    debounce(
      async (text, callback) => {
        setOptions([]);
        const { data } = await client.endpoints.generic.getEnabledGasStationList(text);
        callback(
          Object.keys(data).map(key => ({
            gasStationName: data[key].nombre,
            id: data[key]._id,
          }))
        );
      },
      200,
      { leanding: true }
    ),
    []
  );

  useEffect(() => {
    if (inputValue)
      getGasStationsDelayed(inputValue, filteredOptions => {
        setOptions(filteredOptions);
      });
  }, [inputValue, getGasStationsDelayed]);

  const createGasOrder = async () => {
    try {
      setCreatingGasOrder(true);
      const gasOrder = {
        estacionServicioId: gasStationSelected.id,
      };
      const res = await client.endpoints.carrier.createGasOrder(gasOrder, shipmentId);
      if (res.status === 200) {
        setCreatingGasOrder(false);
        setSuccess(true);
      }
    } catch (error) {
      setCreatingGasOrder(false);
      setErrorMsg(error.response.data.message);
    }
  };

  return (
    <CSS.GasOrderWrap>
      <h3>Crear orden de gasoil</h3>
      <Autocomplete
        id="asynchronous-demo"
        style={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={option => {
          setGasStationSelected(option);
          return option.gasStationName;
        }}
        options={options}
        filterOptions={x => x}
        onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label="Estaciones de servicio"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <span className="create-btn-wrap">
        {!creatingGasOrder && !success ? (
          <button className="create-btn" type="button" onClick={() => createGasOrder()}>
            Crear orden
          </button>
        ) : (
          <>
            {success ? (
              <img src="/images/icons/done.png" alt="done" />
            ) : (
              <CircularProgress style={{ color: '#ffbb0f' }} />
            )}
          </>
        )}
      </span>
      {errorMsg ? <p className="error">{errorMsg}</p> : ''}
    </CSS.GasOrderWrap>
  );
};

GenerateGasOrder.propTypes = {
  shipmentId: string.isRequired,
};

export default GenerateGasOrder;
