/* eslint-disable no-console */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Heading,
} from '@chakra-ui/react';
import provinces from '../../Global/GeoCode/provinces';
import cities from '../../Global/GeoCode/cities';
import { AuthContext } from '../../PrivateRoute/AuthProvider';
import client from '../../../api/api';
import { getSessionUserData } from '../../../libs/user';

const Wrapper = styled.div`
  .select {
    margin: 10px 0;
  }

  .header {
    text-align: center;
    margin: 30px 0 0 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
  }

  h6 {
    font-size: 18px;
    padding: 0 0 30px 0;
    color: #ffbb0f;
    font-weight: 300;
  }
`;

const CarrierUbicationForm = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [provinceSelected, setProvinceSelected] = useState('');
  const [citySelected, setCitySelected] = useState('');
  const [citiesListByProvince, setCitiesListByProvince] = useState([]);
  const [geoCode, setGeocode] = useState('');
  const { logout } = useContext(AuthContext);
  const { _id } = getSessionUserData().data;
  const {
    endpoints: { carrier },
  } = client;

  const logOut = async () => {
    await logout();
    await carrier.fbTokenLogOut(_id);
  };

  const updateHandler = async () => {
    try {
      const res = await client.endpoints.carrier.updateCarrierGeocode(geoCode);
      if (res.status === 200) setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (provinceSelected) {
      const results = cities.filter(citiesList => {
        return citiesList.id === JSON.parse(provinceSelected).id;
      });
      setCitiesListByProvince(results[0].list);
    }
  }, [provinceSelected]);

  useEffect(() => {
    if (provinceSelected && citySelected) {
      const fetchGeocode = async () => {
        const res = await axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${
              JSON.parse(provinceSelected).value
            },+${JSON.parse(citySelected).value}&key=AIzaSyBnq1QcKZwrZYdEj2HuPsOKxQvrbm3_j5U`
          )
          .then(response => {
            setGeocode({
              geoCode: {
                lat: response.data.results[0].geometry.location.lat,
                long: response.data.results[0].geometry.location.lng,
              },
              provinceId: Number(JSON.parse(provinceSelected).id),
              provinceName: JSON.parse(provinceSelected).value,
              cityId: Number(JSON.parse(citySelected).id),
              cityName: JSON.parse(citySelected).value,
            });
          });
        return res;
      };
      fetchGeocode();
    }
  }, [provinceSelected, citySelected]);

  return (
    <Modal isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <Wrapper>
          <ModalHeader className="header">
            <Heading as="h6" size="md">
              ¡Ayudanos a simplificar la logística!
            </Heading>
            <p>Completá provincia y ciudad</p>
            <p>para que podamos notificarte cargas de tu zona</p>
          </ModalHeader>
          <ModalBody>
            <Select
              className="select"
              placeholder="Provincia"
              onChange={e => setProvinceSelected(e.target.value)}
            >
              {provinces.map(prov => {
                return <option value={JSON.stringify(prov)}>{prov.value}</option>;
              })}
            </Select>
            <Select
              className="select"
              placeholder="Ciudad"
              onChange={e => setCitySelected(e.target.value)}
              isDisabled={!citiesListByProvince.length > 0}
            >
              {citiesListByProvince.map(city => {
                return <option value={JSON.stringify(city)}>{city.value}</option>;
              })}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button className="btn-save" variant="ghost">
              <Link to="/" onClick={() => logOut()}>
                Salir
              </Link>
            </Button>
            <Button className="btn-save" variant="ghost" onClick={() => updateHandler()}>
              Guardar
            </Button>
          </ModalFooter>
        </Wrapper>
      </ModalContent>
    </Modal>
  );
};

export default CarrierUbicationForm;
