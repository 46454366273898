/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { BallClipRotate } from 'react-pure-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faClock, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import client from '../../../api/api';
import resizeImage from '../../../libs/resizer/imageResizer';

const EndPicWrap = styled.div`
  width: 40px;
  label {
    margin: 0;
  }

  .check-icon {
    font-size: 18px;
    float: right;
    color: #19c482;
  }

  .error-upload-icon {
    font-size: 18px;
    float: right;
    color: #e03f3f;
  }
`;

const CameraIcon = styled.div`
  .empty-icon {
    color: #6495ed;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .accepted-icon {
    color: #19c482;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: auto;
  }

  .rejected-icon {
    color: #e03f3f;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .cancelled-icon {
    color: #424141;
    font-size: 18px;
    margin: 0;
    padding: 0;
    cursor: auto;
  }
`;

const ClockIcon = styled.div`
  display: inline-block;
  .icon {
    color: yellow;
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
`;

const EndPic = ({ shipment }) => {
  const [loading, setLoading] = useState(false);

  const [blueCam, setBlueCam] = useState(
    !['completa', 'finalizada', 'cancelada'].includes(shipment?.status) &&
      shipment?.status !== 'cancelada' &&
      !shipment?.signedReport &&
      !shipment?.confirmedSignedReport
  );

  const [clock, setClock] = useState(
    shipment?.signedReport !== null &&
      shipment?.confirmedSignedReport === null &&
      shipment?.status !== 'errorFotoFin' &&
      shipment?.status !== 'finalizada'
  );

  const [redCam, setRedCam] = useState(
    (shipment?.signedReport !== null &&
      (shipment?.confirmedSignedReport !== null && !shipment?.confirmedSignedReport)) ||
      shipment?.status === 'errorFotoFin'
  );

  const [greenCam] = useState(
    shipment?.status === 'finalizada' ||
      (shipment?.signedReport !== null &&
        (shipment?.confirmedSignedReport !== null && shipment?.confirmedSignedReport))
  );

  const [grayCam] = useState(shipment?.status === 'cancelada');

  const [uploadSuccessIcon, setUploadSuccessIcon] = useState(false);
  const [uploadErrorIcon, setUploadErrorIcon] = useState(false);

  const photoUpload = async pic => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('photoFile', pic);
      formData.append('photoName', 'signedReport');
      const data = { formData };
      const res = await client.endpoints.carrier.uploadShipmentPhotos(
        shipment.trucker.userId,
        shipment.id,
        data
      );

      if (res.status === 200) {
        setLoading(false);
        setClock(true);
        setUploadSuccessIcon(true);
        setBlueCam(false);
        setRedCam(false);
      }
    } catch (error) {
      setLoading(false);
      setUploadErrorIcon(true);
    }
  };

  const startUploadProcess = async file => {
    try {
      const res = await resizeImage({ file });
      photoUpload(res);
    } catch (error) {
      setUploadErrorIcon(true);
    }
  };

  return (
    <EndPicWrap>
      {loading ? (
        <BallClipRotate color="#ffbb0f" loading />
      ) : (
        <>
          {grayCam ? (
            <CameraIcon>
              <FontAwesomeIcon icon={faCamera} className="cancelled-icon" />
            </CameraIcon>
          ) : (
            ''
          )}
          {blueCam ? (
            <CameraIcon>
              <label htmlFor={shipment.trucker.cuit}>
                <FontAwesomeIcon icon={faCamera} className="empty-icon" />
                <input
                  id={shipment.trucker.cuit}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={e => {
                    startUploadProcess(e.target.files[0]);
                  }}
                />
              </label>
            </CameraIcon>
          ) : (
            ''
          )}

          {greenCam ? (
            <CameraIcon>
              <FontAwesomeIcon icon={faCamera} className="accepted-icon" />
            </CameraIcon>
          ) : (
            ''
          )}

          {redCam ? (
            <CameraIcon>
              <label htmlFor={shipment.trucker.cuit}>
                <FontAwesomeIcon icon={faCamera} className="rejected-icon" />
                <input
                  id={shipment.trucker.cuit}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={e => {
                    startUploadProcess(e.target.files[0]);
                  }}
                />
              </label>
            </CameraIcon>
          ) : (
            ''
          )}

          {clock ? (
            <>
              <ClockIcon>
                <FontAwesomeIcon icon={faClock} className="icon" />
              </ClockIcon>
              {uploadSuccessIcon ? <FontAwesomeIcon icon={faCheck} className="check-icon" /> : ''}
              {uploadErrorIcon ? (
                <FontAwesomeIcon icon={faTimes} className="error-upload-icon" />
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </>
      )}
    </EndPicWrap>
  );
};

EndPic.propTypes = {
  shipment: PropTypes.shape(PropTypes.object).isRequired,
};

export default EndPic;
