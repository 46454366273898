/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { bool, func, PropTypes, object } from 'prop-types';
import client from '../../../api/api';
import resizeImage from '../../../libs/resizer/imageResizer';
import Card from './Card';
import ModalRegular from '../../../ui/modals';
import AdditionalPhotos from './AdditionalPhotos';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const ModalWrap = styled.div`
  h1 {
    color: #ffbb0f;
    font-size: 20px;
    padding: 0 23px;
  }

  ${media.tablet} {
    h1 {
      width: 46pc;
      font-size: 2pc;
      margin-top: 50px;
    }
  }
`;

const Row = styled.div`
  ${media.tablet} {
    margin: 20px auto;
    width: 90%;
  }

  ${media.desktop} {
    display: flex;
  }
`;

const Header = styled.div`
  img {
    margin: 22px;
    width: 3pc;
    height: 3pc;
  }

  ${media.tablet} {
    display: flex;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;

    img {
      margin: 50px 10px;
      width: 5pc;
      height: 5pc;
    }
  }
`;

const StatusWrap = styled.div`
  width: 35px;
  position: fixed;
  right: 80px;
  bottom: 80px;

  img {
    width: 34px;
  }
`;

const SaveBtnWrap = styled.div`
  position: fixed;
  height: 5pc;
  bottom: 1pc;
  right: 4%;

  button {
    position: absolute;
    border-radius: 85px;
    font-size: 26px;
    border: none;
    background: #ffbb0f;
    width: 4pc;
    height: 4pc;
  }

  p {
    margin-top: 4pc;
    margin-left: -3px;
    color: #ffbb0f;
    font-size: 20px;
  }

  button:focus {
    outline: none;
  }

  button img {
    width: 100%;
  }

  ${media.desktop} {
    margin: 40px;

    button {
      width: 6pc;
      height: 6pc;
    }

    p {
      margin-top: 6pc;
      margin-left: 6px;
      font-size: 25px;
    }
  }
`;

const Error = styled.div`
  width: 100%;
  background: red;

  p {
    color: white;
    padding: 10px;
  }
`;

const EditTruckerModal = ({
  trucker,
  premiumPhotos,
  openModal,
  setOpenModal,
  fetchTruckerList,
}) => {
  const { truck } = trucker;
  const [celNumber, setCelNumber] = useState('');

  const [cbu, setCBU] = useState('');
  const [truckPatent, setTruckPatent] = useState('');
  const [trailerPatent, setTrailerPatent] = useState('');
  const [bodyTypeSelected, setBodyTypeSelected] = useState('');
  const [psycophysicalPhoto, setPsycophysicalPhoto] = useState('');
  const [psycophysicalPhotoUpdated, setPsycophysicalPhotoUpdated] = useState('');
  const [frontTractorIdPhoto, setFrontTractorIdPhoto] = useState('');
  const [frontTractorIdPhotoUpdated, setFrontTractorIdPhotoUpdated] = useState('');
  const [trailerIdPhoto, setTrailerIdPhoto] = useState('');
  const [trailerIdPhotoUpdated, setTrailerIdPhotoUpdated] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [truckerUpdated, setTruckerUpdated] = useState(false);
  const [photoUpdated, setPhotoUpdated] = useState(false);
  const [additionalPhotosList, setAdditionalPhotosList] = useState([]);

  const [editTruckerInfo, setEditTruckerInfo] = useState(false);
  const [editBilingDataInfo, setEditBilingDataInfo] = useState(false);
  const [editTractorInfo, setEditTractorInfo] = useState(false);
  const [editTrailerInfo, setEditTrailerInfo] = useState(false);

  const errorRef = useRef(null);

  const truckerFields = [
    { label: 'Nombre y Apellido', value: trucker.truckerName },
    { label: 'Celular', value: trucker.truckerCellPhone },
  ];

  const truckerInputFields = [
    {
      label: 'Celular',
      id: 'cel-number',
      type: 'number',
      defaultValue: trucker.truckerCellPhone,
      func: setCelNumber,
      errorMsg: '',
      disabled: false,
    },
  ];

  const billingFields = [
    {
      label: 'CUIT de facturación',
      value: trucker.billingData ? trucker.billingData.cuit : '',
    },
    {
      label: 'CBU',
      value: trucker.billingData ? trucker.billingData.bankAccount : '',
    },
  ];

  const billingInputFields = [
    {
      label: 'CBU',
      id: 'bank-account',
      type: 'text',
      defaultValue: trucker?.truck?.truckPatent,
      func: setCBU,
      errorMsg: '',
      disabled: false,
    },
  ];

  const tractorFields = [
    { label: 'Patente', value: trucker?.truck?.truckPatent },
    { value: trucker?.truck?.truckMake },
    { value: trucker?.truck?.truckType },
  ];

  const tractorInputFields = [
    {
      label: 'Patente',
      id: 'truck-patent',
      type: 'text',
      defaultValue: trucker?.truck?.truckPatent,
      func: setTruckPatent,
      errorMsg: '',
      disabled: false,
    },
  ];

  const trailerFields = [
    { label: 'Patente', value: trucker?.truck?.trailerPatent },
    { value: trucker?.truck?.truckBodyType },
  ];

  const trailerInputFields = [
    {
      label: 'Patente',
      id: 'trailer-patent',
      type: 'text',
      defaultValue: trucker?.truck?.trailerPatent,
      func: setTrailerPatent,
      errorMsg: '',
      disabled: false,
    },
    {
      label: 'Carrocería',
      id: 'truck-body-type',
      type: 'select',
      defaultValue: bodyTypeSelected,
      func: setBodyTypeSelected,
      errorMsg: '',
      disabled: false,
    },
  ];

  const updateTrucker = async () => {
    try {
      const formData = new FormData();
      if (celNumber !== '') {
        formData.append('truckerCellPhone', celNumber);
      }
      if (truckPatent) {
        formData.append('truckPatent', truckPatent);
      }
      if (trailerPatent) {
        formData.append('trailerPatent', trailerPatent);
      }
      if (bodyTypeSelected) {
        formData.append('trailerBodyTypeId', bodyTypeSelected._id);
      }
      if (cbu) {
        formData.append('bankAccount', cbu);
      }
      const res = await client.endpoints.carrier.editTrucker(formData, trucker._id);

      if (res.status === 200) {
        setTruckerUpdated(true);
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };

  const premiumImgList = () => {
    const photoUpdateList = [];
    if (psycophysicalPhotoUpdated !== '') {
      photoUpdateList.push({
        photoTag: 'Foto psicofísico',
        photoName: 'psychophysicalPhoto',
        photoFile: psycophysicalPhotoUpdated,
      });
    }
    if (frontTractorIdPhotoUpdated !== '') {
      photoUpdateList.push({
        photoTag: 'Cédula Tractor',
        photoName: 'frontTractorIdPhoto',
        photoFile: frontTractorIdPhotoUpdated,
      });
    }
    if (trailerIdPhotoUpdated !== '') {
      photoUpdateList.push({
        photoTag: 'Cédula Remolque',
        photoName: 'trailerIdPhoto',
        photoFile: trailerIdPhotoUpdated,
      });
    }
    return photoUpdateList;
  };

  const updatePhoto = async img => {
    try {
      const resizedImage = await resizeImage({ file: img.photoFile });
      const formData = new FormData();
      formData.append('photoName', img.photoName);
      formData.append('photoFile', resizedImage);
      const res = await client.endpoints.carrier.editTruckerPhotos(formData, truck._id);
      if (res.status === 200) {
        setPhotoUpdated(true);
      }
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const updateChanges = () => {
    try {
      const photoListForUpdate = premiumImgList();
      if (photoListForUpdate.length > 0) {
        photoListForUpdate.forEach(e => {
          updatePhoto(e);
        });
      }

      if (additionalPhotosList.length > 0) {
        additionalPhotosList.forEach(e => {
          updatePhoto(e);
        });
      }

      if (celNumber || truckPatent || trailerPatent || bodyTypeSelected || cbu) {
        updateTrucker();
      }
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const checkStatus = () => {
    setPsycophysicalPhoto({
      imgURI: premiumPhotos.psychophysicalPhoto,
      imgStatus:
        premiumPhotos.confirmedPsychophysicalPhoto !== ''
          ? premiumPhotos.confirmedPsychophysicalPhoto
          : null,
      expiration: premiumPhotos.psychophysicalExpiration
        ? moment(premiumPhotos.psychophysicalExpiration).format('DD/MM/YYYY')
        : ' - ',
    });
    setFrontTractorIdPhoto({
      imgURI: premiumPhotos.frontTractorIdPhoto,
      imgStatus:
        premiumPhotos.confirmedFrontTractorIdPhoto !== ''
          ? premiumPhotos.confirmedFrontTractorIdPhoto
          : null,
    });
    setTrailerIdPhoto({
      imgURI: premiumPhotos.trailerIdPhoto,
      imgStatus:
        premiumPhotos.confirmedFrontTrailerIdPhoto !== ''
          ? premiumPhotos.confirmedFrontTrailerIdPhoto
          : null,
    });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    if (errorMsg && errorRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [errorMsg, errorRef]);

  return (
    <ModalRegular
      visible={openModal}
      onActionHandler={() => {
        fetchTruckerList();
        setOpenModal(false);
      }}
    >
      <ModalWrap>
        <Header>
          <img src="../../../images/icons/super-premium.png" alt="super-premium" />
          <h1>
            Hace premium a tu camionero con solo 3 fotos (Psicofísico y las cédulas de
            tractor/remolque)
          </h1>
        </Header>
        {errorMsg ? (
          <Error>
            <p ref={errorRef}>{errorMsg}</p>
          </Error>
        ) : (
          ''
        )}
        <Row>
          {psycophysicalPhoto ? (
            <Card
              title="Chofer"
              fields={truckerFields}
              setEditCardInfo={setEditTruckerInfo}
              editCardInfo={editTruckerInfo}
              inputFields={truckerInputFields}
              img={psycophysicalPhoto}
              uploadImgBtnLabel="Psicofísico"
              billingData={false}
              setImg={setPsycophysicalPhotoUpdated}
            />
          ) : (
            ''
          )}
          <Card
            title="Datos de facturación"
            fields={billingFields}
            setEditCardInfo={setEditBilingDataInfo}
            editCardInfo={editBilingDataInfo}
            inputFields={billingInputFields}
            billingData
          />
        </Row>
        <Row>
          {frontTractorIdPhoto ? (
            <Card
              title="Tractor"
              fields={tractorFields}
              setEditCardInfo={setEditTractorInfo}
              editCardInfo={editTractorInfo}
              inputFields={tractorInputFields}
              img={frontTractorIdPhoto}
              uploadImgBtnLabel="Cédula"
              billingData={false}
              setImg={setFrontTractorIdPhotoUpdated}
            />
          ) : (
            ''
          )}
          {trailerIdPhoto ? (
            <Card
              title="Remolque"
              fields={trailerFields}
              setEditCardInfo={setEditTrailerInfo}
              editCardInfo={editTrailerInfo}
              inputFields={trailerInputFields}
              img={trailerIdPhoto}
              uploadImgBtnLabel="Cédula"
              billingData={false}
              setImg={setTrailerIdPhotoUpdated}
            />
          ) : (
            ''
          )}
        </Row>
        <AdditionalPhotos
          premiumPhotos={premiumPhotos}
          setAdditionalPhotosList={setAdditionalPhotosList}
        />
        <StatusWrap>
          {errorMsg ? (
            <img src="../../../images/icons/save-btn-error.png" alt="save-btn-error" />
          ) : (
            ''
          )}
          {truckerUpdated || photoUpdated ? (
            <img src="../../../images/icons/save-btn-ok.png" alt="save-btn-ok" />
          ) : (
            ''
          )}
        </StatusWrap>
        <SaveBtnWrap>
          <button
            type="button"
            id="save-btn"
            onClick={() => {
              setErrorMsg('');
              updateChanges();
            }}
          >
            <img
              src="../../../images/icons/save-btn.png"
              className="cloud"
              alt="psychophysicalPhoto"
            />
          </button>
          <p>Guardar</p>
        </SaveBtnWrap>
      </ModalWrap>
    </ModalRegular>
  );
};

EditTruckerModal.propTypes = {
  trucker: PropTypes.objectOf(object),
  setOpenModal: func.isRequired,
  fetchTruckerList: func.isRequired,
  openModal: bool.isRequired,
  premiumPhotos: PropTypes.objectOf(object),
};

EditTruckerModal.defaultProps = {
  trucker: {},
  premiumPhotos: {},
};

export default EditTruckerModal;
