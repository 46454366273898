import React from 'react';
import { node, func, bool } from 'prop-types';
import styled from 'styled-components';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const ModalWrapper = styled.div`
  position: absolute;
  height: fit-content;
  top: 0px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: #31354e;
  overflow: hidden;

  ${media.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CloseWrapper = styled.div`
  width: 50px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const CloseText = styled.p`
  font-size: 10px;
  color: white;
`;

const ModalRegular = ({
  /** Show modal */
  visible,
  /** Internal content componets */
  children,
  /** Action function. */
  onActionHandler,
}) => (
  <>
    {visible ? (
      <ModalWrapper>
        {children}
        <CloseWrapper onClick={onActionHandler}>
          <img src="/images/icons/close.png" alt="cerrar-icono" />
          <CloseText>CERRAR</CloseText>
        </CloseWrapper>
      </ModalWrapper>
    ) : null}
  </>
);

ModalRegular.propTypes = {
  visible: bool.isRequired,
  children: node.isRequired,
  onActionHandler: func.isRequired,
};

export default ModalRegular;
