import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes, object } from 'prop-types';
import UploadImgBtn from '../UploadImgBtn';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const DocumentStatusWrap = styled.div`
  h3 {
    color: white;
    font-size: 14px;
  }

  img {
    width: 35px;
    height: 35px;
    margin: 0 3px;
  }

  .error {
    color: #f05353;
  }

  ${media.desktop} {
    h3 {
      font-size: 1.75em;
    }
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DocumentStatus = ({ documentData, setImg }) => {
  const [errorSrc, setErrorSrc] = useState('');
  const [checkingSrc, setCheckingSrc] = useState('');
  const [confirmedSrc, setConfirmedSrc] = useState('');

  const imgURI = documentData ? documentData.imgURI : '';
  const imgStatus = documentData ? documentData.imgStatus : '';

  const rejectedOff = '../../../images/icons/error-icon-off.png';
  const checkingOff = '../../../images/icons/checking-icon-off.png';
  const confirmedOff = '../../../images/icons/confirmed-icon-off.png';

  const rejectedOn = '../../../images/icons/error-icon-on.png';
  const checkingOn = '../../../images/icons/checking-icon-on.png';
  const confirmedOn = '../../../images/icons/confirmed-icon-on.png';

  const [statusChecked, setStatusChecked] = useState(false);

  const checkStatus = () => {
    if (imgURI === '' && (!imgStatus && imgStatus !== false)) {
      setErrorSrc(rejectedOff);
      setCheckingSrc(checkingOff);
      setConfirmedSrc(confirmedOff);
    } else if ((imgStatus === null || imgStatus === '') && imgURI !== '') {
      setErrorSrc(rejectedOff);
      setCheckingSrc(checkingOn);
      setConfirmedSrc(confirmedOff);
    } else if ((imgURI !== '' && !imgStatus) || imgStatus === false) {
      setErrorSrc(rejectedOn);
      setCheckingSrc(checkingOff);
      setConfirmedSrc(confirmedOff);
    } else if (imgStatus) {
      setErrorSrc(rejectedOff);
      setCheckingSrc(checkingOff);
      setConfirmedSrc(confirmedOn);
    }
    setStatusChecked(true);
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <DocumentStatusWrap>
      <ImgWrap>
        {statusChecked ? (
          <>
            <img src={errorSrc} className="error-icon" alt="error-icon" />
            <img src={checkingSrc} alt="checking-icon" className="checking-icon" />
            <img src={confirmedSrc} alt="confirmed-icon" className="confirmed-icon" />
            <UploadImgBtn setImg={setImg} />
          </>
        ) : (
          ''
        )}
      </ImgWrap>
    </DocumentStatusWrap>
  );
};

DocumentStatus.propTypes = {
  documentData: PropTypes.objectOf(object),
  setImg: PropTypes.func.isRequired,
};

DocumentStatus.defaultProps = {
  documentData: {},
};

export default DocumentStatus;
