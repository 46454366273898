import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PointNav from './PointNav';
import AddLoadView from './AddLoadView';
import ActiveLoadsView from './ActiveLoadsView';
import PointRecordView from './PointRecordView/PointRecordView';

const routes = [
  {
    path: '/point',
    component: AddLoadView,
  },
  {
    path: '/point/active-loads',
    component: ActiveLoadsView,
  },
  {
    path: '/point/point-record',
    component: PointRecordView,
  },
];

function routing() {
  return routes.map(route => (
    <Route exact key={route.path} path={route.path} component={route.component} />
  ));
}

const Point = () => {
  return (
    <Router>
      <>
        <PointNav />
        <Switch>{routing()}</Switch>
      </>
    </Router>
  );
};

export default Point;
