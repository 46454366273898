import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './options-redirect.css';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const OptionsRedirect = props => {
  const { genMsj, msjBtn1, msjBtn2, action1, action2 } = props;
  const [buttonOnePress, setButtonOnePress] = useState(false);
  const [buttonTwoPress, setButtonTwoPress] = useState(false);

  const renderOptions = () => {
    if (buttonOnePress) {
      action1();
    }
    if (buttonTwoPress) {
      action2();
    }
    return (
      <div className="success-screen">
        <div className="message">
          <h2>{genMsj}</h2>
        </div>

        <div className="icon-wrapper">
          <FontAwesomeIcon className="icon" icon={faCheckCircle} />
        </div>

        <div className="options-wrapper">
          {msjBtn1 ? (
            <label htmlFor="msgOne">
              <div
                className="message-one-button"
                id="msgOne"
                tabIndex={0}
                role="button"
                onClick={() => setButtonOnePress(true)}
              >
                {msjBtn1}
              </div>
            </label>
          ) : (
            ''
          )}

          {msjBtn2 ? (
            <label htmlFor="msgTwo">
              <div
                className="message-two-button"
                id="msgTwo"
                tabIndex={0}
                role="button"
                onClick={() => setButtonTwoPress(true)}
              >
                {msjBtn2}
              </div>
            </label>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  };
  return renderOptions();
};

OptionsRedirect.propTypes = {
  genMsj: PropTypes.string.isRequired,
  msjBtn1: PropTypes.string.isRequired,
  msjBtn2: PropTypes.string.isRequired,
  path1: PropTypes.string,
  path2: PropTypes.string,
};

export default withRouter(OptionsRedirect);
