/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import truckIcon from '../../../../img/TruckIcon';
import startIcon from '../../../../img/StartIcon';
import endIcon from '../../../../img/EndIcon';

const MapWrap = styled.span`
  .leaflet-container .leaflet-marker-icon {
    margin-left: -12px !important;
    margin-top: -40px !important;
  }

  .active-load-icon {
    font-size: 25px;
  }
`;

const MapIconWrap = styled.div`
  outline: none;
  .btn-map {
    width: 125px;
    color: #ffbb0f;
    cursor: pointer;
    padding: 10px 0;
    font-size: 13px;
  }
`;

const ActiveLoadsMap = ({ loadSelected }) => {
  const [showMap, setShowMap] = useState(true);
  const defaultPosition = [-34.603722, -58.381592];
  const { shipments } = loadSelected;

  return (
    <>
      <MapIconWrap onClick={() => setShowMap(!showMap)} tabIndex={0} role="button">
        {showMap ? <p className="btn-map">Ocultar mapa</p> : <p className="btn-map">Ver mapa</p>}
      </MapIconWrap>
      {showMap ? (
        <MapWrap>
          <Map
            center={loadSelected ? loadSelected.fromGeoCode : defaultPosition}
            scrollWheelZoom={false}
            zoom={6}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {loadSelected ? (
              <>
                <Marker position={loadSelected.fromGeoCode} icon={startIcon} />
                <Marker position={loadSelected.toGeoCode} icon={endIcon} />
              </>
            ) : (
              ''
            )}
            {shipments ? (
              <>
                {shipments.map(e => {
                  const position =
                    e.trucker.lastGeolocationReport !== ''
                      ? e.trucker.lastGeolocationReport.geolocation
                      : e.trucker.geoCode;
                  return (
                    <>
                      {e.status !== 'cancelada' ? (
                        <Marker position={position} className="truck-icon" icon={truckIcon}>
                          <Popup>
                            {e.trucker.lastGeolocationReport.geolocation === ''
                              ? 'No reporta geolocalización'
                              : ''}
                            <br />
                            <b>Nombre: </b> {e.trucker.truckerName} <br />
                            <b>CUIT: </b> {e.trucker.truckerCuit} <br />
                            <b>Patente: </b> {e.truck.truckPatent} <br />
                            <b>Cel: </b> {e.trucker.truckerCellPhone} <br />
                            {e.trucker.lastGeolocationReport !== '' ? (
                              <>
                                <b>Geolocalización reportada</b>
                                <br />
                                {moment(e.trucker.lastGeolocationReport.timestamp).format(
                                  'DD-MMM-YYYY'
                                )}
                                <br />
                                {moment(e.trucker.lastGeolocationReport.timestamp).format('HH:mm')}
                                hs.
                              </>
                            ) : (
                              <>
                                <b>Geolocalización desactualizada</b>
                                <br />
                              </>
                            )}
                          </Popup>
                        </Marker>
                      ) : (
                        ''
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              ''
            )}
          </Map>
        </MapWrap>
      ) : (
        ''
      )}
    </>
  );
};

ActiveLoadsMap.propTypes = {
  loadSelected: PropTypes.object.isRequired,
};

export default ActiveLoadsMap;
