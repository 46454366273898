import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import client from '../../../api/api';
import HumberDropdown from '../../../libs/humber-dropdown';

const media = {
  tablet: '@media(min-width: 768px)',
  desktop: '@media(min-width: 1366px)',
};

const InputProviderWrap = styled.div`
  margin-top: 20px;

  label {
    width: 100%;
    font-weight: 600;
    color: white;
  }

  input {
    background: transparent;
    border: none;
    height: 45px;
    font-size: 16px;
    color: white;
    width: 100%;
    border-bottom: 1px solid #787878;
  }

  input:focus {
    outline: none;
  }

  ${media.desktop} {
    padding: 0 15px;
  }
`;

const InputProvider = ({
  label,
  id,
  type,
  defaultValue,
  setValue,
  errorMsg,
  disabled,
  setErrorMsg,
}) => {
  const [list, setList] = useState([]);

  const getTrailerTypeList = async () => {
    try {
      const res = await client.endpoints.carrier.getBodyTypeList();
      setList(res.data);
    } catch (error) {
      setErrorMsg('Error al obtener listado de carrocerías');
    }
  };

  useEffect(() => {
    if (type === 'select') {
      getTrailerTypeList();
    }
  }, []);
  return (
    <InputProviderWrap>
      {type === 'select' && list.length > 0 ? (
        <HumberDropdown
          list={list}
          label={label}
          htmlFor={id}
          id={id}
          value={defaultValue}
          onChange={setValue}
          errorMsg={errorMsg}
          required
        />
      ) : (
        <label htmlFor={id}>
          {label}
          <input
            id={id}
            type={type}
            defaultValue={defaultValue}
            onChange={e => {
              setValue(e.target.value);
            }}
            errorMsg={errorMsg}
            disabled={disabled}
          />
        </label>
      )}
    </InputProviderWrap>
  );
};

InputProvider.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setValue: PropTypes.func,
  errorMsg: PropTypes.string.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
};

InputProvider.defaultProps = {
  setValue: () => {},
};

export default InputProvider;
