import React, { useState } from 'react';
import PropTypes from 'prop-types';

const IS_EMPTY_ERROR_MSG = 'Por favor, completa este campo';

const HumberInput = ({
  value,
  onChange,
  onBlur = () => {},
  onClick = () => {},
  label,
  errorMsg,
  id,
  type,
  htmlFor,
  min,
  minLength,
  maxLength,
  required,
  placeholder,
  display,
}) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [lengthError, setLengthError] = useState('');

  const checkEmpty = val => {
    if (!val) {
      setIsEmpty(IS_EMPTY_ERROR_MSG);
      return false;
    }
    setIsEmpty('');
    return true;
  };

  const checkMinLength = key => {
    if (key.length < minLength) {
      setLengthError(`El numero debe tener por lo menos ${minLength} digitos`);
      return false;
    }
    setLengthError('');
    return true;
  };

  return (
    <div className="humber-input">
      <label htmlFor={htmlFor}>
        {label}
        <input
          id={id}
          type={type}
          defaultValue={value}
          min={min}
          maxLength={maxLength}
          minLength={minLength}
          className="form-control"
          display={display}
          value={value}
          placeholder={placeholder}
          onChange={e => {
            onChange(e.target.value);
          }}
          onBlur={e => {
            let callOnBlur = true; // si hay un error no llamo al onBlur
            if (required) {
              callOnBlur = checkEmpty(e.target.value);
            }
            if (minLength) {
              callOnBlur = checkMinLength(e.target.value);
            }
            if (callOnBlur) {
              onBlur(e.target.value);
            }
          }}
          onClick={onClick}
        />
      </label>
      {errorMsg || lengthError || isEmpty ? (
        <small className="simple-error-msg">{errorMsg || lengthError || IS_EMPTY_ERROR_MSG}</small>
      ) : (
        ''
      )}
    </div>
  );
};

HumberInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  min: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  display: PropTypes.string,
};

HumberInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  placeholder: '',
  errorMsg: '',
  min: '',
  minLength: null,
  maxLength: null,
  required: false,
  display: '',
};

export default HumberInput;
