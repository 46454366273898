/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { BallClipRotate } from 'react-pure-loaders';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import image from './flota.png';
import CSS from './active-loads-styles';
import ActiveLoadsMap from './components/ActiveLoadsMap';
import ActiveLoad from './components/ActiveLoad';
import LoadInfo from './components/LoadInfo';
import client from '../../../api/api';
import CartaPorteCreate from './components/CartaPorteCreate';

const ActiveLoadsView = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loadSelected, setLoadSelected] = useState('');
  const [activeLoads, setActiveLoads] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cartaPorteData, setCartaPorteData] = useState({});

  const fetchLoads = async () => {
    try {
      setIsLoading(true);
      const res = await client.endpoints.shipper.getActiveLoads();
      if (res.data.length > 0) {
        setLoadSelected(res.data[0]);
        setActiveLoads(res.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setErrorMsg(error);
    }
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    await fetchLoads();
  };

  useEffect(() => {
    fetchLoads();
  }, []);

  return (
    <CSS.ActiveLoadsWrap>
      {errorMsg !== '' ? <p className="error-msg">{errorMsg}</p> : ''}
      {loadSelected !== '' ? (
        <CSS.MapWrap>
          <ActiveLoadsMap loadSelected={loadSelected} />
          <LoadInfo loadSelected={loadSelected} fetchLoads={fetchLoads} />
        </CSS.MapWrap>
      ) : (
        <CSS.MapWrap style={{ animation: 'fadeIn 5s' }}>
          <img src={image} className="no-loads-icon-map" alt="icon-img" />
        </CSS.MapWrap>
      )}

      <CSS.ActiveLoadsMobile>
        {isLoading ? (
          <BallClipRotate color="#ffbb0f" isLoading />
        ) : (
          <>
            <div className="load-list">
              <h4>Cargas activas</h4>
              {activeLoads ? (
                <div className="load-list-wrap">
                  {activeLoads.map(load => (
                    <div
                      className="load-wrap"
                      tabIndex={0}
                      role="button"
                      key={load._id}
                      onClick={() => {
                        setLoadSelected(load);
                      }}
                    >
                      <ActiveLoad
                        load={load}
                        openCartaPorteCarga={() => {
                          setCartaPorteData({
                            load,
                            shipments: load.shipments,
                          });
                          setIsModalVisible(true);
                        }}
                        openCartaPorteViaje={shipment => {
                          setCartaPorteData({
                            load,
                            shipments: [shipment],
                          });
                          setIsModalVisible(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-loads-wrap">
                  <FontAwesomeIcon icon={faMapMarkedAlt} className="no-loads-icon" />
                  <h1>No tenes cargas activas en este momento</h1>
                  <Link className="add-load-btn" to="/point" onClick={() => {}}>
                    Subir Carga
                  </Link>
                </div>
              )}
            </div>
          </>
        )}
      </CSS.ActiveLoadsMobile>

      <CSS.ActiveLoadsDesktop>
        {isLoading ? (
          <BallClipRotate color="#ffbb0f" isLoading />
        ) : (
          <>
            <div className="load-list">
              <h4>Cargas activas</h4>
              {activeLoads ? (
                <div className="load-list-wrap">
                  {activeLoads.map(load => (
                    <div
                      className="load-wrap"
                      tabIndex={0}
                      role="button"
                      key={load._id}
                      onClick={() => {
                        setLoadSelected(load);
                      }}
                    >
                      <ActiveLoad
                        load={load}
                        openCartaPorteCarga={() => {
                          setCartaPorteData({
                            load,
                            shipments: load.shipments,
                          });
                          setIsModalVisible(true);
                        }}
                        openCartaPorteViaje={shipment => {
                          setCartaPorteData({
                            load,
                            shipments: [shipment],
                          });
                          setIsModalVisible(true);
                        }}
                      />
                    </div>
                  ))}

                  <Modal
                    destroyOnClose
                    title="Datos de la carga"
                    visible={isModalVisible}
                    footer={null}
                    closable
                    onCancel={handleCancel}
                    style={{ top: 20 }}
                    width="80%"
                  >
                    <CartaPorteCreate
                      load={cartaPorteData.load}
                      onCancel={handleCancel}
                      shipments={cartaPorteData.shipments}
                    />
                  </Modal>
                </div>
              ) : (
                <div className="no-loads-wrap">
                  <FontAwesomeIcon icon={faMapMarkedAlt} className="no-loads-icon" />
                  <h1>No tenes cargas activas en este momento</h1>
                  <Link className="add-load-btn" to="/point" onClick={() => {}}>
                    Subir Carga
                  </Link>
                </div>
              )}
            </div>
          </>
        )}
      </CSS.ActiveLoadsDesktop>
    </CSS.ActiveLoadsWrap>
  );
};

export default ActiveLoadsView;
