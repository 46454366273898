import React from 'react';
import { BallScaleMultiple } from 'react-pure-loaders';
import './loading.css';

function Loading() {
  return (
    <div className="loading-wrapper">
      <BallScaleMultiple color="#ffbb0f" loading />
    </div>
  );
}

export default Loading;
