/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faTruckMoving, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import CSS from './active-load-styles';
import TruckerListMobile from './TruckerListMobile';
import TruckerListDesktop from './TruckerListDesktop';
import client from '../../../../api/api';

const ActiveLoad = ({ load, openCartaPorteCarga, openCartaPorteViaje }) => {
  const {
    premium,
    fromCity,
    toCity,
    loadDate,
    loadType,
    productType,
    distance,
    fare,
    fareType,
    trucksRequested,
    pendingTrucks,
    shipments,
    status,
    _id: loadId,
  } = load;

  const [showTruckerListMobile, setShowTruckerListMobile] = useState(false);
  const [showTruckerListDesktop, setShowTruckerListDesktop] = useState(false);
  const [pendingDeletedOK, setPendingDeletedOK] = useState('');

  const deletePending = async id => {
    try {
      const res = await client.endpoints.shipper.deletePendingLoads(id);
      if (res.status === 200) {
        setPendingDeletedOK(true);
      }
    } catch (error) {
      setPendingDeletedOK(false);
    }
  };

  return (
    <>
      <CSS.LoadWrap>
        <CSS.LoadMobile onClick={() => setShowTruckerListMobile(!showTruckerListMobile)}>
          <div className="load-info">
            <p className="load-hour">{moment(loadDate).format('HH:mm')} hs</p>
            <p className="load-date">{moment(loadDate).format('DD-MMM-YYYY')}</p>
            <p className="load-type">{loadType}</p>
            <p>{productType}</p>
            <div className="premium-wrap">
              <p>
                {premium ? (
                  <FontAwesomeIcon className="premium humber-yellow" icon={faCrown} />
                ) : (
                  <FontAwesomeIcon className="no-premium" icon={faCrown} />
                )}
              </p>
            </div>
            <h3>de {fromCity}</h3>
            <p>a {toCity}</p>
            <p>
              {distance}
              <span className="fare">
                ${fare}/{fareType} +IVA
              </span>
            </p>
          </div>
          {status === 'open' && pendingDeletedOK === '' ? (
            <p className="open">ABIERTA</p>
          ) : (
            <p className="closed">CERRADA</p>
          )}
          <span className="delete-pending-wrap">
            <Popconfirm
              disabled={status === 'close'}
              title="¿Estás seguro que querés dar de baja la carga?"
              onConfirm={() => {
                deletePending(loadId);
              }}
              okText="Sí"
              cancelText="No"
            >
              <FontAwesomeIcon
                icon={faUserTimes}
                title="Dar de baja pendientes"
                role="button"
                tabIndex={0}
                className={
                  status === 'close' || pendingDeletedOK !== ''
                    ? 'delete-pendings-disabled'
                    : 'delete-pendings'
                }
              />
            </Popconfirm>
          </span>
          <span
            className="create-carta-porte-wrap"
            title="Carta de porte"
            role="button"
            tabIndex={0}
            onClick={() => {
              openCartaPorteCarga(load);
            }}
          >
            CPE
          </span>
          {pendingDeletedOK !== '' ? (
            <p className={pendingDeletedOK ? 'deleted-pending' : 'deleted-pending-error'}>
              {pendingDeletedOK ? 'Carga cerrada correctamente' : 'Error al cerrar la carga'}
            </p>
          ) : (
            ''
          )}
        </CSS.LoadMobile>
        {showTruckerListMobile ? (
          <TruckerListMobile
            shipments={shipments}
            openCartaPorte={shipment => {
              openCartaPorteViaje(shipment);
            }}
          />
        ) : (
          ''
        )}
        <CSS.LoadDesktop onClick={() => setShowTruckerListDesktop(!showTruckerListDesktop)}>
          <div className="load-info">
            <div className="premium-wrap">
              <p>
                {premium ? (
                  <FontAwesomeIcon
                    className="premium humber-yellow"
                    icon={faCrown}
                    title="Premium"
                  />
                ) : (
                  <FontAwesomeIcon className="no-premium" icon={faCrown} title="No Premium" />
                )}
              </p>
            </div>
            <div className="load-time-wrap">
              <p className="load-date">{moment(loadDate).format('DD-MMM-YYYY')}</p>
              <p className="load-hour">{moment(loadDate).format('HH:mm')}</p>
            </div>

            <div className="load-type-product-wrap">
              <p className="load-type" title={loadType}>
                {loadType}
              </p>
              <p className="product-type" title={productType}>
                {productType}
              </p>
            </div>
            <p className="from-city" title={fromCity}>
              de {fromCity}
            </p>
            <p className="to-city" title={toCity}>
              a {toCity}
            </p>
            <p className="distance">{distance}</p>
            <p className="fare">
              ${fare}/{fareType} +IVA
            </p>
            <div className="trucks-wrap">
              <FontAwesomeIcon
                icon={faTruckMoving}
                className="requested-icon trucks-requested"
                title="Camiones solicitados"
              />
              <p className="trucks-requested">{trucksRequested}</p>

              <FontAwesomeIcon
                icon={faTruckMoving}
                className="confirmed-icon"
                title="Camiones confirmados"
              />
              <p className="trucks-confirmed">{trucksRequested - pendingTrucks}</p>

              <FontAwesomeIcon
                icon={faTruckMoving}
                className="pending-icon"
                title="Camiones pendientes"
              />
              <p className="trucks-pending">{pendingTrucks}</p>
            </div>
            {status === 'open' && pendingDeletedOK === '' ? (
              <p className="open">ABIERTA</p>
            ) : (
              <p className="closed">CERRADA</p>
            )}
            <span className="delete-pending-wrap">
              <Popconfirm
                disabled={status === 'close'}
                title="¿Estás seguro que querés dar de baja la carga?"
                onConfirm={() => {
                  deletePending(loadId);
                }}
                okText="Sí"
                cancelText="No"
              >
                <FontAwesomeIcon
                  icon={faUserTimes}
                  title="Dar de baja pendientes"
                  role="button"
                  tabIndex={0}
                  className={
                    status === 'close' || pendingDeletedOK !== ''
                      ? 'delete-pendings-disabled'
                      : 'delete-pendings'
                  }
                />
              </Popconfirm>
            </span>
            <span
              className="create-carta-porte-wrap"
              title="Carta de porte"
              role="button"
              tabIndex={0}
              onClick={() => {
                openCartaPorteCarga(load);
              }}
            >
              CPE
            </span>
            {pendingDeletedOK !== '' ? (
              <p className={pendingDeletedOK ? 'deleted-pending' : 'deleted-pending-error'}>
                {pendingDeletedOK ? 'Carga cerrada correctamente' : 'Error al cerrar la carga'}
              </p>
            ) : (
              ''
            )}
          </div>
        </CSS.LoadDesktop>
        {showTruckerListDesktop && shipments.length > 0 ? (
          <TruckerListDesktop
            shipments={shipments}
            openCartaPorte={shipment => {
              openCartaPorteViaje(shipment);
            }}
          />
        ) : (
          ''
        )}
      </CSS.LoadWrap>
    </>
  );
};

ActiveLoad.propTypes = {
  load: PropTypes.object.isRequired,
};

export default ActiveLoad;
