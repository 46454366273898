/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { getToken } from 'firebase/messaging';
import { AuthContext } from '../PrivateRoute/AuthProvider';
import { messaging } from '../../firebase';
import { getSessionUserData } from '../../libs/user';
import config from '../../api/config';
import client from '../../api/api';

import './login.css';
import '../../App.css';

const Login = ({ username: usernameProp, role }) => {
  const [username, setUsername] = useState(usernameProp || '');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState(role || 'carrier');
  const [carrierLoggedIn, setCarrierLoggedIn] = useState(false);
  const [shipperLoggedIn, setShipperLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { login, passwordResetRequest } = useContext(AuthContext);
  const [recoverPasswordEmail, setRecoverPasswordEmail] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState(false);
  const history = useHistory();
  const { FIREBASE_VAPID_KEY } = config;

  const {
    endpoints: { carrier },
  } = client;

  useEffect(() => {
    const session = getSessionUserData();
    if (session && session.scope && session.scope[0] === 'carrier') {
      setCarrierLoggedIn(true);
    }
    if (session && session.scope && session.scope[0] === 'shipper') {
      setShipperLoggedIn(true);
    }
  }, []);

  const getOrRegisterServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
      return window.navigator.serviceWorker
        .getRegistration('/firebase-push-notification-scope')
        .then(serviceWorker => {
          if (serviceWorker) return serviceWorker;
          return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/firebase-push-notification-scope',
          });
        });
    }
    throw new Error('The browser doesn`t support service worker.');
  };

  const activateMessaging = async () => {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    const fbToken = await getToken(messaging, {
      vapidKey: FIREBASE_VAPID_KEY,
      serviceWorkerRegistration,
    }).catch(e => console.log(`Token no creado ...${e}`));
    // TODO: delete logs
    if (fbToken) {
      console.log(`fbToken: ${fbToken}`);
      return fbToken;
    }
    if (!fbToken) console.log('NO Token!');
  };

  async function submit() {
    try {
      await login({ username, password, logAs: userType });
      if (userType === 'shipper') {
        setShipperLoggedIn(true);
      }
      if (userType === 'carrier') {
        setCarrierLoggedIn(true);
        const fbToken = await activateMessaging();
        const { data } = getSessionUserData();
        if (fbToken) await carrier.fbTokenToDB(data._id, fbToken);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else {
        return <Route render={() => <Redirect push to="/error" />} />;
      }
    }
  }

  async function onPasswordRequest() {
    try {
      await passwordResetRequest(recoverPasswordEmail);
      setResetPasswordMessage(
        'Te enviamos un correo a tu cuenta. Seguí los pasos para recuperar la contraseña'
      );
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  }

  function handleKeyPressed(event) {
    if (event.key === 'Enter') {
      if (!showResetPassword) {
        submit();
      } else {
        onPasswordRequest();
      }
    }
  }

  const renderFunct = () => {
    if (carrierLoggedIn) {
      return <Route render={() => <Redirect push to="/carrier" />} />;
    }
    if (shipperLoggedIn) {
      return <Route render={() => <Redirect push to="/point" />} />;
    }
    return (
      <>
        <div className="login-wrapper" tabIndex={0} role="button" onKeyPress={handleKeyPressed}>
          <div className="fields">
            <div className="title">
              <h2>Elegí tu tipo de usuario</h2>
            </div>
            <div className="user-type-btn-wrapper">
              <div
                className={userType === 'carrier' ? 'user-selected' : 'user-type-btn'}
                role="button"
                tabIndex={0}
                onClick={() => setUserType('carrier')}
              >
                Transportista
              </div>
              <div
                className={userType === 'shipper' ? 'user-selected' : 'user-type-btn'}
                role="button"
                tabIndex={0}
                onClick={() => setUserType('shipper')}
              >
                Dador de carga
              </div>
            </div>
            <div className="humber-input">
              <label htmlFor="user">
                Usuario
                <input
                  id="user"
                  tabIndex={0}
                  type="text"
                  className="form-control"
                  onChange={e => setUsername(encodeURIComponent(e.target.value))}
                />
              </label>
            </div>
            <div className="humber-input">
              <label htmlFor="password">
                Password
                <input
                  id="password"
                  tabIndex={0}
                  type="password"
                  className="form-control"
                  onChange={e => setPassword(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div
            role="button"
            tabIndex={-1}
            className="reset-password"
            onClick={() => setShowResetPassword(!showResetPassword)}
          >
            Recuperar contraseña
          </div>
          {showResetPassword ? (
            <div className="fields">
              <div className="humber-input">
                <label htmlFor="passRecover">
                  Ingresá el email con el que te registraste
                  <input
                    id="passRecover"
                    type="email"
                    className="form-control"
                    placeholder="Ingresá tu email..."
                    value={recoverPasswordEmail}
                    onChange={e => setRecoverPasswordEmail(e.target.value)}
                  />
                </label>
              </div>
              <div
                className="reset-password-btn"
                role="button"
                tabIndex={-2}
                onClick={() => onPasswordRequest()}
              >
                Enviar
              </div>
              <p className="reset-password-msg">{resetPasswordMessage}</p>
            </div>
          ) : (
            ''
          )}
          <p className="login-error-msg">{errorMessage}</p>
          <div role="button" tabIndex={0} className="login-humber-btn" onClick={() => submit()}>
            Entrar
          </div>
          <div
            className="register"
            tabIndex={0}
            role="button"
            onClick={() => {
              history.push('/signup');
              // window.scrollTo({ top: 1000, behavior: 'smooth' });
              // setShowSignUp(true);
            }}
          >
            Registrarme
          </div>
          <div className="text">
            <p>¿Necesitás camiones?</p>
            <p>Llamanos</p>
            <p>11-2642-5830</p>
          </div>
        </div>
      </>
    );
  };

  return renderFunct();
};
export default Login;
