/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCamera,
  faIdCard,
  faMobileAlt,
  faFolder,
  faTimes,
  faCalculator,
} from '@fortawesome/free-solid-svg-icons';
import { BallClipRotate } from 'react-pure-loaders';
import PropTypes from 'prop-types';
import CSS from './trucker-list-styles';
import client from '../../../../../api/api';
import openCPEPdf from '../../../../../libs/utils';

// eslint-disable-next-line react/prop-types
const TruckerListDesktop = ({ shipments, openCartaPorte }) => {
  const [showReports, setShowReports] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [showPremiumImg, setShowPremiumImg] = useState(false);
  const [truckerPremiumPhotos, setTruckerPremiumPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const imgList = [];
  const premiumImagesList = [];

  const fetchPremiumImg = async trucker => {
    Object.entries(trucker.premiumPhotos || {}).forEach(e => {
      if (typeof e[1] === 'string' && e[1].includes('.jpg')) {
        premiumImagesList.push({ name: e[0], value: e[1] });
      }
    });

    try {
      premiumImagesList.forEach(async e => {
        const res = await client.endpoints.generic.getImagesPremium(e.value);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setTruckerPremiumPhotos(p => p.concat([base64data]));
          setShowPremiumImg(!showPremiumImg);
          setIsLoading(false);
        };
        reader.onerror = () => {
          setIsLoading(false);
        };
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchReports = async e => {
    if (e.emptyReport) {
      imgList.push({
        name: 'emptyReport',
        value: e.emptyReport,
      });
    }

    if (e.signedReport) {
      imgList.push({
        name: 'signedReport',
        value: e.signedReport,
      });
    }

    try {
      imgList.forEach(async report => {
        const res = await client.endpoints.generic.getReports(report.value);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setReportList(p => p.concat([base64data]));
          setIsLoading(false);
        };
        reader.onerror = () => {
          setIsLoading(false);
        };
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        setTruckerPremiumPhotos([]);
        setShowPremiumImg(false);
        setReportList([]);
        setShowReports(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <CSS.TruckerListWrap>
      {showPremiumImg ? (
        <CSS.ReportPicsPopUp>
          <h3>Espera que se carguen todas las imagenes...</h3>
          <FontAwesomeIcon
            icon={faTimes}
            className="close-btn"
            onClick={() => {
              setTruckerPremiumPhotos([]);
              setShowPremiumImg(false);
            }}
          />
          {truckerPremiumPhotos.map(pic => (
            <img src={pic} alt="Foto del viaje" key={new Date().getTime()} />
          ))}
        </CSS.ReportPicsPopUp>
      ) : (
        ''
      )}
      {showReports ? (
        <CSS.ReportPicsPopUp>
          <h3>Espera que se carguen todas las imagenes...</h3>
          <FontAwesomeIcon
            icon={faTimes}
            className="close-btn"
            onClick={() => {
              setReportList([]);
              setShowReports(false);
            }}
          />
          {reportList.map(pic => (
            <>
              <img src={pic} alt="Foto del viaje" key={new Date().getTime()} />
            </>
          ))}
        </CSS.ReportPicsPopUp>
      ) : (
        ''
      )}
      <ul>
        <li className="trucker-label">Chofer</li>
        <li className="truck">Camión</li>
        <li className="carrier">Transportista</li>
      </ul>
      {shipments.map(e => {
        return (
          <CSS.Trucker>
            <div className="trucker-info-wrap">
              {e.status === 'cancelada' ? <p className="status">CANCELADO</p> : ''}

              <p className="name" title="Nombre chofer">
                {e.trucker.truckerName}
              </p>

              <FontAwesomeIcon icon={faIdCard} className="id-card" title="CUIT chofer" />
              <p className="cuit" title="CUIT chofer">
                {e.trucker.truckerCuit}
              </p>

              <FontAwesomeIcon icon={faMobileAlt} className="phone" title="Celular" />
              <p className="cel" title="Celular">
                {e.trucker.truckerCellPhone}
              </p>
            </div>
            <div className="truck-info-wrap">
              <p className="patent">{e.truck.truckPatent}</p>
              <p className="patent">{e.truck.trailerPatent}</p>
              <p className="truck">{e.truck.truckBodyType}</p>
            </div>
            <div className="carrier-info-wrap">
              <p className="billing-b-name" title="Razón social">
                {e.trucker.billingData.businessName}
              </p>
              <div className="billing-cuit-wrap" title="CUIT transportista">
                <FontAwesomeIcon icon={faIdCard} className="id-card" title="CUIT transportista" />
                <p className="billing-cuit" title="CUIT transportista">
                  {e.trucker.billingData.cuit}
                </p>
              </div>
            </div>
            <div className="images-wrap">
              {!e.tripDocument && (
                <button
                  type="button"
                  className="create-carta-porte-wrap"
                  title="Carta de porte"
                  tabIndex={0}
                  onClick={() => {
                    openCartaPorte(e);
                  }}
                >
                  CPE
                </button>
              )}
              {e.tripDocument && (
                <a
                  href="#"
                  onClick={() => {
                    openCPEPdf({ ctg: e.tripDocument });
                  }}
                >
                  {e.tripDocument}
                </a>
              )}
            </div>
          </CSS.Trucker>
        );
      })}
    </CSS.TruckerListWrap>
  );
};

export default TruckerListDesktop;

TruckerListDesktop.propTypes = {
  shipments: PropTypes.objectOf().isRequired,
};
