import {
  faAddressCard,
  faCamera,
  faMobileAlt,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import client from '../../api/api';
import Loading from '../../libs/loading';
import './shipment.css';

const Shipment = ({ shipment }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showShipmentDocs, setShowShipmentDocs] = useState(false);
  const [shipmentPhotos, setShipmentPhotos] = useState([]);
  const { truck, trucker, premiumPhotos, emptyReport, signedReport, status } = shipment;
  const { truckerName, truckerCuit, truckerCellPhone, billingData } = trucker;
  const { trailerPatent, truckPatent, truckType } = truck;
  const premiumPhotosFiltered = [];
  const shipmentPhotosFiltered = [];

  if (emptyReport) {
    shipmentPhotosFiltered.push({
      name: 'emptyReport',
      value: emptyReport,
    });
  }
  if (signedReport) {
    shipmentPhotosFiltered.push({
      name: 'signedReport',
      value: signedReport,
    });
  }

  Object.entries(premiumPhotos || {}).forEach(e => {
    if (typeof e[1] === 'string' && e[1].includes('.jpg')) {
      premiumPhotosFiltered.push({ name: e[0], value: e[1] });
    }
  });

  async function fetchShipmentPhotos() {
    let count = shipmentPhotosFiltered.length;
    try {
      shipmentPhotosFiltered.forEach(async e => {
        const res = await client.endpoints.generic.getReports(e.value);
        const reader = new FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = () => {
          const base64data = reader.result;
          setShipmentPhotos(p => p.concat([base64data]));
          setIsLoading((count -= 1));
        };
        reader.onerror = () => {
          setIsLoading((count -= 1));
        };
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (showShipmentDocs && shipmentPhotosFiltered.length > 0) {
      setIsLoading(true);
      fetchShipmentPhotos();
    }
  }, [showShipmentDocs]);

  return (
    <div className="shipment">
      {status === 'cancelada' ? (
        <span className="user-icon-cancelled">cancelado</span>
      ) : (
        <>
          <span className="user-icon">
            <FontAwesomeIcon icon={faUserCircle} />
          </span>
        </>
      )}
      <p className="trucker-name">{truckerName}</p>
      <span className="address-card-icon">
        <FontAwesomeIcon icon={faAddressCard} />
      </span>
      <p className="trucker-cuit">{truckerCuit}</p>
      <span className="cellphone-icon">
        <FontAwesomeIcon icon={faMobileAlt} />
      </span>
      <p className="trucker-cellphone">{truckerCellPhone}</p>
      <p className="trucker-patent" title="Patente tractor">
        {truckPatent}
      </p>
      <p className="trailer-patent" title="Patente remolque">
        {trailerPatent}
      </p>
      <p className="truck-type">{truckType}</p>
      <p title="Razón social" className="company-name">
        {billingData ? billingData.businessName : ''}
      </p>
      <p title="CUIT facturación" className="cuit-company-name">
        {billingData ? billingData.cuit : ''}
      </p>
      <Popup
        className="imgs-popup"
        onOpen={() => {
          setShowShipmentDocs(true);
        }}
        onClose={() => setShowShipmentDocs(false)}
        position="right center"
        modal
        trigger={
          <span className="folder-icon" title="Carta de Porte">
            <FontAwesomeIcon icon={faCamera} />
          </span>
        }
      >
        <div className={!isLoading ? 'doc-premium' : 'loading'}>
          {isLoading ? (
            <Loading />
          ) : (
            <div className={!shipmentPhotos.length ? 'no-photo' : ''}>
              {!shipmentPhotos.length ? <p>El viaje aún no tiene fotos subidas</p> : ''}
              {shipmentPhotos.map(pic => (
                <img src={pic} alt="Foto del viaje" key={new Date().getTime()} />
              ))}
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
};

Shipment.propTypes = {
  shipment: PropTypes.shape({
    trucker: PropTypes.shape({
      truckerName: PropTypes.string,
      truckerCuit: PropTypes.string,
      truckerCellPhone: PropTypes.string,
      geoCode: PropTypes.arrayOf(PropTypes.number),
      lastGeolocationReport: PropTypes.arrayOf(PropTypes.number),
      billingData: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    truck: PropTypes.shape({
      trailerPatent: PropTypes.string,
      truckBodyType: PropTypes.string,
      truckMake: PropTypes.string,
      truckModel: PropTypes.string,
      truckPatent: PropTypes.string,
      truckType: PropTypes.string,
    }).isRequired,
    status: PropTypes.string,
    premiumPhotos: PropTypes.object,
    emptyReport: PropTypes.string,
    signedReport: PropTypes.string,
  }).isRequired,
};

export default Shipment;
