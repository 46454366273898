/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '@chakra-ui/react';

import CSS from './search-loads-styles';
import client from '../../../api/api';
import LoadList from './components/LoadList';
import LoadsMap from './components/LoadsMap';
import LoadSelected from './components/LoadSelected';
import TruckerList from './components/TruckerList';
import LoadDetail from './components/LoadDetail';
import Alerts from '../../Alerts';

const SearchLoadsView = () => {
  const toast = useToast();

  const [todayLoadList, setTodayLoadList] = useState('');
  const [tomorrowLoadList, setTomorrowLoadList] = useState('');
  const [nextDaysLoadList, setNextDaysLoadList] = useState('');
  const [loadSelected, setLoadSelected] = useState('');
  const [showMap, setShowMap] = useState(true);
  const [showLoadList, setShowLoadList] = useState(true);
  const [showLoadDetail, setShowLoadDetail] = useState(false);

  const fetchLoads = async () => {
    try {
      const res = await client.endpoints.carrier.getAvailableLoads();
      if (res.data.today) setTodayLoadList(res.data.today);
      if (res.data.tomorrow) setTomorrowLoadList(res.data.tomorrow);
      if (res.data.others) setNextDaysLoadList(res.data.others);
    } catch (error) {
      const {
        response: { status, data },
      } = error;
      if (status === 429) {
        toast({ title: data, status: 'warning', isClosable: true });
      }
      console.error(error);
    }
  };

  useEffect(() => {
    if (showLoadList) {
      fetchLoads();
    }
  }, [showLoadList]);

  return (
    <>
      <Alerts />
      <CSS.SearchLoadsViewWrap>
        {showLoadList ? (
          <CSS.LoadListWrap>
            <CSS.Title>Elegí una carga</CSS.Title>
            <CSS.TodayLoadsWrapper>
              <h1>Cargas de hoy</h1>
              {todayLoadList.length > 0 ? (
                <LoadList
                  list={todayLoadList}
                  setShowLoadList={setShowLoadList}
                  loadSelected={setLoadSelected}
                  setShowLoadDetail={setShowLoadDetail}
                />
              ) : (
                <>
                  {todayLoadList === '' ? (
                    <p className="loading-list">Cargando...</p>
                  ) : (
                    <p className="empty-list">No hay cargas para hoy en este momento...</p>
                  )}
                </>
              )}
            </CSS.TodayLoadsWrapper>
            <CSS.TomorrowLoadsWrapper>
              <h1>Cargas para mañana</h1>
              {tomorrowLoadList.length > 0 ? (
                <LoadList
                  list={tomorrowLoadList}
                  setShowLoadList={setShowLoadList}
                  loadSelected={setLoadSelected}
                  setShowLoadDetail={setShowLoadDetail}
                />
              ) : (
                <>
                  {tomorrowLoadList === '' ? (
                    <p className="loading-list">Cargando...</p>
                  ) : (
                    <p className="empty-list">No hay cargas para mañana en este momento...</p>
                  )}
                </>
              )}
            </CSS.TomorrowLoadsWrapper>
            <CSS.NextDaysLoadsWrapper>
              <h1>Para mas adelante...</h1>
              {nextDaysLoadList.length > 0 ? (
                <LoadList
                  list={nextDaysLoadList}
                  setShowLoadList={setShowLoadList}
                  loadSelected={setLoadSelected}
                  setShowLoadDetail={setShowLoadDetail}
                />
              ) : (
                <>
                  {nextDaysLoadList === '' ? (
                    <p className="loading-list">Cargando...</p>
                  ) : (
                    <p className="empty-list">No hay cargas para mas adelante en este momento...</p>
                  )}
                </>
              )}
            </CSS.NextDaysLoadsWrapper>
          </CSS.LoadListWrap>
        ) : (
          <CSS.TruckerListWrap>
            {loadSelected ? (
              <>
                <CSS.LoadSelectedWrap>
                  <LoadSelected loadSelected={loadSelected} setShowLoadList={setShowLoadList} />
                </CSS.LoadSelectedWrap>
                <TruckerList load={loadSelected} />
              </>
            ) : (
              ''
            )}
          </CSS.TruckerListWrap>
        )}
        <CSS.MapWrap>
          <CSS.MapIconWrap onClick={() => setShowMap(!showMap)}>
            <span>
              <FontAwesomeIcon icon={faMap} />
            </span>
          </CSS.MapIconWrap>
          {showMap ? <LoadsMap loadSelected={loadSelected} /> : ''}
          <h1>Detalles de la carga</h1>
          {showLoadDetail ? <LoadDetail loadSelected={loadSelected} /> : ''}
        </CSS.MapWrap>
      </CSS.SearchLoadsViewWrap>
    </>
  );
};

export default SearchLoadsView;
