// eslint-disable jsx-a11y/label-has-associated-control
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './nav.css';
import Logo from './humber-logo.png';
import { AuthContext } from '../../PrivateRoute/AuthProvider';
import useToast from '../../Global/outdatedVersionToast';

const PointNav = () => {
  const { logout } = useContext(AuthContext);
  const logOut = async () => {
    await logout();
  };
  const options = [
    { label: 'Subir cargas', path: '/point' },
    { label: 'Cargas activas', path: '/point/active-loads' },
    { label: 'Historial', path: '/point/point-record' },
    { label: 'Salir', path: '/', action: () => logOut() },
  ];

  useToast();

  return (
    <nav className="point">
      <div className="logo">
        <a href="/">
          <img className="logo-img" src={Logo} alt="humber logo" />
        </a>
      </div>
      <div className="landing-items">
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon" />
        </label>
        <ul className="menu">
          {options.map(l => {
            return (
              <li key={`key-${l.label}`}>
                <Link to={l.path} onClick={() => (l.action ? l.action() : () => {})}>
                  {l.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default PointNav;
