import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { pdfjs } from 'react-pdf';
import config from './api/config';
import './index.css';
// eslint-disable-next-line import/named
import App from './App';
import * as serviceWorker from './serviceWorker';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const tagManagerArgs = {
  gtmId: config.GTM_ID,
};

ReactGA.initialize(config.GA_UID);
ReactGA.pageview(window.location.pathname + window.location.search);

const chakraTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  styles: {
    global: props => ({
      body: {
        bg: mode('#1c2023', '#1c2023')(props),
      },
    }),
  },
});

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <ChakraProvider theme={chakraTheme}>
    <App />
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
